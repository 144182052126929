// ReorderContext.js
import React from 'react';

/**
 * Kendo ReorderContext
 */
export const ReorderContext = React.createContext({
  reorder: () => {},
  dragStart: () => {},
});
