import React from 'react';
import { FieldWrapper } from '@progress/kendo-react-form';

import avatarIcon from '../../../../../assets/images/avatar-2.svg';

export const UserAvatar = () => {
  return (
    <FieldWrapper>
      <div className="profile-detail-image">
        <img src={avatarIcon} alt="User Profile" />
      </div>
    </FieldWrapper>
  );
};
export default UserAvatar;
