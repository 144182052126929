import React from 'react';
import {
  Grid,
  GridNoRecords,
  GridColumn as Column,
  getSelectedState,
} from '@progress/kendo-react-grid';
import { ReorderContext } from './Context/ReorderContext'; // Import from the new file
import { DragAndDrop } from '@progress/kendo-react-common';
import { orderBy, filterBy } from '@progress/kendo-data-query';
import { getter } from '@progress/kendo-react-common';
import { Tooltip } from '@progress/kendo-react-tooltip';
import calculateSize from 'calculate-size';
import {
  countObjectsFrequency,
  getMappedFilters,
} from '../../../../Utils/Filters/filterUtils';
import Loader from '../Loader/Loader';
import {
  FilterOperators,
  RowStatus,
} from '../../../../constants/applicationConstants';

import {
  CellRender,
  RowRender,
} from '../../../../Utils/GridRenderers/gridRenderers';
import {
  getItem,
  setItem,
} from '../../../../Utils/Storage/LocalStorage/localStorageHandler';
import '../../../../assets/scss/common/Grid.scss';
import '../../../../assets/scss/common/GridToolbar.scss';
import { messageGenerator } from '../../../../Utils/Notifications/notificationUtils';
import { DraggableRow } from './DraggableRow';
import { DragHandleCell } from './DragHandle';
import { reorderGridData } from '../../../../Utils/DataTables/reorder';
// Variables
const selectedField = 'selected';

/**
 * Pagination Default Setting
 */
const defaultPagerSettings = {
  buttonCount: 5,
  info: true,
  type: 'numeric',
  pageSizes: false,
  previousNext: true,
};

/**
 * Sortable Default Setting
 */
const defaultSortableSettings = {
  allowUnsort: process.env.AllowUnSorting ? true : false,
  mode: process.env.SortMode ? 'multiple' : 'single',
};

/**
 * Column selectable Default Setting
 */
const selectable = {
  enabled: true,
  drag: false,
  cell: false,
  mode: 'multiple',
};

/**
 * Kendo Edit Field label
 */
const editField = 'inEdit';

/**
 * Data Grid Component
 * @param {*} props
 * @param ref
 * @returns {Grid}
 */
const DataGrid = (props, ref) => {
  const [selectedState, setSelectedState] = React.useState({});
  const [gridData, setGridData] = React.useState([]);
  const [allItemsChecked, setAllItemsChecked] = React.useState(false);
  const [activeItem, setActiveItem] = React.useState(null);
  let gridRecords = 0;
  const {
    sortable,
    pagerSettings,
    isSelectable,
    setFilterName,
    setGridFilterName,
    gridFilterName,
    isFilterable = true,
    inlineEdit,
    filterType,
    isFavourite,
    handleFavouriteArray,
    noRecord,
    dataColumns,
    draggableToggle,
    setDraggableCallback,
    isGridSortable,
  } = props;
  /**
   * Define Variables and functions which we have to use in parent component
   */
  React.useImperativeHandle(
    ref,
    () => ({
      selectedField,
    }),
    []
  );

  /**
   * Check All rows are selected or not
   * @returns boolean
   */
  const headerSelectionValue = () =>
    gridData.findIndex((item) => !selectedState[idGetter(item)]?.checked) ===
    -1;

  /**
   * Calculate Width of column  according to content
   * @returns number
   */
  const calculateWidth = (field) => {
    let maxWidth = 50;
    gridData.forEach((item) => {
      const size = calculateSize(item[field], {
        font: 'Arial',
        fontSize: '16px',
      }); // pass the font properties based on the application
      if (size.width > maxWidth) {
        maxWidth = size.width;
      }
    });
    return maxWidth;
  };

  /**
   * Filter element for tooltip
   */
  const filterElement = (element) => {
    if (element.tagName === 'TD') {
      const tooltip = element.getAttribute('data-tooltip');
      if (tooltip) {
        element.setAttribute('title', tooltip);
      } else {
        element.setAttribute('title', element.innerText);
      }

      return true;
    }
    return false;
  };

  /**
   * Get selected element id
   */
  const idGetter = getter(props.dataItemKey);

  /**
   * Update local state variable when any operation is performed on data.
   * @param {Object} dataItem
   */
  const itemChange = (event) => {
    // get columns and map with field
    const field = props?.columns?.filter((f) => f.field === event.field)[0];

    // if field is unique then make sure no other column has same field
    if (
      (field?.isUnique ?? false) &&
      !(gridData?.findIndex((f) => f[event.field] === event.value) === -1)
    ) {
      event.value = '';
      messageGenerator({
        title: 'Unique Column Constraint',
        message: `Cannot enter duplicate value for ${event.field} column`,
        style: 'error',
      });
    }
    const updatedItem = {
      ...event.dataItem,
      [event.field || '']: event.value,
    };

    const updatedItems = gridData?.map((item) =>
      String(item[props.dataItemKey]) ===
      String(event.dataItem[props.dataItemKey])
        ? updatedItem
        : item
    );
    props.setData(updatedItems);
  };

  /** event to trigger when a column is reordered */
  const onColumnReorder = (event) => {
    setColumnsInfo(event.columns);
    // saving column state in local Storage
    setItem(props.storageName, event.columns);
  };

  /** @event
   * Handle Header Row Selections
   */
  const onHeaderSelectionChange = React.useCallback(
    (event) => {
      if (selectableConfig.mode !== 'single') {
        const checkboxElement = event.syntheticEvent.target;
        const { checked } = checkboxElement;
        setAllItemsChecked(checked);
        const newSelectedState = {};
        let checkedRows = {};
        gridData.forEach((item) => {
          newSelectedState[idGetter(item)] = checked;
          checkedRows[idGetter(item)] = {
            id: idGetter(item),
            checked,
          };
        });

        setSelectedState(newSelectedState);
        props.checkedItems(checkedRows);
      }
    },
    [gridData]
  );

  /** @event
   * Handle Row Selections
   */
  const onSelectionChange = React.useCallback(
    (event) => {
      const startingIndex = 0;
      if (event.startColIndex === startingIndex) {
        const newSelectedState = getSelectedState({
          event,
          selectedState,
          dataItemKey: props.dataItemKey,
        });
        const areEqual =
          JSON.stringify(newSelectedState) === JSON.stringify(selectedState);
        let checkedRows = [];
        if (selectableConfig.mode === 'single' && areEqual) {
          setSelectedState(newSelectedState);
          props.checkedItems(checkedRows);
        } else {
          for (var key in newSelectedState) {
            if (newSelectedState[key]) {
              checkedRows[key] = {
                id: key,
                checked: true,
              };
            }
          }

          setSelectedState(newSelectedState);
          props.checkedItems(checkedRows);
        }
      }
    },
    [selectedState]
  );

  /** @event
   * Make Each Row Inline Editable
   * @param {*} tr
   * @param {*} props
   * @returns {RowRender} returns row
   */
  const customRowRender = (tr, props) => {
    const { rowStatus, inEdit } = props?.dataItem ?? {};
    return (
      <DraggableRow elementProps={tr.props} {...props}>
        <RowRender
          originalProps={{
            ...props,
          }}
          isModified={
            ((rowStatus === RowStatus.New ||
              rowStatus === RowStatus.Modified) &&
              !inEdit) ??
            false
          }
          tr={tr}
          exitEdit={props.exitEdit}
          editField={editField}
        />
      </DraggableRow>
    );
  };

  /** @event
   * Make Each Cell Inline Editable
   * @param {*} td
   * @param {*} props
   * @returns {CellRender} returns cell
   */
  const customCellRender = (td, props) => {
    const field = props?.field;
    const fieldValue = props?.dataItem[field];

    /**
     * Create Checkbox for boolean value
     */
    if (
      (fieldValue === true ||
        fieldValue === false ||
        fieldValue === 'true' ||
        fieldValue === 'false') &&
      field !== 'selected'
    ) {
      const isChecked = fieldValue === true || fieldValue === 'true'; // Handle both boolean and string 'true'

      const booleanCheckbox = (
        <td>
          <input
            type="checkbox"
            className="grid-checkbox k-checkbox k-checkbox-md k-rounded-md"
            value={isChecked}
            checked={isChecked}
            disabled={enterEdit ? false : true}
            readOnly={true}
          />
        </td>
      );
      return (
        <CellRender
          originalProps={props}
          td={booleanCheckbox}
          enterEdit={enterEdit}
          inlineEdit={inlineEdit}
          editField={editField}
        />
      );
    }
    return (
      <CellRender
        originalProps={props}
        td={td}
        enterEdit={enterEdit}
        inlineEdit={inlineEdit}
        editField={editField}
      />
    );
  };

  /** @method
   * Trigger Inline Edit for Specfic Data Row
   * @param {Object} dataItem
   */
  const enterEdit = (dataItem) => {
    if (inlineEdit) {
      props.setData(
        props.data?.map((item) =>
          item[props.dataItemKey] === dataItem[props.dataItemKey]
            ? { ...item, inEdit: true, rowStatus: RowStatus.Modified }
            : item
        )
      );
      props.setInEdit(true);
    }
  };
  var columns = getItem(props.storageName);
  const [columnsInfo, setColumnsInfo] = React.useState(
    columns ? columns : null
  );

  /** Reorder Columns on Page refresh */
  const ReorderColumIndex = (field) =>
    /** default value is 0, if value found in columns then pick order index else 0 */
    columnsInfo
      ? (columnsInfo?.filter((column) => column.field === field)[0]
          ?.orderIndex ?? 0)
      : 0;

  /** @event
   * Set Grid Data in local Sate Variable
   */
  React.useEffect(() => {
    const newGridData = props.data
      // removed deleted rows on grid
      ?.filter((f) => f.rowStatus !== RowStatus.Deleted)
      ?.map((dataItem) => {
        const parsedObj = Object.fromEntries(
          Object.entries(dataItem).map(([key, val]) => {
            // if a value is numeric parse it to int
            const dataType =
              props?.columns?.filter((f) => f.field === key)[0]?.type ?? 'text';
            if (dataType === 'numeric' && !isNaN(parseInt(val))) {
              return [key, parseInt(val)];
            }
            return [key, val];
          })
        );
        return {
          selected: allItemsChecked,
          ...parsedObj,
        };
      });
    setGridData(newGridData);
    // Update selected state based on allItemsChecked
    const newSelectedState = {};
    let checkedRows = {};
    if (allItemsChecked) {
      // if all items are selected then mark all items selected and update count
      newGridData?.forEach((item) => {
        newSelectedState[item[props.dataItemKey]] = true;
        checkedRows[item[props.dataItemKey]] = {
          id: item[props.dataItemKey],
          checked: true,
        };
      });
    }
  }, [props.data]);

  let pageArgs = {
    onScroll: props.scrollHandler,
    fixedScroll: true,
    scrollable: 'scrollable',
  };
  if (!props.isScrollable) {
    pageArgs = {
      onPageChange: props.pageChange,
      skip: props.page.skip,
      take: parseInt(props.page.take),
      pageable: { ...defaultPagerSettings, ...pagerSettings },
    };
  }
  /** Process empty filters */
  const processFilters = (filters) =>
    // check if filter is a numeric then return number else return value
    filters.filter(
      (x) =>
        x.value ||
        (typeof x.value === 'number' &&
          x.value !== null &&
          x.value !== undefined) //  check if value is numeric and has a value)
    );
  /**
   * Pagination Count For Infinite Scroll
   */

  gridRecords = Number(gridData?.length);
  /**
   * Set local variable of selected state empty based on selected State props.
   */
  React.useEffect(() => {
    if (Object.keys(props.selectedState).length <= 0) {
      setSelectedState([]);
    } else if (Array.isArray(props.selectedState)) {
      const tempSelectedObj = {};
      props.selectedState.forEach((item) => {
        tempSelectedObj[item?.id] = item?.checked;
      });

      setSelectedState({ ...selectedState, ...tempSelectedObj });
    }
  }, [props.selectedState]);

  /**
   * parse Field value from Object
   * @param {Object} dataItem
   * @param {String} field
   * @returns {String} field value
   */
  const getFieldValue = (dataItem, field) => {
    const fields = field.split('.');
    let columnObj;
    fields.forEach((obj) => {
      if (!columnObj) {
        columnObj = dataItem[obj];
      } else {
        columnObj = columnObj[obj];
      }
    });
    return columnObj;
  };

  /**
   * Render Data grid Columns for Tooltip
   * @param {Object} props
   * @returns {JSX.Element} returns Tooltip Custom Cell
   */
  const TooltipCustomCell = (props) => {
    const field = props.field || '';
    const tooltipField = props?.tooltipField || '';
    const columnVal = getFieldValue(props.dataItem, field);
    const tooltipVal = getFieldValue(props.dataItem, tooltipField);

    return (
      <td
        colSpan={props.colSpan}
        role={'gridcell'}
        aria-colindex={props.ariaColumnIndex}
        aria-selected={props.isSelected}
        data-tooltip={tooltipVal}
      >
        {columnVal === null ? '' : columnVal}
      </td>
    );
  };

  /**
   * method to call TooltipCustomCell component and pass props to that
   * @param {Object} props
   * @param {String} tooltipField
   * @returns {Component} TooltipCustomCell
   */
  const tooltipCell = (props) => <TooltipCustomCell {...props} />;
  const ValidateHeaderSelection = () => {
    const dt = props?.data?.filter((f) => f.rowStatus !== RowStatus.Deleted);
    return dt?.length > 0
      ? dt?.findIndex((item) => !selectedState[idGetter(item)]) === -1
      : false;
  };

  /**
   * we are supporting two modes client side and server side for filtring grid data.
   * if filter type is client then it will use the kendo filterBy method to filter the data grid data
   * if filter type is server this it api will provide the filter data we don't have to filter data here
   * @returns {Array} Data
   */
  const getOrderAndFilterData = () => {
    let data;
    if (filterType === 'client') {
      data = filterBy(
        orderBy(gridData, props.sort).map((item) => ({
          ...item,
          [selectedField]: selectedState[idGetter(item)],
        })),
        props.filter
      );
    } else {
      data = orderBy(gridData, props.sort).map((item) => ({
        ...item,
        [selectedField]: selectedState[idGetter(item)],
      }));
    }
    return data;
  };

  /**
   * Data Column for Favourtie Cell
   * @param {Object} cellItem
   * @returns
   */
  const cellWithFavourite = (cellItem) => {
    const { dataItem } = cellItem;
    const dataItemKeyValue = dataItem[props.dataItemKey] ?? '';
    let isChecked = dataItem?.isFavourite ? 'is-favourite' : '';
    return (
      <td>
        <div
          className={`favourite-icon ${isChecked}`}
          onClick={() => {
            const updatedData = gridData?.map((item) => {
              if (
                item[props.dataItemKey] === dataItemKeyValue &&
                (!item?.isFavourite || item?.isFavourite === false)
              ) {
                item.isFavourite = true;
              } else if (
                item[props.dataItemKey] === dataItemKeyValue &&
                item.isFavourite === true
              ) {
                item.isFavourite = false;
              }
              return item;
            });
            setGridData(updatedData);
            handleFavouriteArray(cellItem);
          }}
        >
          {isChecked?.length > 0 ? (
            <span className="k-icon k-font-icon k-i-star" />
          ) : (
            <span className="k-icon k-font-icon k-i-star-outline" />
          )}
        </div>
      </td>
    );
  };

  /* * Reorders the grid data by dragging an item and dropping it before or after another item.
   * Updates the grid data state and calls a callback with the affected reordered items.
   *
   * @param {Object} dataItem - The item where the dragged item is dropped.
   * @param {String} direction - The direction where the item is dropped ('before' or 'after').
   */
  const reorder = (dataItem, direction) => {
    const { reorderedData, updatedAffectedItems } = reorderGridData(
      gridData,
      activeItem,
      dataItem,
      props.dataItemKey,
      props.orderSeqKey,
      direction,
      isGridSortable
    );

    setGridData(reorderedData); // Update the grid data state
    setDraggableCallback(updatedAffectedItems); // Send the affected items

    // Clear the active item after drop
    setActiveItem(null);
  };

  // Sets the item being dragged as the active item
  const dragStart = (dataItem) => {
    setActiveItem(dataItem);
  };
  const selectableConfig = props.selectable ? props.selectable : selectable;
  return (
    <Tooltip
      openDelay={props?.tooltipDelay ? props?.tooltipDelay : 100}
      position={props?.tooltipPosition ? props?.tooltipPosition : 'bottom'}
      anchorElement="target"
      parentTitle={true}
      filter={filterElement}
    >
      <div className="responsive-grid">
        {/* Load the loader if content length is 0 */}
        {props.loading && <Loader />}
        <ReorderContext.Provider
          value={{
            reorder,
            dragStart,
          }}
        >
          <DragAndDrop>
            <Grid
              editable={{ mode: 'inline', createAt: 'bottom' }}
              style={{
                height:
                  gridRecords > 0
                    ? `calc(100vh - ${props.filter?.filters?.length > 0 ? '422px' : '422px'})`
                    : 'auto',
                minHeight: '157px',
              }}
              data={getOrderAndFilterData()}
              filter={props.filter}
              onFilterChange={(e) => {
                // get target element class name
                const targetClassList = e.nativeEvent?.target?.classList;
                // check if target is operation selection or clear filter button, if clear button then ignore else process filters
                if (targetClassList?.contains('k-list-item-text')) {
                  //  process values
                  let { filter } = e;
                  // process Filters for null or empty values
                  filter.filters = processFilters(filter?.filters ?? []);
                  // check if filters are different

                  if (
                    JSON.stringify(filter.filters ?? []) !==
                    JSON.stringify(e?.target?.props?.filter?.filters ?? [])
                  )
                    props.setFilter(filter);
                } else {
                  let tempDateRangeFilter = [];
                  let isSingleDateSelected = false;
                  /**
                   * Separate date Range filter from other filers
                   */
                  let normalFilters = e.filter?.filters.filter((obj) => {
                    /**
                     * Validate value is array.
                     */
                    if (Array.isArray(obj?.value)) {
                      /**
                       * get Data Types of value
                       */
                      const mappedFilters = getMappedFilters({
                        dataColumns,
                        filters: obj?.value,
                      });

                      /**
                       * filter out date range filter value
                       */
                      const isDateRangeFilters = mappedFilters?.filter(
                        (tempObj) => tempObj.type === 'dateRange'
                      );
                      if (isDateRangeFilters?.length > 0) {
                        /**
                         * validate both start and end date is selected
                         */
                        const tempArray = countObjectsFrequency(obj?.value);
                        tempDateRangeFilter = [
                          ...tempDateRangeFilter,
                          ...tempArray,
                        ];
                        return false;
                      }
                    }
                    return true;
                  });

                  const finalFilters = e.filter;

                  /**
                   * merge date range and other filters
                   */
                  const normalFilterWithType = getMappedFilters({
                    dataColumns,
                    filters: normalFilters,
                  });
                  normalFilters = normalFilterWithType;
                  if (
                    normalFilters?.length > 0 ||
                    tempDateRangeFilter?.length > 0
                  ) {
                    finalFilters.filters = [
                      ...normalFilters,
                      ...tempDateRangeFilter,
                    ];
                  }

                  if (!isSingleDateSelected) props.setFilter(finalFilters);
                }
                /**
                 * Reset name filter if any name filter value change from grid
                 */
                if (gridFilterName?.FilterName?.length) {
                  setFilterName({});
                  setGridFilterName({});
                }
              }}
              filterOperators={FilterOperators}
              onItemChange={itemChange}
              editField={editField}
              total={Number(props.total)}
              filterable={
                isFilterable && (props?.filterable ? props?.filterable : true)
              }
              sortable={{ ...defaultSortableSettings, ...sortable }}
              sort={props.sort}
              onSortChange={(e) => {
                props.setSort(e.sort);
              }}
              dataItemKey={props.dataItemKey}
              rowRender={customRowRender}
              cellRender={customCellRender}
              selectedField={selectedField}
              selectable={selectableConfig}
              onSelectionChange={onSelectionChange}
              onHeaderSelectionChange={onHeaderSelectionChange}
              className={`data-grid sis-grid ${props.rowSizes} grid-selection-${selectableConfig.mode}`}
              reorderable={true}
              onColumnReorder={onColumnReorder}
              resizable={props.isResizable}
              {...pageArgs}
            >
              {isSelectable && (
                <Column
                  field={selectedField}
                  headerSelectionValue={ValidateHeaderSelection()}
                  orderIndex={ReorderColumIndex('selected')}
                  filterable={false}
                  width={'44px'}
                  reorderable={false}
                  className={'selected-header'}
                />
              )}
              {draggableToggle && (
                <Column title="" width="40px" cell={DragHandleCell} />
              )}
              {isFavourite && (
                <Column
                  field={'favorite'}
                  filterable={false}
                  width={'44px'}
                  reorderable={false}
                  cell={cellWithFavourite}
                  className={'favourite-header'}
                />
              )}
              {props.columns?.map((dataItem, i) => {
                const {
                  field,
                  title,
                  orderIndex,
                  width,
                  headerSelection,
                  tooltipField,
                  ...rDataItems
                } = dataItem;

                let tooltip = '';

                /**
                 * Render tooltip cell if we define tooltip field
                 */
                if (tooltipField) {
                  tooltip = {
                    cell: (props) =>
                      tooltipCell({
                        ...props,
                        tooltipField,
                      }),
                  };
                }
                return (
                  dataItem.show && (
                    <Column
                      {...tooltip}
                      {...rDataItems}
                      field={field}
                      title={title}
                      key={i}
                      orderIndex={ReorderColumIndex(
                        orderIndex ? orderIndex : field
                      )}
                      width={
                        width === 'autoWidth' ? calculateWidth(field) : width
                      }
                      headerSelectionValue={
                        headerSelection ? headerSelectionValue() : false
                      }
                    />
                  )
                );
              })}
              <GridNoRecords>
                <div>{noRecord()}</div>
              </GridNoRecords>
            </Grid>
          </DragAndDrop>
        </ReorderContext.Provider>
        {props.isScrollable && (
          <div
            className="k-pager k-widget k-grid-pager"
            role="application"
            aria-roledescription="pager"
          >
            <div className="k-pager-info k-label">
              {gridRecords} of {Number(props.total)} items
            </div>
          </div>
        )}
      </div>
    </Tooltip>
  );
};

export default React.forwardRef(DataGrid);
