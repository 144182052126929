import * as React from 'react';
import { MultiSelect } from '@progress/kendo-react-dropdowns';
import { Button } from '@progress/kendo-react-buttons';
import { orderBy, filterBy } from '@progress/kendo-data-query';
/**
 * Multiselect Filter Cell Class
 * @param {*} props
 * @returns {React.ReactElement} The DropdownFilterCell
 */
export const MultiSelectFilterCell = (props) => {
  const [inputValue, setInputValue] = React.useState([]);
  const [data, setData] = React.useState(props.data);
  React.useEffect(() => {
    if (props?.value?.length > 0) {
      setInputValue(props?.value);
    } else setInputValue([]);
  }, [props.value]);
  let hasValue = (value) => Boolean(value?.length > 0);
  let rowLayout = `${props.rowSizes} lb-list-item`;
  /**
   * Change Filter
   * @param {*} event
   */
  const onChange = (event) => {
    const isvalueExist = hasValue(event.target.value);
    setInputValue(isvalueExist ? event.target.value : []);
    props.onChange({
      text: isvalueExist ? event.target.value : '',
      value: isvalueExist ? event.target.value : '',
      operator: isvalueExist ? 'eq' : '',
      syntheticEvent: event.syntheticEvent,
      field: props.field ?? '',
      formRenderProps: props.formRenderProps ?? '',
    });
  };

  /**
   * Clear Filters
   * @param {*} event
   */
  const onClearButtonClick = (event) => {
    event.preventDefault();
    setInputValue([]);
    props.onChange({
      value: null,
      operator: '',
      syntheticEvent: event,
    });
  };

  /**
   * drop down list items renderer
   * @param {*} li list of elements
   * @returns rendered options
   */
  const itemRender = (li) => {
    const itemChildren = <span className={rowLayout}>{li.props.children}</span>;
    return React.cloneElement(li, li.props, itemChildren);
  };
  const filterChange = (event) => {
    setData(filterBy(props.data.slice(), event.filter));
  };
  return (
    <div className="k-filtercell">
      <MultiSelect
        data={orderBy(
          data?.filter(
            (f, index, self) =>
              index ===
              self.findIndex((t) => t.label === f.label && t.value === f.value)
          ) ?? [],
          [{ dir: 'asc', field: 'label' }]
        )}
        onChange={onChange}
        value={inputValue}
        textField="label"
        dataItemKey="value"
        filterable={true}
        itemRender={itemRender}
        allowCustom={true}
        required={props?.required ?? false}
        onFilterChange={filterChange}
        defaultItem={props.defaultItem}
      />
      {!(props?.hideClear ?? false) && (
        <Button
          title="Clear"
          disabled={!hasValue(inputValue)}
          onClick={onClearButtonClick}
          icon="filter-clear"
        />
      )}
    </div>
  );
};

export default MultiSelectFilterCell;
