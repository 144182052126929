import {
  setItem,
  getItem,
} from '../../../../Utils/Storage/LocalStorage/localStorageHandler';
import {
  DisplayNotification,
  NOTIFICATION_KEY,
} from '../../../../constants/applicationConstants';

/**
 * Save a notification object to the browser's local storage.
 * If local storage doesn't contain any notifications, create a new one and add the notification to it.
 * If local storage already has notification(s), add the new notification to the existing one(s).
 * @param {Object} notification - The notification to be saved to local storage.
 * @returns {void}
 */
export const saveNotification = (notification) => {
  let storeItem = getItem(NOTIFICATION_KEY);
  if (storeItem === null) {
    setItem(NOTIFICATION_KEY, [notification]);
    return;
  }
  // If local storage is empty, add notification to array
  if (storeItem.length === 0) {
    storeItem = [...storeItem, notification];
  } else {
    const existingIndex = storeItem.findIndex(
      (notify) => notify.notificationId === notification.notificationId
    );
    // If notification is not there is local storage then save the notification into local storage.
    if (existingIndex === -1) {
      storeItem = [...storeItem, notification];
    }
    // If notification is in local storage and count of notification is 0, then save the notification into local storage.
    else if ((notification.count ?? 0) === 0)
      storeItem = [...storeItem, notification];
    // If Notification is not there, and notification is of same type with same time, then update local storage.
    else
      storeItem[existingIndex] = {
        ...storeItem[existingIndex],
        ...notification,
      };
  }
  setItem(NOTIFICATION_KEY, storeItem);
  window.dispatchEvent(new Event('storage'));
};

/**
 * Remove Notification from local storage
 * @param {*} notification
 */
export const removeNotification = (notificationId) => {
  let storeItem = getItem(NOTIFICATION_KEY);
  var filtered = storeItem.filter(
    (value) => value.notificationId !== notificationId
  );
  setItem(NOTIFICATION_KEY, filtered);
  window.dispatchEvent(new Event('storage'));
};
/**
 * set Notification isRead in local storage
 * @param {*} notification
 */
export const readNotification = (notificationId) => {
  let storeItem = getItem(NOTIFICATION_KEY);
  const readNotifications = storeItem.map((item) => {
    if (item.notificationId === notificationId) {
      item.isRead = true;
    }
    return item;
  });
  setItem(NOTIFICATION_KEY, readNotifications);
  window.dispatchEvent(new Event('storage'));
};

/**
 * Remove All Notifications
 */
export const removeAllNotifications = () => {
  setItem(NOTIFICATION_KEY, []);
  window.dispatchEvent(new Event('storage'));
};

/**
 * Update all panel notifications and hide them from being displayed.
 */
export const updatePanelAllNotifications = () => {
  let storeItem = getItem(NOTIFICATION_KEY);

  // Map through each item in the storeItem array
  const panelNotifications = storeItem?.map((item) => {
    // Set the display property of the item to DisplayNotification.HIDDEN
    item.display = DisplayNotification.HIDDEN;
    return item;
  });

  // Update the storeItem with the updated panelNotifications array
  setItem(NOTIFICATION_KEY, panelNotifications);
  window.dispatchEvent(new Event('storage'));
};

/**
 * Update a specific panel notification and hide it from being displayed.
 * @param {string} notificationId - The ID of the notification to be updated and hidden.
 */
export const updatePanelNotification = (notificationId) => {
  let storeItem = getItem(NOTIFICATION_KEY);

  // Map through each item in the storeItem array
  const panelNotifications = storeItem?.map((item) => {
    // If the item's notificationId matches the provided notificationId
    if (item.notificationId === notificationId) {
      // Set the display property of the item to DisplayNotification.HIDDEN
      item.display = DisplayNotification.HIDDEN;
    }
    return item;
  });

  // Update the storeItem with the updated panelNotifications array
  setItem(NOTIFICATION_KEY, panelNotifications);
  window.dispatchEvent(new Event('storage'));
};
