import React from 'react';
import { FieldWrapper } from '@progress/kendo-react-form';
import { Input } from '@progress/kendo-react-inputs';
import { Error, Hint, Label } from '@progress/kendo-react-labels';
import { RenderTypes } from '../../../../constants/applicationConstants';
import './TextInput.scss';

/**
 * Input component of field to set default value for mobile view filters
 * TextInput component that renders a form input field
 * @param {object} fieldRenderProps - the properties for the field input component
 * @returns {JSX.Element} - the TextInput component
 */
export const TextInput = (fieldRenderProps) => {
  const {
    validationMessage,
    touched,
    label,
    id,
    valid,
    disabled,
    hint,
    type,
    optional,
    max,
    data,
    required,
    readOnly,
    value,
    renderTypes,
    fieldWrapperStyles,
    requiredField,
    prefixLabel,
    prefix,
    ...others
  } = fieldRenderProps;
  const showValidationMessage = touched && validationMessage;
  const showHint = !showValidationMessage && hint;
  const hintId = showHint ? `${id}_hint` : '';
  const errorId = showValidationMessage ? `${id}_error` : '';
  return (
    <FieldWrapper>
      {label && (
        <span className="k-floating-label-container k-label">
          <Label
            editorId={id}
            editorValid={valid}
            editorDisabled={disabled}
            optional={optional}
          >
            {label} {requiredField && <span className="required-field">*</span>}
          </Label>
        </span>
      )}
      {renderTypes === RenderTypes.ReadOnly ? (
        <span className="display-text">{data}</span>
      ) : (
        <div className={`${prefix && 'prefix-wrapper'}`}>
          {prefix && (
            <div>
              <span className="prefix-label">{prefixLabel}</span>
            </div>
          )}
          <div className={'k-form-field-wrap'} style={fieldWrapperStyles}>
            <Input
              valid={valid}
              type={type}
              id={id}
              {...others}
              value={data}
              required={required}
              disabled={disabled}
            />
            {showHint && <Hint id={hintId}>{hint}</Hint>}
            {showValidationMessage && (
              <Error id={errorId}>{validationMessage}</Error>
            )}
          </div>
        </div>
      )}
    </FieldWrapper>
  );
};

export default TextInput;
