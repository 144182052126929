import React from 'react';
import {
  TreeList,
  mapTree,
  extendDataItem,
  getSelectedState,
  getSelectedStateFromKeyDown,
} from '@progress/kendo-react-treelist';

/**
 * Tree List Constants Starts
 */
const EXPAND_FIELD = 'expanded';
const SELECTED_FIELD = 'selected';
const dragEnabled = false;
const cellEnabled = true;
const selectionMode = 'single';

/**
 * Tree List Constants end
 */
/**
 * Lookup Sidebar
 * Includes TreeView of Data
 */
const LookupTreeList = ({
  dataSet,
  setSelectedTreeObj,
  dataItemKey,
  subItemField,
  idGetter,
  columns,
}) => {
  /**
   * Tree List Start
   */

  /**
   * Used to mapped the dataset according to TreeList
   * @param {Array} dataState
   * @param {Object} selectedState
   * @param {Object} expandedState
   * @returns
   */
  const extendData = (dataState, selectedState, expandedState) => {
    const tree = mapTree(dataState, subItemField, (item) =>
      extendDataItem(item, subItemField, {
        selected: selectedState[idGetter(item)],
        expanded: expandedState[idGetter(item)],
      })
    );
    return tree;
  };

  const [selectedState, setSelectedState] = React.useState({});
  const [expandedState, setExpandedState] = React.useState({
    1: true,
  });

  const onExpandChange = React.useCallback(
    (e) => {
      setExpandedState({
        ...expandedState,
        [idGetter(e.dataItem)]: !e.value,
      });
    },
    [expandedState]
  );
  const onSelectionChange = (event) => {
    /**
     * Get All DataItems of Selected Tree
     */
    const { dataItems } = event;

    const newSelectedState = getSelectedState({
      event,
      selectedState,
      dataItemKey,
    });
    let currentSelectedTreeObj = [];

    /**
     * Filter out current selected object
     */
    Object.keys(newSelectedState).forEach((item) => {
      if (item) {
        currentSelectedTreeObj = event.target.flattedData.find(
          (obj) => obj?.dataItem[dataItemKey] === item
        );
      }
    });

    /**
     * Parse out parents based on the level object in
     */
    const parent = [];
    currentSelectedTreeObj?.level.forEach((obj, i) => {
      if (i === 0) {
        parent.push(dataItems[obj]);
      } else {
        const child = parent[parent.length - 1][subItemField][obj];
        parent.push(child);
      }
    });
    if (parent?.length) {
      currentSelectedTreeObj.parents = parent;
    }

    setSelectedTreeObj(currentSelectedTreeObj);
    setSelectedState(newSelectedState);
  };
  const onKeyDown = (event) => {
    const newSelectedState = getSelectedStateFromKeyDown({
      event,
      selectedState,
      dataItemKey,
    });
    setSelectedState(newSelectedState);
  };

  return (
    <TreeList
      style={{
        maxHeight: '510px',
        overflow: 'auto',
      }}
      className="lookup-tree"
      data={extendData(dataSet, selectedState, expandedState)}
      selectedField={SELECTED_FIELD}
      expandField={EXPAND_FIELD}
      subItemsField={subItemField}
      dataItemKey={dataItemKey}
      selectable={{
        enabled: true,
        drag: dragEnabled,
        cell: cellEnabled,
        mode: selectionMode,
      }}
      navigatable={true}
      onSelectionChange={onSelectionChange}
      onExpandChange={onExpandChange}
      onKeyDown={onKeyDown}
      columns={columns}
    />
  );
};

export default LookupTreeList;
