/**
 * Checks if the session storage is available in the current browser, and adds a key-value pair to the session storage if available.
 * @returns {boolean} A boolean value indicating if the session storage is available.
 */
const isSessionStorageAvailable = () => {
  let key = 'test';
  try {
    sessionStorage.setItem(key, key);
    sessionStorage.removeItem(key);
    return true;
  } catch (e) {
    return false;
  }
};
export default isSessionStorageAvailable;
