import * as React from 'react';
import { DatePicker } from '@progress/kendo-react-dateinputs';
import { Button } from '@progress/kendo-react-buttons';
import moment from 'moment';

/**
 * Date Range Filter cell
 * @param {*} props
 * @returns
 */
export const DateRangeFilter = (props) => {
  const [minDateFilter, setMinDateFilter] = React.useState(null);
  const [maxDateFilter, setMaxDateFilter] = React.useState(null);
  const { dateRangefilter, setDateRangeFilter, field } = props;
  const getDateFilterVal = (operator) => {
    const filterVal = dateRangefilter?.find(
      (obj) => obj.field === field && obj.operator === operator
    );
    return filterVal?.value === null ? '' : filterVal?.value;
  };
  React.useEffect(() => {
    setMinDateFilter(getDateFilterVal('gte'));
    setMaxDateFilter(getDateFilterVal('lte'));
  }, [dateRangefilter]);

  /**
   * Handle change on date filter
   * @param {*} event
   */
  const onDateFilterChange = (event) => {
    let currentFilters = [...dateRangefilter];
    if (event.operator === 'gte') {
      setMinDateFilter(event.value);
    } else {
      setMaxDateFilter(event.value);
    }
    if (currentFilters?.length > 0) {
      let newFilter = currentFilters?.filter(
        (obj) => !(obj.field === field && obj.operator === event.operator)
      );
      currentFilters = newFilter;
      /**
       * Fitler out the Unique item of Date Range
       */
      const uniqueFilterItem = dateRangefilter?.reduce((acc, curr) => {
        let existing = acc.find((item) => item.field === curr.field);
        if (!existing) {
          acc.push({ field: curr.field, operator: curr.operator });
        }
        return acc;
      }, []);
      /**
       * Remove Duplicate item from filters
       */
      let finalizedFilterList = [];
      uniqueFilterItem.forEach((obj) => {
        finalizedFilterList = dateRangefilter?.filter(
          (nobj) => nobj.field === obj.field && nobj.operator === obj.operator
        );
      });
      if (event.value)
        currentFilters.push({
          field,
          operator: event.operator,
          value:
            moment(event.value).startOf('day').format('YYYY-MM-DD') ?? null,
        });
    } else if (event.value)
      currentFilters.push({
        field,
        operator: event.operator,
        value: moment(event.value).startOf('day').format('YYYY-MM-DD') ?? null,
      });
    setDateRangeFilter(currentFilters);
    if (currentFilters?.length > 0) {
      props.onChange({
        text: event.value?.label,
        value: currentFilters?.filter((f) => f.field === field) ?? [],
        operator: event.operator,
        type: 'dateRange',
        syntheticEvent: event.syntheticEvent,
        field: props.field ?? '',
        formRenderProps: props.formRenderProps ?? '',
      });
    }
  };

  const onClearButtonClick = (event) => {
    event.preventDefault();
    let currentFilters = [...dateRangefilter];
    let newFilter = currentFilters.filter((obj) => obj.field !== field);
    currentFilters = newFilter;
    setMinDateFilter(null);
    setMaxDateFilter(null);
    setDateRangeFilter(newFilter);
    props.onChange({
      text: newFilter?.length > 0 ? event.value?.label : '',
      value: newFilter?.length > 0 ? currentFilters : '',
      operator: newFilter?.length > 0 ? event.operator : '',
      type: 'dateRange',
      syntheticEvent: event.syntheticEvent,
      field: field,
      formRenderProps: props.formRenderProps ?? '',
    });
  };
  return (
    <div className="k-filtercell">
      <DatePicker
        value={
          minDateFilter
            ? typeof minDateFilter === 'string'
              ? new Date(minDateFilter?.replaceAll('-', '/'))
              : minDateFilter
            : null
        }
        onChange={(e) => {
          onDateFilterChange({
            value: e.target.value,
            operator: 'gte',
          });
        }}
      />
      <DatePicker
        value={
          maxDateFilter
            ? typeof maxDateFilter === 'string'
              ? new Date(maxDateFilter?.replaceAll('-', '/'))
              : maxDateFilter
            : null
        }
        onChange={(e) => {
          onDateFilterChange({
            value: e.target.value,
            operator: 'lte',
          });
        }}
      />
      <Button
        title="Clear"
        disabled={!(minDateFilter || maxDateFilter)}
        onClick={onClearButtonClick}
        icon="filter-clear"
      />
    </div>
  );
};

export default DateRangeFilter;
