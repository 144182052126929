import * as React from 'react';

/**
 * Render The Columns for Grid
 * Show Checkbox if value is boolean
 * @param {Object} props
 * @returns {React.Element} React Element
 */
export const CellRender = (props) => {
  const { dataItem } = props.originalProps;
  const cellField = props.originalProps.field;
  const inEditField = dataItem[props.editField || ''];
  const additionalProps =
    cellField && cellField === inEditField
      ? {
          ref: (td) => {
            const input = td && td.querySelector('input');
            const { activeElement } = document;

            if (
              !input ||
              !activeElement ||
              input === activeElement ||
              !activeElement.contains(input)
            ) {
              return;
            }

            if (input.type === 'checkbox') {
              input.focus();
            } else {
              input.select();
            }
          },
        }
      : {
          onDoubleClick: () => {
            props.enterEdit(dataItem, cellField);
          },
        };
  const clonedProps = { ...props.td.props, ...additionalProps };
  return React.cloneElement(props.td, clonedProps, props.td.props.children);
};

/**
 * Render The Row for Grid
 * @param {Object} props
 * @returns {React.Element} React Element
 */
export const RowRender = (props) => {
  const trProps = {
    ...props.tr.props,
    className: props?.isModified ?? false ? ' modifed-row' : '',
    onBlur: () => {},
  };
  return React.cloneElement(props.tr, { ...trProps }, props.tr.props.children);
};
