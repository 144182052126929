/**
 * parse the Date Range object value from array
 * Used to reset the date range fields value
 * @param {OBject} arr
 * @returns
 */
export const countObjectsFrequency = (arr) => {
  let frequency = {};
  arr.forEach((obj) => {
    let key = obj?.field;
    frequency[key] = (frequency[key] || 0) + 1;
  });
  const finalArray = [];
  Object.entries(frequency)
    .filter((obj) => obj[1] > 0)
    .forEach(([key]) => {
      const filterdObj = arr.filter((obj) => obj.field === key);
      filterdObj.forEach((obj) => finalArray.push(obj));
    });
  return finalArray;
};

/**
 * map filters with types from data columns array
 * @param {*} dataTableName
 * @param {*} filters
 * @returns {array} returns mapped array with data type
 */
export const getMappedFilters = ({ dataTableName, dataColumns, filters }) => {
  const filtersArray = [];

  filters?.forEach((item) => {
    // Check if the value is an array and if it's empty
    const isValueArrayAndEmpty =
      (Array.isArray(item?.value) && item.value.length === 0) ||
      item.value.length === 0;

    // Only push the item to filtersArray if:
    // - The value is not an empty array
    // - Or the value is not an array at all
    if (!isValueArrayAndEmpty) {
      filtersArray.push({
        field: item?.field,
        operator: item?.operator,
        value: item?.value,
        type:
          dataColumns?.length > 0
            ? (dataColumns?.find((f) => f.field === item.field)?.type ?? 'text')
            : (getDataTableColumns(dataTableName)?.find(
                (f) => f.field === item.field
              )?.type ?? 'text'),
      });
    }
  });
  return filtersArray;
};

/**
 * get Variable Type for the passed data.
 * @param {*} value
 * @returns {dataType} returns datatype of object, string array or numeric
 */
export function getVariableType(type, value) {
  switch (type) {
    case 'date':
      return value ? 'Date' : null;
    case 'dateRange':
      return value ? 'DateRange' : null;
    case 'multiSelect':
    case 'checkboxList':
      return (value?.length ?? 0) > 0 ? 'Array' : null;
    case 'numeric':
      return value !== null && value !== undefined ? 'Numeric' : null;
    default:
      return type;
  }
}
/**
 * get Data Columns based on data table name
 * @param {*} dataTableName
 */
export const getDataTableColumns = (dataTableName) => {
  let dataColumns = [];
  switch (dataTableName) {
    default:
      break;
  }
  return dataColumns;
};
