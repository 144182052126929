// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.k-upload ul.k-upload-files.k-reset {
  display: flex;
  flex-wrap: wrap;
}
.k-upload ul.k-upload-files.k-reset li.k-file {
  margin: 0;
  padding-bottom: 10px;
  padding-top: 10px;
  border: none;
}
.k-upload ul.k-upload-files.k-reset li.k-file .k-progressbar {
  display: none;
}
.k-upload .k-upload-selected {
  display: none;
}

.upload-box-wrapper {
  display: flex;
}
.upload-box-wrapper .upload-dropbox {
  width: 80%;
}
.upload-box-wrapper .upload-box-action {
  padding-left: 18px;
  width: 20%;
}
.upload-box-wrapper .upload-box-action .upload-file-icon {
  position: relative;
  text-align: center;
  border: 1px solid rgba(0, 0, 0, 0.12);
  padding: 10px;
  border-radius: 6px;
}
.upload-box-wrapper .upload-box-action .upload-file-icon .k-icon {
  font-size: 24px;
}
.upload-box-wrapper .upload-box-action .upload-file-icon .k-upload {
  position: absolute;
  top: 0;
  opacity: 0;
}`, "",{"version":3,"sources":["webpack://./src/Modules/Core/Components/FormElements/Upload/Upload.scss"],"names":[],"mappings":"AAGM;EACE,aAAA;EACA,eAAA;AAFR;AAIU;EACE,SAAA;EACA,oBAAA;EACA,iBAAA;EACA,YAAA;AAFZ;AAGY;EACE,aAAA;AADd;AAQE;EACE,aAAA;AANJ;;AAUA;EACE,aAAA;AAPF;AAQE;EACE,UAAA;AANJ;AAQE;EACE,kBAAA;EACA,UAAA;AANJ;AAOI;EACE,kBAAA;EACA,kBAAA;EACA,qCAAA;EACA,aAAA;EACA,kBAAA;AALN;AAMM;EACE,eAAA;AAJR;AAMM;EACE,kBAAA;EACA,MAAA;EACA,UAAA;AAJR","sourcesContent":[".k-upload {\n  ul {\n    &.k-upload-files {\n      &.k-reset {\n        display: flex;\n        flex-wrap: wrap;\n        li {\n          &.k-file {\n            margin: 0;\n            padding-bottom: 10px;\n            padding-top: 10px;\n            border: none;\n            .k-progressbar {\n              display: none;\n            }\n          }\n        }\n      }\n    }\n  }\n  .k-upload-selected {\n    display: none;\n  }\n}\n\n.upload-box-wrapper {\n  display: flex;\n  .upload-dropbox {\n    width: 80%;\n  }\n  .upload-box-action {\n    padding-left: 18px;\n    width: 20%;\n    .upload-file-icon {\n      position: relative;\n      text-align: center;\n      border: 1px solid rgba(0, 0, 0, 0.12);\n      padding: 10px;\n      border-radius: 6px;\n      .k-icon {\n        font-size: 24px;\n      }\n      .k-upload {\n        position: absolute;\n        top: 0;\n        opacity: 0;\n      }\n    }\n  }\n}\n"],"sourceRoot":""}]);
// Exports
export default ___CSS_LOADER_EXPORT___;
