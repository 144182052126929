import React from 'react';
import { Button } from '@progress/kendo-react-buttons';
import { Form } from '@progress/kendo-react-form';
import '../../../../../assets/scss/common/Slider.scss';
import { RadioGroup, Switch } from '@progress/kendo-react-inputs';
import SliderCard from '../../Slider/SliderCard';
import './Sort.scss';
import {
  TOGGLE_SORT_DATATABLE_SLIDER,
  TOGGLE_CANCEL_DATATABLE_SLIDER,
} from '../../../../../constants/actionTypes';
/**
 * Sorting slider card for mobile
 * @param {*} props
 * @returns {React.Component} returns sort component
 */
const Sort = (props) => {
  const { title, handleSlide, dataColumns, dataTableName, initialSort } = props;
  const [sort, setSort] = React.useState(props.sort);
  const [gridColumns, setGridColumns] = React.useState([]);
  const [radioGroupData, setRadioGroupData] = React.useState([]);
  const [desc, setDesc] = React.useState(false);
  const [formReset, setFormReset] = React.useState(false);
  const sortMode = process.env?.SortMode ?? false;

  React.useEffect(() => {
    setSort(props.sort[0]);
    setDesc(props.sort[0]?.dir === 'desc' ? true : false);
  }, [props.sort]);

  /**
   * Handle Sorting Order of Cards on selected field
   * @param {Object} e
   */
  const orderChange = (formRenderProps, column) => {
    formRenderProps.onChange('dir', {
      value: !desc,
    });
    if (!sortMode) {
      // Get All Columns
      const gcolumns = gridColumns?.map((cl) => {
        if (cl.field === column.field) {
          cl.sortAsc = !column.sortAsc;
        }
        return cl;
      });

      // Set Columns in state level variable
      setGridColumns(gcolumns);
    }

    // Set Sort if sort on just single column is enable
    if (sortMode) {
      setDesc(!desc);
      setSort({ field: sort.field, dir: !desc ? 'desc' : 'asc' });
    }
  };

  /**
   * Handle Sorting Order of Cards (ACS/DESC)
   * @param {Object} e
   */
  const handleChange = (e, formRenderProps, column = '') => {
    let gcolumns = [];
    if (sortMode) {
      gcolumns = gridColumns?.map((cl) => {
        if (cl.field === e.value) {
          cl.sort = true;
        } else {
          cl.sort = false;
        }
        return cl;
      });
    } else {
      gcolumns = gridColumns?.map((cl) => {
        if (cl.field === column.field) {
          cl.sort = !column.sort;
        }
        return cl;
      });
    }
    setGridColumns(gcolumns);
    formRenderProps.onChange('sort', {
      value: e.value,
    });
  };

  /** Apply Sorting on RadioButtons */
  const applySorting = (e) => {
    const sortObj = [];
    gridColumns.forEach((cl) => {
      if (cl.sort) {
        sortObj.push({ field: cl.field, dir: !cl.sortAsc ? 'desc' : 'asc' });
      }
    });
    props.setSort(sortObj);
    handleSlide({ action: TOGGLE_SORT_DATATABLE_SLIDER });
  };

  /**
   *reset Sort To Default
   */
  const resetSortToDefault = () => {
    // reset gridColumns
    const gcolumns = gridColumns?.map((cl) => {
      if (cl.field === initialSort?.field) {
        cl.sortAsc = initialSort?.dir === 'desc' ? false : true;
        cl.sort = true;
      } else {
        cl.sortAsc = false;
        cl.sort = false;
      }
      return cl;
    });
    // Set Columns in state level variable
    setGridColumns(gcolumns);
    // enable allow submit
    setFormReset(true);
  };

  /**
   * Get Data Columns
   * Set Sorted Value for all columns
   */
  React.useEffect(() => {
    const GridColumnsParsed = dataColumns({ gridDateCell: dataTableName });
    const gcolumns = GridColumnsParsed.map((cl) => {
      props.sort.forEach((sortCl) => {
        if (cl.field === sortCl.field) {
          cl.sort = true;
          cl.sortAsc = sortCl.dir === 'asc' ? true : false;
        }
      });
      return cl;
    });
    if (sortMode) {
      const radioGroupDataTemp = gcolumns?.map((item) => ({
        label: item.title,
        value: item.field,
      }));
      setRadioGroupData(radioGroupDataTemp);
    }
    setGridColumns(gcolumns);
  }, [props.sort]);

  return (
    <Form
      className="sort-wrapper"
      render={(formRenderProps) => (
        <SliderCard
          title={title}
          handleSlide={handleSlide}
          className={'default-slider-wrapper'}
        >
          <SliderCard.Body>
            <fieldset className="k-form-fieldset">
              {sortMode ? (
                <div className="sort-controls-item">
                  <RadioGroup
                    name="group1"
                    className="sort-control"
                    defaultValue={props.sort[0]?.field}
                    data={radioGroupData}
                    textField="title"
                    dataItemKey="field"
                    onChange={(e) => handleChange(e, formRenderProps)}
                  />
                </div>
              ) : (
                <div className="grid-sort-wrapper">
                  {gridColumns?.map((column, i) => (
                    <div className="sort-button-wrapper" key={i}>
                      <div className="card-col card-col-4 switchable">
                        <Switch
                          label={column.title}
                          name={column.field}
                          defaultChecked={column?.sort}
                          checked={column?.sort}
                          onChange={(e) =>
                            handleChange(e, formRenderProps, column)
                          }
                          onLabel={'Yes'}
                          offLabel={'No'}
                        />
                        <strong className="heading">{column.title} </strong>
                      </div>
                      <Button
                        icon={column?.sortAsc ? 'sort-asc' : 'sort-desc'}
                        type="button"
                        themeColor={'primary'}
                        onClick={() => orderChange(formRenderProps, column)}
                        className="left-sort-controls-icon"
                      />
                    </div>
                  ))}
                </div>
              )}
            </fieldset>
          </SliderCard.Body>
          <SliderCard.Footer>
            <Button
              icon={'cancel'}
              onClick={() =>
                handleSlide({ action: TOGGLE_CANCEL_DATATABLE_SLIDER })
              }
              type="button"
            >
              Cancel
            </Button>
            <Button
              icon={'reset'}
              themeColor={'primary'}
              onClick={() => {
                // Get All Columns
                resetSortToDefault(formRenderProps);
              }}
              fillMode="outline"
              className="action-button"
            >
              Reset
            </Button>
            <Button
              icon={'check'}
              themeColor={'primary'}
              disabled={!(formRenderProps.allowSubmit || formReset)}
              className="action-button"
              onClick={() => applySorting(formRenderProps)}
            >
              Sort
            </Button>
            {sortMode && (
              <Button
                icon={desc ? 'sort-desc' : 'sort-asc'}
                type="button"
                themeColor={'primary'}
                onClick={() => orderChange(formRenderProps)}
                className="left-sort-controls-icon"
              />
            )}
          </SliderCard.Footer>
        </SliderCard>
      )}
    />
  );
};

export default Sort;
