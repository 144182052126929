// DataTables Module
export const EDIT_DATA = 'EDIT_DATA'; // Update the Single Data Item
export const UPDATE_DATA_LIST = 'UPDATE_DATA_LIST'; // Update the data in bulk.
export const GET_DATA_LIST = 'GET_DATA_LIST'; // Update The data set by appending the new Items Data
export const GET_DATA = 'GET_DATA'; // Update the dataList state by just appending the Single Item Data
export const INIT_DATA_LIST = 'INIT_DATA_LIST '; // Initialize the DataTable Data State
export const GET_DATA_BY_ID = 'GET_DATA_BY_ID '; // get the data set by id
export const CHECKED_ITEM_DATA_LIST = 'CHECKED_ITEM_DATA_LIST '; // handle checked data set
export const SELECTED_STATE = 'SELECTED_STATE '; // handle selected state
export const SET_DATA_KEY = 'SET_DATA_KEY '; // used to set unique Data key

// Network Status
export const SET_NETWORK_STATUS = 'SET_NETWORK_STATUS';
export const GET_NETWORK_STATUS = 'GET_NETWORK_STATUS';

export const TOGGLE_NAMED_FILTER_SLIDER = 'NAMED_FILTER';

// Slider Actions Constant
export const TOGGLE_ADD_SLIDER = 'ADD';
export const TOGGLE_EDIT_SLIDER = 'EDIT';
export const TOGGLE_SORT_DATATABLE_SLIDER = 'SORT';
export const TOGGLE_FILTER_DATATABLE_SLIDER = 'FILTER';
export const TOGGLE_PROCESS_SLIDER = 'PROCESS';
export const TOGGLE_RESET_SORT_DATATABLE_SLIDER = 'RESET_SORT';
export const TOGGLE_CANCEL_DATATABLE_SLIDER = 'CANCEL';
export const TOGGLE_CLOSE_DATATABLE_SLIDER = 'CLOSE';
