import React from 'react';
import { FieldWrapper } from '@progress/kendo-react-form';
import { Label } from '@progress/kendo-react-labels';
import { NumericTextBox } from '@progress/kendo-react-inputs';
import { Button } from '@progress/kendo-react-buttons';
import {
  DataTypes,
  RenderTypes,
} from '../../../../constants/applicationConstants';
import { parseCurrencyString } from '../../../../Utils/Common/utils';
import { messageGenerator } from '../../../../Utils/Notifications/notificationUtils';

/**
 * NumericInput component of Date Picker field
 * NumericInput component that renders a form numeric field
 * @param {object} fieldRenderProps - the properties for the field numeric component
 * @returns {JSX.Element} - the NumericInput component
 */
export const NumericInput = (props) => {
  const {
    label,
    id,
    valid,
    disabled,
    type,
    optional,
    data,
    renderTypes,
    value,
    isCurrency = false,
    spinners = true,
    min,
    max,
    formRenderProps,
  } = props;

  const [numericValue, setNumericValue] = React.useState(data);

  /**
   * handle Change
   * @param {*} e
   * @param {*} opType
   */
  const handleChange = (e, opType) => {
    let val = e?.target?.value ?? '';
    if (props.onChange) {
      if (opType)
        val = opType === 'increase' ? numericValue + 1 : numericValue - 1;
      if (val > (max ?? Infinity)) {
        val = max;
        messageGenerator({
          title: 'Maximum Limit Reached',
          message: `Please enter a value  less than ${max}`,
          style: 'error',
          timeout: 0,
        });
      }
      if (val < (min ?? -Infinity)) {
        val = min;
        messageGenerator({
          title: 'Minimum Limit Reached',
          message: `Please enter a value  greater than ${min}`,
          style: 'error',
          timeout: 0,
        });
      }
      if (val < (min ?? -Infinity) || val > (max ?? Infinity))
        val = val < min ? min : max;
      setNumericValue(val);
      props.onChange({
        field: props.field,
        value: val,
        type: DataTypes.Numeric,
        formRenderProps,
      });
    }
  };
  return (
    <FieldWrapper>
      {label && (
        <span className="k-floating-label-container k-label">
          <Label
            editorId={id}
            editorValid={valid}
            editorDisabled={disabled}
            optional={optional}
          >
            {label}
          </Label>
        </span>
      )}
      {renderTypes === RenderTypes.ReadOnly ? (
        <span className="display-text">
          {isCurrency ? parseCurrencyString(value) : value ?? ''}
        </span>
      ) : (
        <div className={'k-form-field-wrap'}>
          <div className="k-numeric-wrap">
            {!spinners && (
              <Button
                themeColor="primary"
                onClick={(e) => handleChange(e, 'decrease')}
                className="k-numeric-decrease"
              >
                -
              </Button>
            )}
            <NumericTextBox
              value={numericValue}
              format={isCurrency ? 'c' : 'n'}
              valid={valid}
              type={type}
              id={id}
              spinners={spinners}
              onChange={(e) => handleChange(e)}
            />
            {!spinners && (
              <Button
                onClick={(e) => handleChange(e, 'increase')}
                themeColor="primary"
                className="k-numeric-increase"
              >
                +
              </Button>
            )}
          </div>
        </div>
      )}
    </FieldWrapper>
  );
};

export default NumericInput;
