import React from 'react';
import { Badge, BadgeContainer } from '@progress/kendo-react-indicators';
import {
  DisplayNotification,
  NOTIFICATION_KEY,
} from '../../../../constants/applicationConstants';
import { getItem } from '../../../../Utils/Storage/LocalStorage/localStorageHandler';
import NotificationList from './NotificationList';
import DropDownMenu from '../DropDownMenu/DropDownMenu';
import { readNotification } from './NotificationHandler';

/**
 * Component for displaying notification icon and notification list
 * Handle notification icon and notification dropdown list
 * @return {ReactNode} NotificationIcon component
 */
const NotificationIcon = () => {
  // #region react hook
  const [count, setCount] = React.useState(0); // Count of notifications
  const [notifications, setNotifications] = React.useState([]); // Notifications List
  const [isActiveDropdown, setIsActiveDropdown] = React.useState();
  const [isDismissed, setIsDismissed] = React.useState(false);

  React.useEffect(() => {
    notificationHandle();
  }, []);
  React.useEffect(() => {
    if (isActiveDropdown === false) {
      const notificationArray = getItem(NOTIFICATION_KEY);
      notificationArray?.forEach((item) => {
        readNotification(item?.notificationId);
      });
    }
  }, [isActiveDropdown]);
  /**
   * Set state on the counts
   * @param {*} newCounts
   */
  const handleSetCounts = (newCounts) => {
    setCount(newCounts);
  };
  // #endregion react hook

  /**
   * Handle notification. Process Notifications from the Local storage and show them into Notification Menu
   */
  const notificationHandle = () => {
    let notificationArray = getItem(NOTIFICATION_KEY);
    if (notificationArray) {
      notificationArray = notificationArray?.filter(
        (notification) =>
          notification.display ===
          (DisplayNotification.SHOW || DisplayNotification.SILENT)
      );
      setNotifications(notificationArray);
      const notificationCount =
        notificationArray === null ? 0 : notificationArray?.length;
      setCount(notificationCount);
    }
  };

  /**
   * Calls when add/Update in local stroage
   */
  window.onstorage = () => {
    notificationHandle();
  };

  return (
    <DropDownMenu
      setIsDropdownOpen={setIsActiveDropdown}
      isDismissed={isDismissed}
      setIsDismissed={setIsDismissed}
    >
      <DropDownMenu.Header>
        <button
          type="button"
          className="k-button k-button-md k-rounded-md k-button-flat k-button-flat-base"
        >
          <BadgeContainer>
            <span className="k-icon sis-ico-notifications" />
            {count > 0 && <Badge>{count < 100 ? <>{count}</> : '99+'}</Badge>}
          </BadgeContainer>
        </button>
      </DropDownMenu.Header>
      <DropDownMenu.Body className="notification-detail-main">
        <NotificationList
          counts={count}
          setCounts={handleSetCounts}
          notifications={notifications}
          setNotifications={setNotifications}
          setIsDismissed={setIsDismissed}
        />
      </DropDownMenu.Body>
    </DropDownMenu>
  );
};

export default NotificationIcon;
