import * as React from 'react';
import { NumericTextBox } from '@progress/kendo-react-inputs';
import { Hint } from '@progress/kendo-react-labels';
import { Button } from '@progress/kendo-react-buttons';
import { messageGenerator } from '../../../../Utils/Notifications/notificationUtils';

/**
 * NumericFilter For Inline Grid Operations Cell Class
 * @param {*} props
 * @returns {React.ReactElement} The NumericFilter
 */
export const NumericFilter = (props) => {
  const { dataItem, field, required = false, formRenderProps } = props;
  const min = props?.setMinNumber ? props?.minNumber : -Infinity;
  const max = props?.setMaxNumber ? props?.maxNumber : Infinity;

  let value = '';
  if (field.includes('.')) {
    const fields = field?.split('.');
    value = dataItem[fields[0]][fields[1]];
  } else {
    value = dataItem[field];
  }

  const dataValue = value === null ? '' : value;

  /**
   * Handle change event on numeric filter
   * @param {Object} e
   */
  const handleChange = (e, type) => {
    let val = e?.target?.value ?? '';
    if (props.onChange) {
      if (type) val = type === 'increase' ? dataValue + 1 : dataValue - 1;

      if (props.setMaxNumber && val > props.maxNumber) {
        val = props.maxNumber;
        messageGenerator({
          title: 'Maximum Limit Reached',
          message: `Please enter a value  less than ${props.maxNumber}`,
          style: 'error',
          timeout: 0,
        });
      }
      if (props.setMinNumber && val < props.minNumber) {
        val = props.minNumber;
        messageGenerator({
          title: 'Minimum Limit Reached',
          message: `Please enter a value  greater than ${props.minNumber}`,
          style: 'error',
          timeout: 0,
        });
      }
      if (val < min || val > max) val = val < min ? min : max;
      props.onChange({
        dataIndex: 0,
        dataItem: props.dataItem,
        field: props.field,
        syntheticEvent: e.syntheticEvent,
        value: val,
        formRenderProps,
      });
    }
  };

  return (
    <td>
      {dataItem.inEdit ? (
        <>
          <div className={'k-form-field-wrap'}>
            <div className="k-numeric-wrap">
              <Button
                themeColor="primary"
                onClick={(e) => handleChange(e, 'decrease')}
                className="k-numeric-decrease"
              >
                -
              </Button>

              <NumericTextBox
                format={props?.isCurrency ? 'c' : 'n'}
                value={dataValue ? parseInt(dataValue) : 0}
                onChange={handleChange}
                spinners={false}
              />
              <Button
                onClick={(e) => handleChange(e, 'increase')}
                themeColor="primary"
                className="k-numeric-increase"
              >
                +
              </Button>
            </div>
          </div>
          <Hint className="k-form-error">
            {required && !dataValue && dataItem.isChange
              ? 'Please enter a value'
              : ''}
          </Hint>
        </>
      ) : (
        <>{dataValue ? parseInt(dataValue) : 0}</>
      )}
    </td>
  );
};
export default NumericFilter;
