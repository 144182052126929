import React from 'react';
import { Upload } from '@progress/kendo-react-upload';
import './Upload.scss';

/**
 * UploadFile Class
 * @returns {React.ReactElement} The UploadFile
 */
const uploadRef = React.createRef();
const UploadFile = ({
  batch,
  multiple,
  autoUpload,
  files,
  setFiles,
  saveUrl = '',
  removeUrl = '',
  setAttachmentsUpdated,
}) => {
  /**
   * Add Uploaded File
   * @param {*} event
   */
  const onAdd = (event) => {
    setFiles(event.newState);
    setAttachmentsUpdated(true);
  };

  /**
   * Remove Uploaded File
   * @param {*} event
   */
  const onRemove = (event) => {
    setFiles(event.newState);
    setAttachmentsUpdated(true);
  };

  /**
   * upload Props
   */
  const uploadProps = {
    onAdd,
    onRemove,
    batch,
    multiple,
    files,
    saveUrl,
    removeUrl,
    autoUpload,
  };

  return (
    <div>
      <Upload ref={uploadRef} {...uploadProps} />
      <div>
        <div
          style={{
            marginTop: 20,
          }}
        />
      </div>
    </div>
  );
};

export default UploadFile;
