import React, { Children } from 'react';
import { CardBody, Card } from '@progress/kendo-react-layout';
import '../../../../assets/scss/common/Slider.scss';
import SliderHeader from './SliderHeader';
import SliderFooter from './SliderFooter';

const SliderCard = ({
  title,
  handleSlide,
  className,
  children,
  countDisplay,
}) => {
  let body;
  let footer;

  Children.forEach(children, (child) => {
    if (child.type === FormFooter) {
      footer = child;
      return footer;
    }

    if (child.type === FormBody) {
      body = child;
      return body;
    }
    return '';
  });

  return (
    <Card className={className}>
      <div className="slider-card">
        <SliderHeader
          title={title}
          handleSlide={handleSlide}
          countDisplay={countDisplay}
        />
        {body}
      </div>
      {footer}
    </Card>
  );
};
const FormFooter = ({ children }) => <SliderFooter>{children}</SliderFooter>;
const FormBody = ({ children }) => <CardBody>{children}</CardBody>;
SliderCard.Footer = FormFooter;
SliderCard.Body = FormBody;
export default SliderCard;
