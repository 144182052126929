import * as React from 'react';
import { MultiSelect } from '@progress/kendo-react-dropdowns';
import { Button } from '@progress/kendo-react-buttons';
import { Checkbox } from '@progress/kendo-react-inputs';
import { orderBy, filterBy } from '@progress/kendo-data-query';
import './CheckBoxListSelectFilter.scss';

const pageSize = 50; // Number of items per page

/**
 * CheckBoxListSelectFilter Filter Cell Class
 * @param {*} props
 * @returns {React.ReactElement} The DropdownFilterCell
 */
export const CheckBoxListSelectFilter = (props) => {
  const [inputValue, setInputValue] = React.useState(props?.value ?? []);
  const [data, setData] = React.useState(
    props?.enableVirtualization ? props.data.slice(0, pageSize) : props.data
  );
  const [isChecked, setIsChecked] = React.useState(false);
  const [dropdownOpen, setDropdownOpen] = React.useState(false);
  const [displayText, setDisplayText] = React.useState('');
  const [filteredData, setFilteredData] = React.useState(props.data);
  const [total, setTotal] = React.useState(props.data.length);
  const [skip, setSkip] = React.useState(0);
  const [filterValue, setFilterValue] = React.useState('');

  const field = props?.field;

  React.useEffect(() => {
    if (props?.value?.length > 0) {
      setInputValue(props?.value);
      updateDisplayText(props?.value);
    } else {
      setInputValue([]);
      setDisplayText('');
    }
  }, [props.value]);

  React.useEffect(() => {
    reorderData({ dataset: props.data, values: props?.value });
  }, [props.data]);

  React.useEffect(() => {
    setIsChecked(containsAllItems());
  }, [inputValue, data]);

  React.useEffect(() => {
    if (!dropdownOpen) updateDisplayText(inputValue);
  }, [inputValue, dropdownOpen]);

  // Function to check if array1 contains all objects from array2 based on the 'id' property
  const containsAllItems = () => {
    if (Array.isArray(inputValue) && Array.isArray(props?.data)) {
      // Create a set of selected item values from inputValue
      const inputSet = new Set(inputValue.map((item) => item.value));

      // Check if filtered data is present or fallback to original data if not filtered
      const dataToCheck = filteredData?.length ? filteredData : props.data;

      // Filter out any 'select-all' option from data to check against inputSet
      const itemsToCheck = dataToCheck.filter(
        (item) => item.value !== 'select-all'
      );

      // Return true if all items in the dataToCheck array are in the inputSet
      return itemsToCheck.every((item) => inputSet.has(item.value));
    }
    return false;
  };

  /**
   * Update Display text for the checkbox filter
   * @param {*} values
   */
  const updateDisplayText = (values) => {
    if (Array.isArray(values)) {
      const labels = values
        ?.filter((item) => item.value !== 'select-all')
        ?.map((item) => item.label);
      setDisplayText(
        labels.length > 1 && !dropdownOpen
          ? `Multiple ${
              Array.isArray(values) && values?.length > 1
                ? `(${values?.length})`
                : ``
            }`
          : props?.enableVirtualization && values?.length > 1
            ? `Multiple ${
                Array.isArray(values) && values?.length > 1
                  ? `(${values?.length})`
                  : ``
              }`
            : labels.join(', ')
      );
    } else {
      setDisplayText('');
    }
  };

  const reorderData = ({ dataset, values }) => {
    // Define the "Select All" item
    const selectAllItem = { label: 'Select All', value: 'select-all' };

    // Filter out the selected items that are part of the dataset
    const selectedItems =
      values?.length > 0
        ? orderBy(
            values?.filter(
              (item) =>
                item.value !== 'select-all' &&
                dataset.some((d) => d.value === item.value)
            ),
            [{ dir: 'asc', field: 'label' }]
          )
        : [];

    // Filter the dataset to exclude already selected items and the "Select All" item
    const unselectedItems = orderBy(
      dataset?.filter(
        (item) =>
          !selectedItems.some((selected) => selected.value === item.value) &&
          item.value !== 'select-all'
      ) ?? [],
      [{ dir: 'asc', field: 'label' }]
    );

    // Set data with "Select All" item followed by selected and unselected items
    const reorderedData = [selectAllItem, ...selectedItems, ...unselectedItems];
    setFilteredData(reorderedData);
    setData(
      props.enableVirtualization
        ? reorderedData?.slice(0, pageSize)
        : reorderedData
    );
    setTotal(reorderedData.length);
  };

  const hasValue = (value) => Boolean(value?.length > 0);
  const rowLayout = `${props.rowSizes} lb-list-item`;

  /**
   * Checkbox On change Event
   * @param {*} event
   */
  const onChange = (event) => {
    if (
      event?.nativeEvent?.target?.classList?.contains('k-checkbox') ||
      event?.nativeEvent?.target?.classList?.length === 0
    ) {
      const isValueExist = hasValue(event.target.value ?? []);
      const updatedValues = isValueExist
        ? (event?.target?.value?.filter(
            (item) => item.value !== 'select-all'
          ) ?? [])
        : [];
      setInputValue(updatedValues);
      updateDisplayText(updatedValues);
      if (
        !dropdownOpen ||
        event?.nativeEvent?.target?.classList?.length === 0
      ) {
        props.onChange({
          text: hasValue(updatedValues) ? updatedValues : null,
          value: hasValue(updatedValues) ? updatedValues : null,
          operator: hasValue(updatedValues)
            ? (props?.defaultOperator ?? 'eq')
            : '',
          syntheticEvent: event.syntheticEvent,
          field: props.field ?? '',
          formRenderProps: props.formRenderProps ?? '',
        });
      }
    }
  };

  /**
   *  Clear selected filter
   * @param {*} event
   */
  const onClearButtonClick = (event) => {
    event.preventDefault();
    setInputValue([]);
    setDisplayText('');
    props.onChange({
      value: null,
      operator: '',
      syntheticEvent: event,
    });
    setIsChecked(false);
  };

  const pageChange = (event) => {
    const newSkip = event.page.skip;
    setSkip(newSkip);
    setData(filteredData.slice(newSkip, newSkip + pageSize));
  };
  /**
   * Update toggle select All
   * @param {*} event
   */

  const toggleSelectAll = () => {
    if (isChecked) {
      // Unselect all items that are in the filtered dataset and 'select-all' items
      const updatedInputValue = inputValue.filter(
        (item) =>
          !filteredData.some(
            (filteredItem) => filteredItem.value === item.value
          ) && item.value !== 'select-all'
      );
      setInputValue(updatedInputValue);
      updateDisplayText(updatedInputValue);
    } else {
      // Select all filtered items, ignoring 'select-all'
      const selectAllItems = filteredData.filter(
        (item) => item.value !== 'select-all'
      );
      const newInputValue = [
        ...inputValue.filter(
          (item) =>
            !selectAllItems.some(
              (selectItem) => selectItem.value === item.value
            )
        ),
        ...selectAllItems,
      ];
      setInputValue(newInputValue);
      updateDisplayText(newInputValue);
    }
    setIsChecked(!isChecked);
  };

  /**
   *  Item renderer for the checkbox list
   * @param {*} li
   * @param {*} props
   * @returns
   */
  const itemRender = (li, props) => {
    const { dataItem } = props;
    const itemIsChecked =
      Array.isArray(inputValue) &&
      inputValue?.some((item) => item.value === props.dataItem.value);
    const itemChildren = (
      <>
        <Checkbox
          checked={dataItem.value === 'select-all' ? isChecked : itemIsChecked}
          onChange={() => {
            dataItem.value === 'select-all'
              ? toggleSelectAll()
              : handleCheckboxChange(props.dataItem);
          }}
        />
        {field?.toLowerCase() === 'status' &&
        dataItem?.value !== 'select-all' ? (
          <span
            className={rowLayout}
            style={{ display: 'flex', alignItems: 'center' }}
          >
            <span
              className={`badge ${props.dataItem?.label?.replaceAll(' ', '-').toLowerCase()}`}
              style={{ display: 'flex', alignItems: 'center' }}
            >
              <span
                className={`circle ${props.dataItem?.label?.replaceAll(' ', '-').toLowerCase()}`}
              />
              {props.dataItem?.label}
            </span>
          </span>
        ) : (
          <span className={rowLayout}>{props.dataItem.label}</span>
        )}
      </>
    );
    return React.cloneElement(li, li.props, itemChildren);
  };

  /**
   * Handle Checkbox Change Event
   * @param {*} dataItem
   */
  const handleCheckboxChange = (dataItem) => {
    const isItemChecked = inputValue?.some(
      (item) => item.value === dataItem.value
    );
    const newValue = isItemChecked
      ? inputValue?.filter((item) => item.value !== dataItem.value) // Deselect item
      : [...inputValue, dataItem]; // Select item

    setInputValue(newValue);
    updateDisplayText(newValue);
    setIsChecked(containsAllItems());
    setDropdownOpen(true);
  };

  /**
   * Handle Filter Change Event
   * @param {*} event
   */
  const filterChange = (event) => {
    let filteredValue = '';
    if (event?.nativeEvent?.target?.classList?.contains('k-input-inner'))
      filteredValue = event.filter.value;
    else filteredValue = filterValue;

    setFilterValue(filteredValue);
    const filtered = filterBy(props.data, {
      logic: 'and',
      filters: [{ field: 'label', operator: 'contains', value: filteredValue }],
    });

    setFilteredData(filtered);
    setData(
      props?.enableVirtualization ? filtered.slice(0, pageSize) : filtered
    );
    setSkip(0);
    reorderData({ dataset: filtered, values: props?.value });
  };

  const handleDropdownOpen = () => setDropdownOpen(true);

  const handleDropdownClose = () => {
    setDropdownOpen(false);
    if (
      JSON.stringify(hasValue(inputValue) ? inputValue : []) !==
      JSON.stringify(hasValue(props?.value) ? props?.value : [])
    ) {
      const isValueExist = hasValue(inputValue);
      props.onChange({
        text: isValueExist ? inputValue : null,
        value: isValueExist ? inputValue : null,
        operator: inputValue.length > 0 ? (props?.defaultOperator ?? 'eq') : '',
        syntheticEvent: { target: { value: inputValue } },
        field: props.field ?? '',
        formRenderProps: props.formRenderProps ?? '',
      });
    }
    setFilterValue('');
    setData(
      props?.enableVirtualization ? props.data.slice(0, pageSize) : props.data
    );
  };

  return (
    <div className="k-filtercell">
      <MultiSelect
        data={
          data?.filter(
            (f, index, self) =>
              index ===
              self.findIndex((t) => t.label === f.label && t.value === f.value)
          ) ?? []
        }
        disabled={props?.isDisabled ?? false}
        readOnly={props?.isDisabled ?? false}
        onChange={onChange}
        textField="label"
        dataItemKey="value"
        filterable={true}
        itemRender={itemRender}
        allowCustom={false}
        required={props?.required ?? false}
        filter={filterValue}
        onFilterChange={filterChange}
        virtual={
          props?.enableVirtualization ? { pageSize, skip, total } : undefined
        }
        onPageChange={props?.enableVirtualization ? pageChange : undefined}
        popupSettings={{ appendTo: document.body, height: '200px' }}
        autoClose={false}
        onOpen={handleDropdownOpen}
        onClose={handleDropdownClose}
        // Show displayText when dropdown is open
        value={
          dropdownOpen && !props?.enableVirtualization
            ? inputValue // Use inputValue when the dropdown is open
            : displayText && displayText.length > 0
              ? [{ label: displayText, value: '' }] // Ensure this is an array
              : [] // Pass an empty array if no displayText
        }
      />
      {!(props?.hideClear ?? false) && (
        <Button
          title="Clear"
          disabled={!hasValue(inputValue)}
          onClick={onClearButtonClick}
          icon="filter-clear"
        />
      )}
    </div>
  );
};

export default CheckBoxListSelectFilter;
