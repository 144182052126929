import React from 'react';
import { Button } from '@progress/kendo-react-buttons';
import { Form, Field, FieldWrapper } from '@progress/kendo-react-form';
import { Input, NumericTextBox } from '@progress/kendo-react-inputs';
import '../../../../../assets/scss/common/Slider.scss';
import './Filter.scss';
import { Label } from '@progress/kendo-react-labels';
import { DropDownList } from '@progress/kendo-react-dropdowns';
import { DatePicker } from '@progress/kendo-react-dateinputs';
import { orderBy } from '@progress/kendo-data-query';
import SliderCard from '../../Slider/SliderCard';
import Loader from '../../Loader/Loader';
import NamedFilter from '../../Filter/NamedFilter';
import { FilterOperators } from '../../../../../constants/applicationConstants';
import { searchDatafromDB } from '../Service/namedFiltersService';
import {
  TOGGLE_FILTER_DATATABLE_SLIDER,
  TOGGLE_CANCEL_DATATABLE_SLIDER,
} from '../../../../../constants/actionTypes';
import Dialogue from '../../Dialogue/Dialogue';
import DialogueMessage from '../../Dialogue/DialogueMessage';
import { ensureDate } from '../../../../../Utils/Common/utils';

/**
 * Input component of field to set default value for mobile view filters
 * FormInput component that renders a form input field
 * @param {object} fieldRenderProps - the properties for the field input component
 * @returns {JSX.Element} - the FormInput component
 */
const FormInput = (fieldRenderProps) => {
  const {
    validationMessage,
    touched,
    label,
    id,
    valid,
    disabled,
    hint,
    type,
    optional,
    max,
    data,
    value,
    ...others
  } = fieldRenderProps;

  return (
    <FieldWrapper>
      {label && (
        <Label
          editorId={id}
          editorValid={valid}
          editorDisabled={disabled}
          optional={optional}
        >
          {label}
        </Label>
      )}
      <div className={'k-form-field-wrap'}>
        <Input valid={valid} type={type} id={id} {...others} value={data} />
      </div>
    </FieldWrapper>
  );
};

/**
 * Handle Filter For Mobile Cards
 * Renders a filter component for a datatable
 * @param {Object} props - The props object for the component
 * @param {string} props.title - The title of the filter
 * @param {Function} props.handleSlide - The function to handle slider events
 * @param {Object} props.filterName - The filter name object
 * @param {string} props.filterName.FilterName - The name of the filter
 * @param {Array} props.filterName.Filters - The filters to apply
 * @param {Function} props.setFilterName - The function to set the filter name
 * @param {Function} props.setNamedFilter - The function to set a named filter
 * @param {string} props.dataTableName - The name of the data table
 * @param {Function} props.saveFilters - The function to save filters
 * @param {string} props.actionType - The type of action to perform
 * @param {Function} props.setActionType - The function to set the action type
 * @param {Function} props.applyFilter - The function to apply filters
 * @param {Function} props.deleteFilter - The function to delete a filter
 * @param {string} props.moduleName - The name of the module
 * @param {Array} props.gridColumnsList - The list of columns in the grid
 * @returns {JSX.Element} The filter component
 */
function Filter(props) {
  const {
    title,
    handleSlide,
    filterName,
    setFilterName,
    setNamedFilter,
    namedFilter,
    dataTableName,
    saveFilters,
    actionType,
    setActionType,
    applyFilter,
    deleteFilter,
    moduleName,
    gridColumnsList,
    dataColumns,
    setDateRangeFilter,
    dateRangefilter,
    getMappedFilters,
    countObjectsFrequency,
    gridFilterName,
    setGridFilterName,
  } = props;
  const [tempDateRangeFilters, setTempDateRangeFilters] = React.useState(
    JSON.parse(JSON.stringify(dateRangefilter ?? [])) // Deep copy for independence
  );
  const [gridColumns, setGridColumns] = React.useState([]);
  const [selectedGridColumns, setSelectedGridColumns] = React.useState([]);
  const [selectedValue, setSelectedValue] = React.useState(null);
  const [showDialogue, setShowDialogue] = React.useState(false);

  /**
   * used to show title of  dialog box
   */
  const [dialogueTitle, setDialogueTitle] = React.useState('');
  const [actionName, setActionName] = React.useState('');

  const [filterArray, setFilterArray] = React.useState({
    logic: 'and',
    filters: [],
  });
  const [filterNameExisted, setFilterNameExisted] = React.useState(false);
  let filterDataTemp = {};
  const selected = 0;
  const [filterValue, setFilterValue] = React.useState(
    actionType === 'edit' ? props?.filterName?.FilterName : ''
  );
  let hasValue = (value) => Boolean(value?.toString().trim());
  React.useEffect(() => {
    const filter = {
      logic: 'and',
      filters: JSON.parse(JSON.stringify(props?.filterName.Filters ?? [])),
    };
    setFilterArray(filter);
    setFilterValue(props?.filterName?.FilterName);
    props.setActionType('edit');
  }, []);
  React.useEffect(() => {
    /**
     * Validate Action should not be add.
     */
    if (props.actionType !== 'add') {
      const filter = {
        logic: 'and',
        filters: JSON.parse(JSON.stringify(props.filterName?.Filters ?? [])),
      };
      setFilterArray(filter ?? {});

      /**
       * Parse the date range filter value
       * getMappedFilters used to get the data Type of filter
       */
      if (filter?.filters?.length > 0) {
        const mappedFilters = getMappedFilters({
          dataColumns,
          filters: filter?.filters,
        });
        setTempDateRangeFilters(
          mappedFilters?.filter((obj) => obj.type === 'dateRange')
        );
      }
      setFilterValue(props?.filterName?.FilterName);
    }
    if (props.filterName?.FilterName?.trim().length === 0) {
      props.setActionType('add');
    }
  }, [props.filterName]);
  /**
   * Convert filter array into Javascript Object.
   */
  props.filter?.filters?.map((obj) => {
    filterDataTemp[obj.field] = obj.value;
  });
  const filterData = filterDataTemp;

  /**
   * Set/update filter value at local filter state
   * @param {String} fieldName
   * @param {String} fieldValue
   * @param {String} operator
   * @return {void}
   */
  const UpdateFilterArray = ({ fieldName, fieldValue, operator }) => {
    // Clone the filterArray to avoid direct mutation
    let filterArrayTemp = { ...filterArray };
    let fieldOperator = operator?.value ?? operator;

    // Initialize filter with an empty array if filterArray is empty
    if (Object.entries(filterArrayTemp).length === 0) {
      filterArrayTemp = {
        logic: 'and',
        filters: [],
      };
    }

    // Separate existing dateRange filters before processing other filters
    let tempDateRangeFilter = [];
    let normalFilters = filterArrayTemp?.filters?.filter((filter) => {
      if (filter.type === 'dateRange') {
        tempDateRangeFilter.push(filter); // Store date range filters separately
        return false; // Exclude from normal filters
      }
      return true; // Keep normal filters
    });

    // Process normal filters and add/update the one that is being changed
    const index = normalFilters.findIndex(
      (filter) => filter.field === fieldName
    );

    if (Array.isArray(fieldValue) && !fieldValue[0]?.label) {
      // Process Date Range Filters if fieldValue is an array (e.g., date range picker)
      const filterWithDataType = getMappedFilters({
        dataColumns,
        filters: fieldValue,
      });

      // Extract the date range filters from the mapped filters
      const dateRangeFilters = filterWithDataType.filter(
        (tempObj) => tempObj.type === 'dateRange'
      );

      // Update or add the date range filters (prevent duplication)
      dateRangeFilters.forEach((newDateRangeFilter) => {
        // Check if this date range filter already exists
        const existingDateRangeIndex = tempDateRangeFilter.findIndex(
          (existingFilter) =>
            existingFilter.field === newDateRangeFilter.field &&
            existingFilter.operator === newDateRangeFilter.operator
        );
        if (existingDateRangeIndex === -1) {
          // If not found, add the new date range filter
          tempDateRangeFilter.push(newDateRangeFilter);
        } else {
          // Otherwise, update the existing date range filter
          tempDateRangeFilter[existingDateRangeIndex] = newDateRangeFilter;
        }
      });
    } else {
      // Handle non-array values (e.g., text, number, etc.)
      if (!operator) {
        fieldOperator =
          getValue(
            fieldName,
            dataColumns?.find((f) => f.field === fieldName)?.type
          ) ?? 'contains';
      }

      // Update or add the filter in normal filters
      if (index === -1) {
        normalFilters.push({
          field: fieldName,
          operator: fieldOperator,
          value: fieldValue,
        });
      } else {
        normalFilters[index] = {
          ...normalFilters[index],
          value: fieldValue,
          operator: fieldOperator,
        };
      }
    }

    // Map normal filters with their types using getMappedFilters (preserving date range filters separately)
    const normalFilterWithType = getMappedFilters({
      dataColumns,
      filters: normalFilters,
    });

    // Combine normal filters and date range filters
    const finalFilters = [...normalFilterWithType, ...tempDateRangeFilter];

    // Update the filterArray with merged filters
    filterArrayTemp.filters = finalFilters;

    // Set the updated filterArray
    setFilterArray({ ...filterArrayTemp });
  };
  const itemRender = (li) => {
    const itemChildren = <span>{li.props.children}</span>;
    return React.cloneElement(li, li.props, itemChildren);
  };

  /**
   * update Filter changed value
   * @param {*} e
   */
  const addFilter = (e) => {
    const fieldName = e.target.props.name;
    var index = filterArray?.filters?.findIndex(
      (filter) => filter.field === fieldName
    );
    let operator = '';
    if (filterArray?.filters) {
      operator = filterArray?.filters[index]?.operator;
    }

    const fieldValue = e.target.value;
    UpdateFilterArray({ fieldName, fieldValue, operator });
  };
  const removeFilter = (fieldName) => {
    const updatedFilters = filterArray.filters.filter(
      (filter) => filter.field !== fieldName
    );
    // check if filter is a date range filter then update range range filter too.
    setTempDateRangeFilters(
      tempDateRangeFilters?.filter((f) => f.field !== fieldName)
    );

    // Update the filter array state or prop with the removed filter
    setFilterArray({ filters: updatedFilters });
  };
  const toggleDialogue = (action, value) => {
    if (value) {
      switch (action) {
        case 'SaveFilters': {
          // Ensure there's no unnecessary block scoping
          setDialogueTitle('Confirm Filter');
          setActionType('edit');
          break; // Ensure you have a break statement here to avoid falling through
        }

        default:
          break;
      }
      setActionName(action);
    }
    setShowDialogue(value);
  };
  /**
   * Handle Dropdown Filter Change Request
   * @param {String} value
   * @param {String} operator
   * @param {String} field
   * @param {any} formRenderProps
   * @return {void}
   */
  const onOperatorChange = ({ operator, field, type, formRenderProps }) => {
    const value = formRenderProps.valueGetter(field);
    UpdateFilterArray({
      fieldName: field,
      fieldValue: value,
      operator: operator ?? getValue(fieldName, type) ?? 'contains',
    });
    if (formRenderProps) {
      formRenderProps.onChange(field, {
        value,
      });
    }
  };

  /**
   * Returns an array of operators based on a given filter type.
   * @param {string} filterType - The filter type to get operators for.
   * @returns {Array} An array of operators for the given filter type.
   */
  const getFilterOperators = (filterType) => {
    let operators = FilterOperators[filterType];
    return operators
      ? Array.from(
          new Set(
            operators?.map((item) => ({
              label: item?.displayText,
              value: item?.operator,
            }))
          )
        )
      : [];
  };

  /**
   * Returns the value of a given field name from the filter object.
   * @param {string} fieldName - The name of the field to get the value for.
   * @returns {string} The value of the given field name, or 'contains' if it is not found.
   */
  const getValue = (fieldName, fieldType) => {
    switch (fieldType) {
      case 'date':
      case 'dateRange':
      case 'numeric':
      case 'boolean':
        return (
          props.filter?.filters?.filter((f) => f.field === fieldName)[0]
            ?.operator ?? 'eq'
        );
      default:
        return (
          props.filter?.filters?.filter((f) => f.field === fieldName)[0]
            ?.operator ?? 'contains'
        );
    }
  };
  /**
   * Returns the appropriate component for a given field type.
   * @param {string} fieldType - The type of the field to get the component for.
   * @returns {function} The component function for the given field type.
   */
  const getComponent = (fieldType) => {
    switch (fieldType) {
      case 'date':
        return DatePicker;
      case 'numeric':
        return (props) => <NumericTextBox {...props} min={0} />;
      default:
        return FormInput;
    }
  };

  /**
   * validate value in filters are updated or not
   * @returns {Boolean}
   */
  const isFilterUpdated = () =>
    JSON.stringify(filterArray?.filters) !==
      JSON.stringify(props.filterName?.Filters) ||
    getFilerName() !== props.filterName?.FilterName;

  /**
   * Used to get the updated filter name
   * @returns {String} FilterName
   */
  const getFilerName = () => {
    let saveFilterName = '';
    if (filterValue?.length > 0) {
      saveFilterName = filterValue.trim();
    } else {
      saveFilterName = filterName?.FilterName?.trim();
    }
    return saveFilterName;
  };
  /**
   * Used to get the updated action Type
   * @returns {String} FilterName
   */
  const getActionType = () => {
    if (filterName?.FilterName?.trim()?.length > 0) {
      return actionType?.length > 0 ? actionType : 'edit';
    }
    return 'add';
  };
  /**
   * Apply Filter to Grid by setting filter value.\Maintenance Mobile\_git\Maintenance Mobile PWA\commits
   * @param {Object} e
   */
  const applyFilters = (isApply) => {
    const FilterName = getFilerName();
    searchDatafromDB(moduleName, dataTableName, {
      FilterName,
    }).then((data) => {
      const filterActionType = getActionType();
      let isNameExisted = false;
      if (data && filterActionType === 'add') {
        isNameExisted = true;
        setFilterNameExisted(true);
      }
      if (!isNameExisted) {
        saveFilters({
          filterTitle: getFilerName(),
          filters: filterArray?.filters,
          action: filterActionType,
          isFilterUpdated: isFilterUpdated(),
          setGridDateRangeFilter: setDateRangeFilter,
          isApply,
          moduleName,
          dataTableName,
          getMappedFilters,
          countObjectsFrequency,
          dataColumns,
        });
      }
      if (isApply && !isNameExisted) {
        setActionType('');
        handleSlide({ action: TOGGLE_FILTER_DATATABLE_SLIDER });
      }
    });
  };

  /**
   * reset Default Filters
   * @param {*} formRenderProps
   */
  const resetDefault = () => {
    setFilterArray({});
    setTempDateRangeFilters([]);
  };

  React.useEffect(() => {
    // Get Grid Columns List
    (async () => {
      const data = await gridColumnsList({
        dateRangefilter: tempDateRangeFilters,
        setDateRangeFilter: setTempDateRangeFilters,
        tab: dataTableName,
      });
      return data;
    })().then((data) => {
      setGridColumns(data);
      // Remove the item from gridColumns
      updateColumnsBasedOnFilters(data);
    });
    // Set Filters Default Value
  }, [tempDateRangeFilters]);

  /**
   * update Columns Based On Filters
   * @param {*} data
   */
  const updateColumnsBasedOnFilters = (data, filterList) => {
    const columnsWithFilters =
      data?.filter((column) => {
        const filter = getFilterFieldValue(column?.field, filterList);
        return filter !== ''; // Check if filter is applied
      }) ?? [];

    // Ensure no duplicates in selectedGridColumns
    setSelectedGridColumns(columnsWithFilters);

    // Update gridColumns to remove columns that are now in selectedGridColumns
    setGridColumns(data.filter((col) => !columnsWithFilters?.includes(col)));
  };

  React.useEffect(() => {
    if (!props?.filterName?.FilterName?.length) {
      (async () => {
        const data = await props.getFilter({
          dataTable: props?.dataTableName,
          dataColumns,
          module: props?.moduleName,
          reset: props.selected,
        });
        return data;
      })().then((data) => {
        setFilterArray(data);
        (async () => {
          const result = await gridColumnsList({
            dateRangefilter: tempDateRangeFilters,
            setDateRangeFilter: setTempDateRangeFilters,
            tab: dataTableName,
          });
          return result;
        })().then((result) => {
          // Remove the item from gridColumns
          updateColumnsBasedOnFilters(result, data);
        });
      });
    }
  }, []);
  /**
   * Handle Dropdown Filter Change Request
   * @param {String} value
   * @param {String} operator
   * @param {String} field
   * @param {any} formRenderProps
   * @return {void}
   */
  const onChange = ({ value, operator, field, formRenderProps }) => {
    if (formRenderProps) {
      formRenderProps.onChange(field, {
        value,
      });
    }
    UpdateFilterArray({ fieldName: field, fieldValue: value, operator });
  };

  /**
   * Clear Filters
   * @param {*} event
   */
  const onClearButtonClick = (formRenderProps, event, field) => {
    event.preventDefault();
    var index = filterArray?.filters?.findIndex(
      (filter) => filter.field === field
    );
    filterArray?.filters?.pop(filterArray?.filters[index]);
    setTempDateRangeFilters(
      tempDateRangeFilters?.filter((f) => f.field !== fieldName)
    );
    setFilterArray({ ...filterArray });
    if (formRenderProps) {
      formRenderProps.onChange(field, {
        value: '',
      });
    }
  };

  /**
   * return filter value for specfied field
   * @param {String} field
   * @return {String} field value
   */
  const getFilterFieldValue = (field, filterList = []) => {
    let fieldValue = '';

    (filterArray.filters?.length > 0
      ? filterArray.filters
      : filterList?.filters
    )?.map((filterObj) => {
      // match filter value with field
      if (filterObj?.field?.toLowerCase() === field.toLowerCase()) {
        fieldValue = filterObj?.value;
      }
    });
    return fieldValue;
  };

  /**
   * return Operate value for specfied field
   * @param {String} field
   * @return {String} field Operator
   */
  const getFilterOperatorValue = (field, filterType) => {
    let fieldOpr = '';
    let operators = FilterOperators[filterType];
    filterArray.filters?.map((filterObj) => {
      // match filter value with field and get operator of that
      if (filterObj?.field?.toLowerCase() === field.toLowerCase()) {
        fieldOpr = filterObj?.operator;
      }
    });
    //
    if (fieldOpr?.length === 0) {
      fieldOpr = getValue(field);
    }

    const filterOperator = operators?.find((f) => f.operator === fieldOpr);
    return {
      value: filterOperator?.operator ?? fieldOpr,
      label: filterOperator?.displayText ?? fieldOpr,
    };
  };
  React.useEffect(() => {
    setFilterNameExisted(false);
  }, [actionType]);
  return (
    <Form
      className="filter-wrapper"
      initialValues={filterData}
      render={(formRenderProps) => (
        <>
          <SliderCard
            title={title}
            handleSlide={handleSlide}
            className={'default-slider-wrapper'}
          >
            <SliderCard.Body>
              <div className="named-filter-fieldset">
                <React.Suspense fallback={<Loader />}>
                  <NamedFilter
                    setFilter={props.setFilter}
                    setSort={props.setSort}
                    filterName={filterName}
                    gridFilterName={gridFilterName}
                    setFilterName={setFilterName}
                    setNamedFilter={setNamedFilter}
                    namedFilter={namedFilter}
                    dataTableName={dataTableName}
                    handleSlide={handleSlide}
                    actionType={actionType}
                    setActionType={setActionType}
                    filter={props.filter}
                    filterNameExisted={filterNameExisted}
                    setFilterNameExisted={setFilterNameExisted}
                    selectedFilter={
                      actionType === 'edit' ? filterName?.FilterName : ''
                    }
                    filterValue={filterValue}
                    setFilterValue={setFilterValue}
                    formRenderProps={formRenderProps}
                    applyFilter={applyFilter}
                    deleteFilter={deleteFilter}
                    filterChange={props.filterChange}
                    setFilterArray={setFilterArray}
                    resetDefault={resetDefault}
                    setGridFilterName={setGridFilterName}
                  />
                </React.Suspense>
              </div>
              <fieldset className="k-form-fieldset filter-slider-wrapper">
                <div className="row">
                  <div className="toolbar-grid-wrapper">
                    <div className="filter">
                      <DropDownList
                        style={{ width: '50%' }}
                        data={orderBy(
                          (gridColumns
                            ? Array.from(
                                new Set(
                                  gridColumns
                                    ?.filter((item) => !item.excludeFromFilter)
                                    ?.map((item) => ({
                                      label: item.title,
                                      value: item.field,
                                    }))
                                )
                              )
                            : []
                          )?.filter((f) => f.label && f.value),
                          [{ dir: 'asc', field: 'value' }]
                        )}
                        itemRender={itemRender}
                        textField="label"
                        value={selectedValue}
                        dataItemKey="value"
                        name="filterddl"
                        onChange={(e) => {
                          const { value } = e.target;
                          if (value) {
                            const selectedItem = gridColumns?.find(
                              (item) => item.field === value?.value
                            );

                            if (selectedItem) {
                              // Add the item to selectedGridColumns
                              setSelectedGridColumns((prevState) => [
                                ...prevState,
                                selectedItem,
                              ]);

                              // Remove the item from gridColumns
                              setGridColumns((prevState) =>
                                prevState.filter(
                                  (item) => item.field !== selectedItem.field
                                )
                              );
                            }
                          }
                        }}
                        label={'Select Field'}
                      />
                    </div>
                  </div>
                </div>
                <hr style={{ margin: '32px -30px' }} />

                {selectedGridColumns?.map((column, i) => (
                  <div
                    className="row"
                    key={i}
                    style={{ marginTop: i === 0 ? '-27px' : 'auto' }}
                  >
                    <span className="k-floating-label-container k-label">
                      <Label className="k-label">{column.title}</Label>
                    </span>
                    <div
                      className={`${
                        typeof column?.filterCell === 'undefined'
                          ? 'k-filtercell'
                          : 'filtercell'
                      }`}
                    >
                      <div className="filter">
                        {typeof column?.filterCell === 'undefined' ? (
                          <Field
                            name={column.field}
                            component={getComponent(column?.type)}
                            data={getFilterFieldValue(column.field)}
                            className="search-field"
                            onChange={(e) => addFilter(e, formRenderProps)}
                          />
                        ) : (
                          <>
                            {column?.filterCell({
                              onChange,
                              field: column.field,
                              formRenderProps,
                              value: getFilterFieldValue(column.field),
                              filterType: 'mobile',
                              dateRangefilter: tempDateRangeFilters,
                            })}
                          </>
                        )}
                      </div>
                      {typeof column?.filterCell === 'undefined' && (
                        <div className="operator">
                          <DropDownList
                            name={`operator`}
                            data={getFilterOperators(column?.type ?? 'text')}
                            onChange={(e) =>
                              onOperatorChange({
                                operator: e.target.value,
                                field: column.field,
                                formRenderProps,
                              })
                            }
                            field={column.field}
                            textField="label"
                            dataItemKey="value"
                            itemRender={itemRender}
                            defaultValue={getFilterOperatorValue(
                              column.field,
                              column?.type ?? 'text'
                            )}
                          />
                          <Button
                            title="Clear"
                            key={column.field}
                            disabled={
                              !hasValue(
                                formRenderProps?.valueGetter(column.field)
                              )
                            }
                            onClick={(e) =>
                              onClearButtonClick(
                                formRenderProps,
                                e,
                                column.field
                              )
                            }
                            icon="filter-clear"
                          />
                        </div>
                      )}
                      <Button
                        icon={'delete'}
                        fillMode="outline"
                        themeColor="primary"
                        className="action-button delete-filter"
                        onClick={() => {
                          if (column?.field) {
                            const selectedItem = selectedGridColumns?.find(
                              (item) => item.field === column.field
                            );

                            if (selectedItem) {
                              // Add the item back to gridColumns
                              setGridColumns((prevState) => [
                                ...prevState,
                                selectedItem,
                              ]);

                              // Remove the item from selectedGridColumns
                              setSelectedGridColumns((prevState) =>
                                prevState.filter(
                                  (item) => item.field !== selectedItem.field
                                )
                              );
                              removeFilter(column.field);
                            }
                          }
                        }}
                      />
                    </div>
                  </div>
                ))}
              </fieldset>
            </SliderCard.Body>
            <SliderCard.Footer>
              {selected === 0 ? (
                <>
                  <Button
                    icon={'cancel'}
                    onClick={() =>
                      handleSlide({ action: TOGGLE_CANCEL_DATATABLE_SLIDER })
                    }
                    type="button"
                  >
                    Cancel
                  </Button>
                  <Button
                    icon={'reset'}
                    themeColor={'primary'}
                    onClick={(e) => resetDefault()}
                    className="action-button"
                    fillMode="outline"
                  >
                    Reset
                  </Button>
                  <Button
                    icon={'save'}
                    themeColor={'primary'}
                    className="action-button"
                    fillMode="outline"
                    onClick={() => {
                      toggleDialogue('SaveFilters', true);
                    }}
                    disabled={!(filterArray?.filters?.length > 0)}
                  >
                    Save & Apply
                  </Button>
                  <Button
                    icon={'filter'}
                    themeColor={'primary'}
                    className="action-button"
                    fillMode="solid"
                    onClick={() => applyFilters(true)}
                    disabled={!(filterArray?.filters?.length > 0)}
                  >
                    Apply
                  </Button>
                </>
              ) : (
                <>
                  <Button
                    icon={'cancel'}
                    onClick={() =>
                      handleSlide({ action: TOGGLE_CANCEL_DATATABLE_SLIDER })
                    }
                    type="button"
                  >
                    Cancel
                  </Button>
                  <Button
                    icon={'save'}
                    themeColor={'primary'}
                    className="action-button"
                    disabled={
                      !(
                        props?.filter?.filters?.length > 0 &&
                        formRenderProps.allowSubmit
                      )
                    }
                  >
                    Save
                  </Button>
                </>
              )}
            </SliderCard.Footer>
          </SliderCard>
          <>
            {showDialogue && (
              <Dialogue
                title={dialogueTitle}
                message={
                  <DialogueMessage
                    FormInput={FormInput}
                    filterValue={filterValue}
                    setFilterNameExisted={setFilterNameExisted}
                    setFilterValue={setFilterValue}
                    filterNameExisted={filterNameExisted}
                  />
                }
                disabled={!filterValue?.length > 0}
                onClick={() => {
                  if (actionName === 'SaveFilters') {
                    setActionType('edit');
                    applyFilters(false);
                    handleSlide({ action: TOGGLE_FILTER_DATATABLE_SLIDER });
                  }
                  toggleDialogue(actionName, false);
                }}
                onClose={() => {
                  toggleDialogue(actionName, false);
                }}
                onCancel={() => {
                  toggleDialogue(actionName, false);
                }}
              />
            )}
          </>
        </>
      )}
    />
  );
}
export default Filter;
