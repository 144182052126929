import React from 'react';
import './DataCard.scss';
import '../../../../assets/scss/common/Cards.scss';

/**
 * Swipe Card Component
 * @param {Object} cardSelected
 * @param {function} handleTouchStart
 * @param {function} handleTouchMove
 * @param {function} handleTouchEnd
 * @param {function} handleMouseDown
 * @param {function} handleMouseMove
 * @param {function} handleMouseUp
 * @param {function} handleMouseLeave
 * @param {String} dataId
 * @param {Component} children
 */
const DataCard = ({
  cardSelected,
  handleTouchStart,
  handleTouchMove,
  handleTouchEnd,
  handleMouseDown,
  handleMouseMove,
  handleMouseUp,
  handleMouseLeave,
  dataId,
  children,
}) => (
  <div
    className={`list-card grid-wrapper ${
      window.matchMedia('(orientation: landscape)').matches
        ? 'list-card-grow'
        : 'list-card-shrink'
    } ${
      cardSelected?.findIndex((card) => card.id === dataId.toString()) !== -1
        ? 'active'
        : 'inactive'
    }`}
  >
    <div
      className={`card swipeItem ${
        cardSelected?.findIndex((card) => card.id === dataId.toString()) !== -1
          ? 'active'
          : 'inactive'
      }`}
      onTouchStart={(touchStartEvent) => handleTouchStart(touchStartEvent)}
      onTouchMove={(touchMoveEvent) => handleTouchMove(touchMoveEvent)}
      onTouchEnd={() => handleTouchEnd()}
      // The following event handlers are for mouse compatibility:
      onMouseDown={(mouseDownEvent) => handleMouseDown(mouseDownEvent)}
      onMouseMove={(mouseMoveEvent) => handleMouseMove(mouseMoveEvent)}
      onMouseUp={() => handleMouseUp()}
      onMouseLeave={() => handleMouseLeave()}
      data-id={dataId}
    >
      {children}
    </div>
  </div>
);

export default DataCard;
