import React from 'react';
import { DEVICE_TYPES } from '../../../../constants/eventDataTypes';
import gridReducer from '../DataGrid/gridReducer';
import { GlobalContext } from '../../Context/GlobalContextState';
import { resizeWorker } from '../../../../Utils/Layouts/resizeWorker';

import {
  errorGenerator,
  messageGenerator,
} from '../../../../Utils/Notifications/notificationUtils';
import {
  getDataFromStorage,
  removeDataFromStorage,
} from '../../../../Utils/Storage/handleCacheConfig';

/**
 * Handle User Grid, User menu and UserFilter
 * @param {*} res error response
 * @returns {Error} Generate Error
 */
const handleError = (res) => {
  const { message } = res;
  errorGenerator({ message });
};

/**
 * Used to get the old Value of state level variable
 * @param {*} value
 * @returns {current} returns prev value
 */
const usePrev = (value) => {
  const ref = React.useRef();

  React.useEffect(() => {
    ref.current = value;
  }, [value]);

  return ref.current;
};
/**
 * Handle Grid and menu
 * tabName variable will be the module itself incase of single tab/no tab pages. For multi-tab page(s), it will be the name of the tab
 * @param {*} props
 * @returns {HOCComponent} returns higher order component
 */
const GridListHOC =
  ({ GRID_ID }) =>
  (Component) =>
  (props) => {
    const { dimensions } = React.useContext(GlobalContext);
    const {
      originalList,
      dataList,
      setDataList,
      selectedState,
      tabName,
      rowLayoutConfigName,
      pageLengthConfigName,
      defaultLimit,
      defaultRowLayout,
      rowLayouts,
      pageSizes,
    } = props;
    const [loading, setIsLoading] = React.useState(false);

    /**
     * Initial Data set for paging
     */
    const initialDataState = {
      skip: 0,
      take: defaultLimit,
    };

    /**
     * initializing RowSize, inEdit, Page and selectedState Properties
     */
    const [state, dispatch] = React.useReducer(gridReducer, {
      rowSizes: process.env.RowLayout,
      inEdit: false,
      page: initialDataState,
      pageLength: defaultLimit,
    });

    // Grid state variable
    const [gridData, setGridData] = React.useState([]);
    const [length, setLength] = React.useState(0);

    React.useEffect(() => {
      (async () => {
        const rowSizes = await getDataFromStorage(
          rowLayoutConfigName,
          rowLayouts,
          defaultRowLayout
        );
        const pageLength = await getDataFromStorage(
          pageLengthConfigName,
          pageSizes,
          defaultLimit
        );
        state.page.take = pageLength;
        state.rowSizes = rowSizes;
        state.pageLength = pageLength;
      })();
    }, []);

    /**
     * handle lazy loading on mobile Cards
     */
    const drawerContentElements =
      document.querySelectorAll('.k-drawer-content');
    const sliderCardElements = document.querySelectorAll(
      '.slider-content-card .slider-card'
    );

    /**
     * used to handle lazy loading for card and list view
     * @param {Object} e
     */
    const handleScroll = (e) => {
      const isCard = e.target.querySelector('.list-card');
      if (isCard) {
        if (
          e.target.scrollTop + 2 >=
          e.target.scrollHeight - e.target.clientHeight
        ) {
          if (parseInt(state.page.skip) * parseInt(state.page.take) < length) {
            const page = {
              skip: parseInt(state.page.skip) + 1,
              take: parseInt(state.pageLength),
              pageLengthConfigName,
            };
            dispatch({ type: 'PAGE', payload: page });
          }
        }
      }
    };
    // Add event listener to .k-drawer-content elements
    drawerContentElements?.forEach((element) => {
      element.addEventListener('scroll', handleScroll);
    });

    // Add event listener to .slider-content-card .slider-card elements
    sliderCardElements?.forEach((element) => {
      element.addEventListener('scroll', handleScroll);
    });

    /** @method
     * Handle Grid View For Mobile
     * @returns {Boolean} returns if grid view template is enabled or not
     */
    const getGridViewTemplate = (device) => {
      if (
        device.type === DEVICE_TYPES.TABLET ||
        device.type === DEVICE_TYPES.PHONE
      ) {
        setFilterExpanded(false);
        return false;
      }
      return true;
    };

    /** @method
     * Exit Edit Mode
     */
    const exitEdit = (filteredData, inEditMode) => {
      const newData = filteredData.map((item) => ({
        ...item,
        inEdit: false,
      }));
      setGridData(newData);
      if (inEditMode) {
        dispatch({ type: 'IN_EDIT', payload: false });
      }
    };

    /** @method
     * Revert Update Changes during Inline Edit Row
     * @param {Object} dataItem
     */
    const cancel = () => {
      const items = FilterCheckedRows();
      const newData = dataList.map((data) => {
        const selectedItem = items?.find(
          (itemObj) => itemObj.id === data[GRID_ID]
        );
        if (selectedItem) {
          // get original item
          return originalList?.find((itemObj) => itemObj.id === data[GRID_ID]);
        }
        return data;
      });
      setGridData(newData);
    };

    /** decide if show grid view or card view */
    const [gridView, setGridView] = React.useState();

    /** decide if show row size section */
    const [filterExpanded, setFilterExpanded] = React.useState(false);

    /** @event
     * Handle Pagination Length Change
     * @param {Object} event
     */
    const pageListChange = (event) => {
      if (event.target.value !== 'Item per page') {
        state.pageLength = event.target.value;
        dispatch({
          type: 'PAGE',
          payload: {
            skip: 0,
            take: parseInt(event.target.value),
            moduleName: tabName,
            pageLengthConfigName,
          },
        });
      }
    };

    /**
     * reset Grid Settings
     */
    const resetSettings = () => {
      try {
        removeDataFromStorage(rowLayoutConfigName);
        removeDataFromStorage(pageLengthConfigName);
        (async () => {
          const rowSizes = await getDataFromStorage(
            rowLayoutConfigName,
            rowLayouts,
            defaultRowLayout
          );
          const pageLength = await getDataFromStorage(
            pageLengthConfigName,
            pageSizes,
            defaultLimit
          );
          state.page.take = pageLength;
          state.rowSizes = rowSizes;
          state.pageLength = pageLength;
          dispatch({
            type: 'RESET_SETTINGS',
            payload: {
              rowSizes,
              pageLength,
              page: {
                skip: 0,
                take: parseInt(pageLength),
                moduleName: tabName,
              },
            },
          });

          messageGenerator({
            title: 'Settings Reset',
            message: 'Grid Settings has been reset successfully',
            style: 'success',
          });
        })();
      } catch (error) {
        const { message } = error;
        errorGenerator({ message });
      }
    };

    /** @event
     * Handle Pagination Page Change
     * @param {Object} event
     */
    const pageChange = (event) => {
      dispatch({ type: 'PAGE', payload: event.page });
    };

    /** @method
     * Handle Add/Edit Popup slide
     */
    const handleSlide = (action) => {
      props.handleSlide(action);
    };
    /** @method
     * Handle inline Edit
     */
    const setInEdit = (isEdit) => {
      dispatch({ type: 'IN_EDIT', payload: isEdit });
    };
    /** @method
     * Discard Changes on Object
     */
    const discard = () => {
      const newData = [...dataList];
      newData.splice(0, 1);
      setGridData(newData);
    };

    const prevFilter = usePrev(props.filter?.filters);
    const prevSort = usePrev(props?.sort);

    /**
     * @event
     * Set Grid Data
     */
    React.useEffect(() => {
      setGridData(dataList);
    }, [dataList]);

    /**
     * @event
     * set Grid View
     */
    React.useEffect(() => {
      const device = resizeWorker();
      setGridView(getGridViewTemplate(device));
    }, [dimensions]);

    /**
     * A function that filters checked rows and returns an array of objects containing their ids and statuses.
     * @returns {Array<{id: string, status: Array}>} Array of objects containing the ids and Statuses of the checked rows.
     */
    const FilterCheckedRows = () => {
      let checkedRows = [];
      for (var key in selectedState) {
        if (selectedState[key]?.checked) {
          checkedRows.push({
            id: selectedState[key]?.id,
            status: selectedState[key]?.status,
          });
        }
      }

      return checkedRows;
    };

    /**
     * @event
     *Set Checked Data
     */
    React.useEffect(() => {
      const items = FilterCheckedRows();
      if (items.length > 0) {
        const selectedItems = dataList.filter((data) => {
          const selectedItem = items.find(
            (itemObj) => itemObj.id === data[GRID_ID]
          );
          if (selectedItem) {
            data.statusID = selectedItem?.status;
            return data;
          }
          return null;
        });

        props.setCheckedDataItems(selectedItems, tabName);
      } else {
        props.setCheckedDataItems([], tabName);
      }
    }, [selectedState, dataList]);

    /**
     * Call API on scroll end for both GRID and CARD
     * @param {*} event
     * @return {void}
     */
    const scrollHandler = (event) => {
      const e = event.nativeEvent;
      if (e.target.scrollHeight > e.target.clientHeight) {
        if (
          e.target.scrollTop + 2 >=
          e.target.scrollHeight - e.target.clientHeight
        ) {
          if (parseInt(state.page.skip) * parseInt(state.page.take) < length) {
            const page = {
              skip: parseInt(state.page.skip) + 1,
              take: parseInt(state.pageLength),
              pageLengthConfigName,
            };
            dispatch({ type: 'PAGE', payload: page });
          }
        }
      }
    };
    const args = {
      filterExpanded,
      state,
      handleSlide,
      gridView,
      dispatch,
      pageListChange,
      resetSettings,
      discard,
      cancel,
      exitEdit,
      setGridData,
      loading,
      pageChange,
      gridData,
      length,
      prevFilter,
      prevSort,
      setIsLoading,
      setDataList,
      setLength,
      handleError,
      scrollHandler,
      setInEdit,
      GRID_ID,
    };
    return <Component {...args} {...props} />;
  };

export default GridListHOC;
