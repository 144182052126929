import React, { Children } from 'react';

const DropDownMenu = ({
  children,
  setIsDropdownOpen,
  toggleOnBody,
  isDismissed = false,
  setIsDismissed,
}) => {
  let body;
  let bodyClassNames;
  let header;
  const dropdownMenu = React.useRef(null); // Dropdown menu Ref Object
  const [isActiveDropdown, setisActiveDropdown] = React.useState(false); // Check Dropdown menu is Active
  /**
   * Close DropDown menu when user click on body
   * @param {C} e
   */
  const closeOpenMenus = (e) => {
    if (
      dropdownMenu.current &&
      isActiveDropdown &&
      !dropdownMenu.current.contains(e.target)
    ) {
      setisActiveDropdown(false);
    }
  };

  /**
   * Toggle Dropdown Display
   */
  const toggleDropdown = () => {
    setisActiveDropdown(!isActiveDropdown);
    if (setIsDropdownOpen) {
      setIsDropdownOpen(!isActiveDropdown);
    }
  };

  React.useEffect(() => {
    document.addEventListener('mousedown', closeOpenMenus);
  }, [closeOpenMenus]);
  React.useEffect(() => {
    if (isDismissed && setIsDismissed) {
      setisActiveDropdown(false);
      setIsDismissed(false);
    }
  }, [isDismissed]);
  Children.forEach(children, (child) => {
    if (child.type === MenuHeader) {
      header = child;
      return header;
    }

    if (child.type === MenuBody) {
      body = child;
      bodyClassNames = child.props.className;
      return body;
    }
    return '';
  });

  /**
   * validate toogle on menu item is active or not
   */
  const evaluateToggle = () => {
    if (toggleOnBody) {
      toggleDropdown();
    }
  };
  return (
    <div ref={dropdownMenu} onClick={evaluateToggle}>
      <div onClick={toggleDropdown}>{header}</div>

      <div
        className={`k-appbar-dropdown ${bodyClassNames} ${
          isActiveDropdown ? 'dropdown-active' : 'dropdown-inactive'
        }`}
      >
        {body}
      </div>
    </div>
  );
};
const MenuHeader = ({ children }) => <div>{children}</div>;
const MenuBody = ({ children }) => <div>{children}</div>;
DropDownMenu.Header = MenuHeader;
DropDownMenu.Body = MenuBody;
export default DropDownMenu;
