import * as React from 'react';
import { Checkbox, Switch } from '@progress/kendo-react-inputs';
import { RenderTypes } from '../../../../constants/applicationConstants';

/**
 * Custom Checkbox Class, show toggle switch or checkbox based on configuration
 * @type {React.FC<Props>}
 * @returns {React.ReactElement} The Custom Checkbox component.
 */
export const CustomCheckbox = (props) => {
  const {
    isToggleSwitch,
    onLabel,
    offLabel,
    readOnly,
    disabled,
    label,
    name,
    onChange,
    renderTypes,
    value,
  } = props;
  return isToggleSwitch ? (
    <span
      className={`${
        renderTypes === RenderTypes.ReadOnly ? 'disabled-switch' : ''
      }`}
    >
      <Switch
        disabled={
          renderTypes === RenderTypes.ReadOnly ? true : disabled ?? false
        }
        onLabel={onLabel ?? 'Yes'}
        offLabel={offLabel ?? 'No'}
        defaultChecked={false}
        checked={value ?? false}
        onChange={onChange}
        name={name}
        label={label}
      />
    </span>
  ) : (
    <Checkbox
      defaultChecked={value ?? false}
      className={`rounded-checkbox${disabled ? ' k-disabled' : ''}`}
      value={value}
      readOnly={readOnly ?? false}
    />
  );
};

export default CustomCheckbox;
