import {
  readNotification,
  updatePanelNotification,
} from '../../Modules/Core/Components/AppNotification/NotificationHandler';
import {
  EVENTS_DATA_TYPES,
  NOTIFICATION_TYPES,
} from '../../constants/eventDataTypes';
import { buildNotification } from '../../Modules/Core/Components/AppNotification/BaseNotification';
import GenerateNotification from '../../Modules/Core/Components/AppNotification/GenerateNotification';
import { DisplayNotification } from '../../constants/applicationConstants';
/**
 * Filter the notification based on the notificationId and
 * update the notification isRead Status in local storage.
 * @param {*} notifications
 * @param {*} notificationId
 * @returns {array} filtered notifications
 */
export const closeNotificationPopup = (notifications, notificationId) => {
  let filteredNotifications = notifications.filter(
    (value) => value.notificationId !== notificationId
  );
  readNotification(notificationId);
  return filteredNotifications;
};

/**
 * Filter the notification based on the notificationId and
 * remove the notification from local storage.
 * @param {*} notifications
 * @param {*} notificationId
 * @returns {array} filtered notifications
 */
export const closeNotification = (notifications, notificationId) => {
  let filteredNotifications = notifications.filter(
    (value) => value.notificationId !== notificationId
  );
  updatePanelNotification(notificationId);
  return filteredNotifications;
};

/**
 * Used to Generate Error Messages
 * @param {String} title
 * @param {String} message
 * @param {String} type
 * @param {Number} timeout
 * @param {String} style
 * @return {void}
 */
export const errorGenerator = ({
  title,
  message,
  type,
  timeout,
  style,
  display,
}) => {
  const notificationMetaData = {
    title: title ?? 'Application Error',
    description: message,
    style: style ?? 'error',
    timeout: timeout ?? 0,
    display: display ?? DisplayNotification.SHOW,
  };
  // get notifications Array

  GenerateNotification(
    buildNotification(notificationMetaData),
    type ? type : NOTIFICATION_TYPES.APP,
    EVENTS_DATA_TYPES.APPLICATION_NOTIFICATION
  );
};
/**
 * Used to Genrate Any Type of messages
 * @param {String} title
 * @param {String} message
 * @param {String} type
 * @param {Number} timeout
 * @param {String} style
 * @return {void}
 */
export const messageGenerator = ({ title, message, type, timeout, style }) => {
  const notificationMetaData = {
    title: title ?? 'Application Info',
    description: message,
    style: style ?? 'info',
    timeout: timeout ?? 5000,
  };
  GenerateNotification(
    buildNotification(notificationMetaData),
    type ? type : NOTIFICATION_TYPES.APP,
    EVENTS_DATA_TYPES.APPLICATION_NOTIFICATION
  );
};

/**
 * Filters out duplicate objects in an array based on a specified key.
 * @param {Array} arr - The array to filter.
 * @param {string} key - The key used to identify duplicates.
 * @returns {Array} - The filtered array with duplicates removed.
 */
export const filterOutDuplicates = (arr, key) => {
  const occurrences = {};

  return arr.filter((item) => {
    if (!occurrences[item[key]]) {
      occurrences[item[key]] = 1;

      return true;
    }

    return false;
  });
};
