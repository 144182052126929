import { Button } from '@progress/kendo-react-buttons';
import * as React from 'react';
/**
 * Custom Command Cells Class, show Action Buttons for grid Operations
 * @type {React.FC<Props>}
 * @returns {React.ReactElement} The Command Cell component.
 */
export const CommandCell = (props, key) => {
  const { dataItem } = props;
  const inEdit = dataItem[props.editField];
  const isNewItem = dataItem[key] === undefined;
  return inEdit ? (
    <td className="k-command-cell">
      <button
        className="k-button k-button-md k-rounded-md k-button-solid k-button-solid-base k-grid-save-command"
        onClick={() =>
          isNewItem ? props.add(dataItem) : props.update(dataItem)
        }
      >
        {isNewItem ? 'Add' : 'Update'}
      </button>
      <button
        className="k-button k-button-md k-rounded-md k-button-solid k-button-solid-base k-grid-cancel-command"
        onClick={() =>
          isNewItem ? props.discard(dataItem) : props.cancel(dataItem)
        }
      >
        {isNewItem ? 'Discard' : 'Cancel'}
      </button>
    </td>
  ) : (
    <td className="k-command-cell">
      <Button
        icon={'edit'}
        fillMode="outline"
        themeColor="primary"
        className="action-button"
        title="Edit"
        onClick={() => props.edit(dataItem)}
      />
      <Button
        icon={'delete'}
        fillMode="outline"
        themeColor="primary"
        className="action-button"
        title="Delete"
        onClick={() =>
          confirm(`Confirm deleting: ${dataItem[key]}`) &&
          props.remove(dataItem)
        }
      />
    </td>
  );
};

export default CommandCell;
