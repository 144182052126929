import React from 'react';
import { Button, ButtonGroup } from '@progress/kendo-react-buttons';
import '../../../../assets/scss/common/Slider.scss';
import { DropDownList } from '@progress/kendo-react-dropdowns';
import Dialogue from '../Dialogue/Dialogue';
/**
 * Handle Named Filter for mobile view
 */
function NamedFilter(props) {
  const {
    filterName,
    filterChange,
    actionType,
    deleteFilter,
    setFilterName,
    setGridFilterName,
    gridFilterName,
  } = props;

  /**
   * used to show info and error message in dialog box
   */
  const [showDialogue, setShowDialogue] = React.useState(false);

  /**
   * set action add, edit and delete and perform action according to data
   */
  const [actionName, setActionName] = React.useState('');

  /**
   * used to show title of  dialog box
   */
  const [title, setTitle] = React.useState('');

  /**
   * used to show messages in dialog box
   */
  const [message, setMessage] = React.useState('');

  /**
   *  Show Confirmation Dialogue based on Action Type
   * @param {*} action
   * @param {*} value
   */
  const toggleDialogue = (action, value) => {
    if (value) {
      setTitle('Confirmation');
      switch (action) {
        case 'DeleteFilters':
          {
            const trimmedFilterName = filterName?.FilterName.slice(0, 50);
            setMessage(
              `Confirm that you want to delete ${
                trimmedFilterName.length < filterName?.FilterName?.length
                  ? `${trimmedFilterName}...`
                  : trimmedFilterName
              } filter?`
            );
          }
          break;
        default:
          break;
      }
      setActionName(action);
    }
    setShowDialogue(value);
  };

  /**
   * Used to get the value of Name Filter Dropdown
   * @returns {Object} filterName
   */
  const getNameFilterDropdownVal = () => {
    return filterName;
  };
  /** @event
   * set form initial values on tab change
   */
  React.useEffect(() => {
    props.setFilterValue(
      actionType === 'edit' ? props.filterName?.FilterName : ''
    );
  }, [props.filterName]);
  return (
    <fieldset className="k-form-fieldset filter-slider-wrapper">
      <div className={`toolbar-grid-wrapper`}>
        <div className="filter">
          <DropDownList
            data={props.namedFilter}
            textField="FilterName"
            dataItemKey="FilterName"
            name="filterddl"
            value={getNameFilterDropdownVal()}
            label="Saved Filters"
            onChange={(e) => {
              props.setActionType('edit');
              filterChange(e, 'mobile');
            }}
          />

          <div>
            <ButtonGroup>
              <Button
                icon={'cancel'}
                themeColor="primary"
                className="action-button"
                style={{
                  background:
                    !props?.filterName?.FilterName?.length > 0
                      ? 'color-mix(in srgb, var(--kendo-color-on-app-surface, #212121) 9%, transparent)'
                      : 'white',
                  color:
                    !props?.filterName?.FilterName?.length > 0
                      ? 'color-mix(in srgb, var(--kendo-color-on-app-surface, #212121) 32%, transparent)'
                      : '#3f51b5',
                }}
                disabled={!props?.filterName?.FilterName?.length > 0}
                onClick={() => {
                  props.setActionType('edit');
                  props.setFilterValue('');
                  props.setFilterName('');
                  props.setFilterArray({});
                  props.resetDefault();
                }}
              />
              <Button
                style={{
                  background:
                    !filterName?.FilterName ||
                    props?.filterName?.FilterType === 'System'
                      ? 'color-mix(in srgb, var(--kendo-color-on-app-surface, #212121) 9%, transparent)'
                      : 'white',
                  color:
                    !filterName?.FilterName ||
                    props?.filterName?.FilterType === 'System'
                      ? 'color-mix(in srgb, var(--kendo-color-on-app-surface, #212121) 32%, transparent)'
                      : '#3f51b5',
                }}
                icon={'delete'}
                themeColor="primary"
                className="action-button"
                disabled={
                  !filterName?.FilterName ||
                  props?.filterName?.FilterType === 'System'
                }
                onClick={() => {
                  toggleDialogue('DeleteFilters', true);
                }}
              />
            </ButtonGroup>
          </div>
        </div>
        <div
          className="alert alert-warning"
          role="alert"
          style={{
            margin: '0',
            padding: '9px 0 0 0',
            color: 'rgb(25 32 72)',
            background: 'none',
            border: 'none',
          }}
        >
          <span className="k-icon k-font-icon k-i-warning" /> Choose a saved
          filter from the list to edit it, or select a field to create a new
          filter.
        </div>
        {showDialogue && (
          <Dialogue
            title={title}
            message={message}
            onClick={() => {
              if (actionName === 'DeleteFilters') {
                props.setFilterValue('');
                props.setActionType('add');
                deleteFilter();
              }
              toggleDialogue(actionName, false);
            }}
            onClose={() => {
              toggleDialogue(actionName, false);
            }}
            onCancel={() => {
              toggleDialogue(actionName, false);
            }}
          />
        )}
      </div>
    </fieldset>
  );
}
export default NamedFilter;
