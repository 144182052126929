import * as React from 'react';
import { DatePicker } from '@progress/kendo-react-dateinputs';
import moment from 'moment';
import { Hint } from '@progress/kendo-react-labels';
import { formatDateTime } from '../../../../Utils/Common/utils';
/**
 * DatePickerFilter For Inline Grid Operations Cell Class
 * @param {*} props
 * @returns {React.ReactElement} The DatePickerFilter
 */
export const DatePickerFilter = (props) => {
  let value = '';
  const { dataItem, field, required = false } = props;

  if (field.includes('.')) {
    const fields = field?.split('.');
    value = dataItem[fields[0]][fields[1]];
  } else {
    value = dataItem[field];
  }
  const dataValue = value === null ? '' : value;

  const maxDate = props?.maxDate ?? new Date();
  const minDate = props?.minDate ?? new Date();

  /**
   * Handle change event on datepicker filter
   * @param {Object} e
   */
  const handleChange = (e) => {
    if (props.onChange) {
      props.onChange({
        dataIndex: 0,
        dataItem: props.dataItem,
        field: props.field,
        syntheticEvent: e.syntheticEvent,
        value:
          moment(e.target?.value)?.format('YYYY-MM-DDT00:00:00.000') ?? null,
      });
    }
  };
  return (
    <td>
      {dataItem.inEdit ? (
        <>
          <div className="k-filtercell">
            <DatePicker
              max={props.setMaxDate ? maxDate : undefined}
              min={props.setMinDate ? minDate : undefined}
              value={dataValue ? new Date(dataValue) : null}
              onChange={handleChange}
            />
          </div>
          <Hint className="k-form-error">
            {required && !dataValue && dataItem.isChange
              ? 'Please enter a value'
              : ''}
          </Hint>
        </>
      ) : (
        formatDateTime(dataValue)
      )}
    </td>
  );
};
export default DatePickerFilter;
