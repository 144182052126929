import React from 'react';
import { ErrorBoundary } from 'react-error-boundary';
import { Splitter } from '@progress/kendo-react-layout';

import LookupSidebar from '../LookupSidebar/LookupSidebar';
import ErrorFallback from '../../ErrorHandler/ErrorFallback';
import Loader from '../../Loader/Loader';

import LookupTopbar from '../LookupTopbar/LookupTopbar';

import { GlobalContext } from '../../../Context/GlobalContextState';
import { DEVICE_TYPES } from '../../../../../constants/eventDataTypes';
import './LookupContent.scss';
/**
 * Lookup main screen
 * @type {React.FC<Props>}
 * @returns {React.ReactElement} The LookupContent component.
 */
const LookupContent = ({
  dataSet,
  setDataSet,
  treeDataSet,
  treeOptionsList,
  topbarSearch,
  setTopBarSearch,
  setSelectedTree,
  selectedTree,
  setIsSearch,
  isSearch,
  setIsFilterFavourite,
  isFilterFavourite,
  setLookupCheckedItems,
  lookupCheckedItems,
  mode,
  lookupSelectionMode,
  isRecent,
  setIsRecent,
  selectLookups,
  show,
  handleLookupSlide,
  LookupDataTable,
  isSearchEnable,
  isRecentEnable,
  isFavouriteEnable,
  isTreeSidebar = true,
  searchPlaceHolder,
  isCritical,
}) => {
  const { deviceInfo } = React.useContext(GlobalContext);
  const [panes, setPanes] = React.useState([
    {
      size: '25%',
      min: '240px',
      collapsible: true,
    },
    {},
  ]);
  const onChange = (event) => {
    setPanes(event.newState);
  };
  const [selectedTreeObj, setSelectedTreeObj] = React.useState({});

  const lookupDataTable = (
    <LookupDataTable
      dataSet={dataSet}
      setDataSet={setDataSet}
      selectedTreeObj={selectedTreeObj}
      selectedTree={selectedTree}
      topbarSearch={topbarSearch}
      setTopBarSearch={setTopBarSearch}
      setIsSearch={setIsSearch}
      isSearch={isSearch}
      setIsFilterFavourite={setIsFilterFavourite}
      isFilterFavourite={isFilterFavourite}
      setLookupCheckedItems={setLookupCheckedItems}
      lookupCheckedItems={lookupCheckedItems}
      mode={mode}
      lookupSelectionMode={lookupSelectionMode}
      isRecent={isRecent}
      setIsRecent={setIsRecent}
      selectLookups={selectLookups}
      show={show}
      handleLookupSlide={handleLookupSlide}
      isCritical={isCritical}
    />
  );
  return (
    <ErrorBoundary
      FallbackComponent={ErrorFallback}
      onReset={() => {
        // reset the state of your app so the error doesn't happen again
      }}
    >
      <div className="lookup container mx-auto">
        <React.Suspense fallback={<Loader />}>
          <LookupTopbar
            topbarSearch={topbarSearch}
            setTopBarSearch={setTopBarSearch}
            setIsSearch={setIsSearch}
            isSearch={isSearch}
            setIsFilterFavourite={setIsFilterFavourite}
            isFilterFavourite={isFilterFavourite}
            isRecent={isRecent}
            setIsRecent={setIsRecent}
            isSearchEnable={isSearchEnable}
            isRecentEnable={isRecentEnable}
            isFavouriteEnable={isFavouriteEnable}
            searchPlaceHolder={searchPlaceHolder}
          />
          {!(
            deviceInfo?.type === DEVICE_TYPES.PHONE ||
            deviceInfo?.type === DEVICE_TYPES.TABLET
          ) && isTreeSidebar ? (
            <Splitter panes={panes} onChange={onChange}>
              <div className="pane-content">
                <LookupSidebar
                  dataSet={treeDataSet}
                  setSelectedTree={setSelectedTree}
                  selectedTree={selectedTree}
                  setSelectedTreeObj={setSelectedTreeObj}
                  selectedTreeObj={selectedTreeObj}
                  treeOptionsList={treeOptionsList}
                />
              </div>
              <div className="pane-content">{lookupDataTable}</div>
            </Splitter>
          ) : (
            <div>{lookupDataTable}</div>
          )}
        </React.Suspense>
      </div>
    </ErrorBoundary>
  );
};
export default LookupContent;
