// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.copied-icon .copy-icon {
  color: #489c4b !important;
}`, "",{"version":3,"sources":["webpack://./src/Modules/Core/Components/CopyTextButton/CopyTextButton.scss"],"names":[],"mappings":"AAEE;EACE,yBAAA;AADJ","sourcesContent":["@import \"./../../../../assets/scss/abstracts/variables\";\n.copied-icon {\n  .copy-icon {\n    color: $green-color !important;\n  }\n}\n"],"sourceRoot":""}]);
// Exports
export default ___CSS_LOADER_EXPORT___;
