import { useReducer } from 'react';
import reducer from './DataTableReducerHandler';
import {
  EDIT_DATA,
  UPDATE_DATA_LIST,
  GET_DATA,
  GET_DATA_LIST,
  INIT_DATA_LIST,
  CHECKED_ITEM_DATA_LIST,
  GET_DATA_BY_ID,
  SELECTED_STATE,
  SET_DATA_KEY,
} from '../../../../constants/actionTypes';
/**
 * Initial state for the DataTable reducer.
 * @typedef {Object} InitialState
 * @property {Array} dataList - The list of data for the table.
 * @property {Array} checkedDataItems - The list of selected data items.
 * @property {Object} selectedState - The state of the currently selected data item.
 * @property {string} DataIdKey - The key of the data item ID.
 * @property {number} total - The total number of data items.
 */
const initialState = {
  dataList: [],
  checkedDataItems: [],
  selectedState: {},
  DataIdKey: '',
  // total: process.env.DefaultLimit,
  total: '',
};

/**
 * DataTable Reducer to handle the Datalist and selected Data Items Values.
 * Returns the DataTable reducer and initial state.
 * @param {Object} dataSet - The data set for the table.
 * @returns {Array} An array containing the DataTable reducer and initial state.
 */
export const getReducer = ({ dataSet,total }) => {
  initialState.dataList = dataSet;
  initialState.total = total;
  return useReducer(reducer, initialState);
};

/**
 * Maps dispatch functions to props for the DataTable component.
 * @param {function} dispatch - The dispatch function for Redux.
 * @returns {Object} An object containing functions to dispatch actions.
 */
export const mapDispatch = (dispatch) => ({
  editData: (data) => {
    dispatch({
      type: EDIT_DATA,
      payload: data,
    });
  },
  updateDataList: (dataList) => {
    dispatch({
      type: UPDATE_DATA_LIST,
      payload: dataList,
    });
  },

  getDataList: (dataList) => {
    dispatch({
      type: GET_DATA_LIST,
      payload: dataList,
    });
  },
  initDataList: (dataList) => {
    dispatch({
      type: INIT_DATA_LIST,
      payload: dataList,
    });
  },
  getData: (data) => {
    dispatch({
      type: GET_DATA,
      payload: data,
    });
  },
  getDataById: (dataID) => {
    dispatch({
      type: GET_DATA_BY_ID,
      payload: dataID,
    });
  },
  setCheckedDataItems: (payload) => {
    dispatch({
      type: CHECKED_ITEM_DATA_LIST,
      payload,
    });
  },
  setSelectedState: (payload) => {
    dispatch({
      type: SELECTED_STATE,
      payload,
    });
  },

  setDataKey: (payload) => {
    dispatch({
      type: SET_DATA_KEY,
      payload,
    });
  },
});
