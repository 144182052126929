import React from 'react';

const Loader = ({ className = '' }) => (
  <div className={`k-loading-mask ${className}`}>
    <span className="k-loading-text">Loading</span>
    <div className="k-loading-image" />
    <div className="k-loading-color" />
  </div>
);
export default Loader;
