import React from 'react';
import { getReducer, mapDispatch } from './DataTableReducer';
import { fetchCacheConfig } from '../../../../Utils/Storage/handleCacheConfig';
import { searchDatafromDB } from './Service/namedFiltersService';
import {
  getSortFromStorage,
  getFilter,
} from '../../../../Utils/DataTables/FilterAndSortHandler';
import BasicContentSlider from './ContentSlider/BasicContentSlider';
import ContentSliderHoc from './ContentSlider/ContentSliderHoc';
import ToolBarItems from './ToolBar/ToolBarItems';
import { GlobalContext } from '../../Context/GlobalContextState';
import { DEVICE_TYPES } from '../../../../constants/eventDataTypes';
import {
  TOGGLE_FILTER_DATATABLE_SLIDER,
  TOGGLE_SORT_DATATABLE_SLIDER,
} from '../../../../constants/actionTypes';
import {
  getMappedFilters,
  countObjectsFrequency,
} from '../../../../Utils/Filters/filterUtils';

const ContentSlider = ContentSliderHoc(BasicContentSlider);

/**
 * HOC to handle common functionality of dataTable
 * @param {React.Component} Component - the component to wrap with higher order functionality
 * @returns {Function} - returns higher order component
 */
const DataTableHoc = (Component) => (props) => {
  const { deviceInfo } = React.useContext(GlobalContext);
  const {
    moduleName,
    dataTableName,
    selected,
    setIsReady,
    isDefaultSlider,
    gridColumnsList,
    dataColumns,
    dataSet,
    setDataSet,
    contentSliderTitle,
    isDefaultFIlter = false,
    deleteFilter,
    applyFilter,
    saveFilters,
    total,
    isGridSortable,
  } = props;
  /**
   * Show or Hide Content Slide
   */
  const refActionSlider = React.useRef(null);
  /**
   * Data reducer and dispatch for handling state updates
   */
  const [dataStates, dispatch] = getReducer({ dataSet, total });
  const reducerActions = mapDispatch(dispatch);
  const { dataList, checkedDataItems, selectedState } = dataStates;

  const [isFavouriteItem, setIsFavouriteItem] = React.useState([]);
  const [sort, setSort] = React.useState([]);
  const resetSort = () => {
    setSort([]);
  };
  const {
    getDataList,
    initDataList,
    updateDataList,
    setCheckedDataItems,
    setSelectedState,
    setDataKey,
  } = reducerActions;
  // For Slider Filter
  const [actionType, setActionType] = React.useState(null);
  /**
   * State for managing the filter settings
   */
  const [filter, setFilter] = React.useState(null);
  const [dateRangefilter, setDateRangeFilter] = React.useState([]);
  const [gridFilterName, setGridFilterName] = React.useState({});
  /** State for managing the filter settings selected in the slider */
  const [filterName, setFilterName] = React.useState({});

  /**
   * State for managing named filters
   */
  const [namedFilter, setNamedFilter] = React.useState([]);
  /**
   * State for controlling when to refresh the data in the grid
   */
  const [isRefresh, setIsRefresh] = React.useState(false);

  /**
   * used to get occurence count of same field in array
   * @param {*} arr
   * @returns
   */

  /** Set if slider to show or hide */
  const [show, setShow] = React.useState(false);
  /**
   * State for tracking the type of action being performed on the filter slider
   */
  const [sliderAction, setSliderAction] = React.useState({});
  /**
   * Refresh data of grid
   */
  const refreshData = () => {
    setIsRefresh(true);
  };

  /** Handle Slide */
  const handleSlide = (props) => {
    const { action, ...payload } = props;
    setShow((prevShow) => {
      if (prevShow) {
        setSliderAction({ status: action, ...payload });
      }
      return !prevShow; // Update state based on the previous value
    });

    if (
      show &&
      !(
        deviceInfo?.type === DEVICE_TYPES.TABLET ||
        deviceInfo?.type === DEVICE_TYPES.PHONE
      ) &&
      setSelectedState
    ) {
      setSelectedState([], dataTableName);
    }
  };

  /**
   * set filters on filter dropdown change
   * @param {*} e - the event that triggered the filter change
   * @param {string} type - the type of filter being changed (e.g. 'grid' or 'slider')
   */
  const filterChange = (e, type) => {
    if (e?.value) {
      setFilterName(e?.value ?? {});
      if (type === 'grid') {
        setGridFilterName(e?.value);
        applyFilter(
          e?.value,
          {
            filterName,
            setFilter,
            setGridFilterName,
            moduleName,
            dataTableName,
            setGridDateRangeFilter: setDateRangeFilter,
            setSort,
            handleSlide: refActionSlider?.current?.handleSlide,
          },
          false
        );
      }
    }
  };

  /**
   * Set Value of Named Filter Dropdowns in both Grid and and filter Slider Popup
   * @param {Object} nameFilterObj - the named filter object containing the selected filters
   */
  const setNameFilterDropdownValue = (nameFilterObj) => {
    setFilterName(nameFilterObj);
    setGridFilterName(nameFilterObj);
  };

  /**
   * Reset Value of All Filters
   */
  const resetFilters = () => {
    (async () => {
      const data = await getFilter({
        dataTable: dataTableName,
        dataColumns,
        module: moduleName,
        reset: true,
      });
      return data;
    })().then((data) => {
      setFilter(data);
    });
  };

  /**
   * Set Action For Edit in Popup Slider
   * @param {Object} action - the action being set
   */
  const editAction = (action) => {
    refActionSlider?.current?.setAction(action);
  };

  /** Set Action Type for Sort Popup Slider */
  const sortSlide = () => {
    const item = {
      action: TOGGLE_SORT_DATATABLE_SLIDER,
      itemId: null,
    };
    editAction(item);
    handleSlide({ action: TOGGLE_SORT_DATATABLE_SLIDER });
  };
  /**
   * Reset Value of Filter and Sort in Grid
   */
  const resetFilterAndSort = () => {
    resetFilters();
    resetSort();
    setFilterName({});
    setGridFilterName({});
    setDateRangeFilter([]);
  };

  /**
   * Used to get base toolbar
   * @returns {React.Component} - the toolbar component
   */
  const getToolbar = () => {
    const isResetAllowed = isFilterUpdated();
    const tempToolbar = ToolBarItems({
      resetFilterAndSort,
      filterSlide,
      sortSlide,
      isResetAllowed,
      refreshData,
      isGridSortable,
    });
    return tempToolbar;
  };

  /** @event
   * set filters based on DataTable
   */
  React.useEffect(() => {
    let filters = null;
    (async () => {
      let SelectedNamedFilter = await fetchCacheConfig(
        `${moduleName}.${dataTableName}.Filter`,
        'SelectedNamedFilter'
      );
      if (SelectedNamedFilter?.length > 0) {
        filters = await searchDatafromDB(moduleName, dataTableName, {
          FilterName: SelectedNamedFilter,
        });
      }

      // get Filter from dexieDB

      setNameFilterDropdownValue(filters ?? {});
      if (filters) {
        const mappedFilters = getMappedFilters({
          dataTableName,
          dataColumns,
          filters: filters.Filters,
        });
        setDateRangeFilter(
          mappedFilters.filter((obj) => obj.type === 'dateRange')
        );
        setFilter({
          logic: 'and',
          filters: filters?.Filters ?? [],
        });
      } else if (!isDefaultFIlter) {
        const data = await getFilter({
          dataTable: dataTableName,
          dataColumns,
          module: moduleName,
        });
        setFilter(data ?? []);
        setDateRangeFilter(data?.filters);
      }
      const data = await getSortFromStorage(moduleName, dataTableName);
      if (data) setSort(data);
      setIsReady(true);
    })();
  }, [dataTableName]);

  /** Set Action Type for Filter Slide Popup */
  const filterSlide = ({ action }) => {
    const item = {
      action: TOGGLE_FILTER_DATATABLE_SLIDER,
      itemId: null,
    };
    editAction(item);
    if (action) {
      setActionType(action);
    }
    handleSlide({ action: TOGGLE_FILTER_DATATABLE_SLIDER });
  };
  /**
   * Used to Check that filter/sort is Updated or not
   * @returns {Boolean}
   */
  const isFilterUpdated = () => {
    if (filter?.filters?.length > 0 || sort?.length > 0) {
      return true;
    }
    return false;
  };
  React.useEffect(() => {
    setDataSet(dataList);
  }, [dataList]);

  const args = {
    refActionSlider,
    dataSet,
    sort,
    checkedDataItems,
    selectedState,
    getDataList,
    initDataList,
    updateDataList,
    setSort,
    setCheckedDataItems,
    setSelectedState,
    setDataKey,
    actionType,
    filter,
    setFilter,
    gridFilterName,
    setGridFilterName,
    filterName,
    setFilterName,
    namedFilter,
    setNamedFilter,
    filterChange,
    getFilter,
    saveFilters,
    applyFilter,
    deleteFilter,
    setActionType,
    resetSort,
    ToolBarItems,
    resetFilterAndSort,
    resetFilters,
    filterSlide,
    sortSlide,
    isFilterUpdated,
    isRefresh,
    setIsRefresh,
    refreshData,
    editAction,
    getToolbar,
    handleSlide,
    show,
    sliderAction,
    setSliderAction,
    dateRangefilter,
    setDateRangeFilter,
    isFavouriteItem,
    setIsFavouriteItem,
  };
  return (
    <>
      <Component {...args} {...props} />
      {isDefaultSlider && (
        <ContentSlider
          show={show}
          handleSlide={handleSlide}
          moduleName={moduleName}
          gridColumnsList={gridColumnsList}
          dataColumns={dataColumns}
          ref={refActionSlider}
          setSort={setSort}
          sort={sort}
          resetSort={resetSort}
          filterChange={filterChange}
          setFilter={setFilter}
          filter={filter}
          selected={selected}
          dataTableName={dataTableName}
          getFilter={getFilter}
          setSelectedState={setSelectedState}
          checkedDataItems={checkedDataItems}
          filterName={filterName}
          setFilterName={setFilterName}
          setNamedFilter={setNamedFilter}
          namedFilter={namedFilter}
          setGridFilterName={setGridFilterName}
          gridFilterName={gridFilterName}
          setActionType={setActionType}
          actionType={actionType}
          sliderAction={sliderAction}
          setSliderAction={setSliderAction}
          deleteFilter={() => {
            deleteFilter({
              moduleName,
              dataTableName,
              filterName,
              setFilterName,
              gridFilterName,
              setGridFilterName,
              setNamedFilter,
              setGridDateRangeFilter: setDateRangeFilter,
              setFilter,
            });
          }}
          applyFilter={() => {
            applyFilter(null, {
              filterName,
              setFilter,
              setSort,
              setGridFilterName,
              handleSlide: refActionSlider?.current?.handleSlide,
              moduleName,
              dataTableName,
              setGridDateRangeFilter: setDateRangeFilter,
            });
          }}
          saveFilters={(e) => {
            saveFilters({
              ...e,
              filterName,
              setNamedFilter,
              setFilterName,
              setActionType,
              setFilter,
              setSort,
              sort,
              setGridFilterName,
              handleSlide: refActionSlider?.current?.handleSlide,
              moduleName,
              setGridDateRangeFilter: setDateRangeFilter,
              getMappedFilters,
              countObjectsFrequency,
              dataColumns,
              setFilter,
            });
          }}
          dateRangefilter={dateRangefilter}
          setDateRangeFilter={setDateRangeFilter}
          title={contentSliderTitle}
        />
      )}
    </>
  );
};

export default DataTableHoc;
