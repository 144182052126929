import React from 'react';
import {
  Button,
  ButtonGroup,
  Toolbar,
  ToolbarItem,
  ToolbarSeparator,
  DropDownButton,
} from '@progress/kendo-react-buttons';

import '../../../../assets/scss/common/Menu.scss';
import '../../../../assets/scss/common/Toolbar.scss';

import './Toolbar.scss';
import DataGridToolbar from './DataGridToolbar';
import DataCardToolbar from './DataCardToolbar';
import MessageAlert from '../MessageAlert/MessageAlert';

/* Register Components to Parse by Parser */
const toolbarComponents = {
  Button,
  ButtonGroup,
  ToolbarSeparator,
  ToolbarItem,
  DropDownButton,
  MessageAlert,
};

/**
 * Design Toolbar For Grid
 * @type {React.FC<Props>}
 * @returns {React.ReactElement} The toolbar component.
 */
const ToolBar = (props) => {
  const {
    toolbar,
    gridView,
    pageListChange,
    rowSizeChange,
    rowSizes,
    pageLength,
    namedFilters,
    filterName,
    filterSlide,
    filterChange,
    resetSettings,
    enterEdit,
    deleteRows,
    save,
    cancel,
    remove,
    addNew,
    refreshDataTable,
    revertChanges,
    setFilterName,
    setDraggableToggle,
  } = props;
  const { dataGridToobar, actionToolbar } = toolbar;

  const [toolbarExpanded, setToolbarExpanded] = React.useState(false);
  const [cardToolbarExpanded, setCardToolbarExpanded] = React.useState(false);

  /**
   * Toggle toolbar
   */
  const toggleToolbar = () => {
    setToolbarExpanded((prevtoolbarExpanded) => !prevtoolbarExpanded);
  };
  /**
   * Toggle toolbar
   */
  const toggleCardToolbar = () => {
    setCardToolbarExpanded((prevtoolbarExpanded) => !prevtoolbarExpanded);
  };
  const [filterExpanded, setFilterExpanded] = React.useState(false);
  /**
   * Toggle Filter Dropdown
   */
  const toggleFilter = () => {
    setFilterExpanded((prevFilterExpanded) => !prevFilterExpanded);
  };

  /**
   * Handle Draggable Toggle Value
   */
  const handleDraggableToggle = () => {
    setDraggableToggle(prevState => !prevState);
  };

  /**
   * Register Function to access by Parse Array
   */
  const toolbarFunctions = {
    toggleToolbar,
    toggleFilter,
    toggleCardToolbar,
    handleDraggableToggle,
    enterEdit,
    deleteRows,
    save,
    cancel,
    remove,
    addNew,
    revertChanges,
    refreshDataTable,
  };

  /**
   * Toolbar Filter
   * @param {*} fields
   * @returns {DisplayType} returns Field display Type
   */
  const toolbarFilter = (fields) =>
    fields.filter((field) => {
      let type = gridView ? 'grid' : 'card';
      return (
        field.show &&
        (type === field.displayType || field.displayType === 'both')
      );
    });

  /**
   * Used to Parse the Toolbar Fields.
   * By recursively Parse the fields array and map the associated component/Functions with it.
   * which we already defined in the toolbarComponent and toolbarFunctions array
   * Parse the SubFields like buttons and map the Default properties to it component
   * @param {*} fields
   * @returns {TargetComponent} returns parsed Toolbar array
   */
  const parseToolbarArray = (dataFields) => {
    if (dataFields) {
      return toolbarFilter(dataFields).map((field, i) => {
        const {
          displayName,
          type,
          fields,
          show,
          displayType,
          events,
          ...rfieldMeta
        } = field;

        if (events) {
          Object.keys(events).forEach((key) => {
            if (typeof events[key] === 'string') {
              events[key] = toolbarFunctions[events[key]];
            }
          });
        }
        const TargetComponent = toolbarComponents[type];
        const subFields = fields?.length > 0 ? parseToolbarArray(fields) : '';

        return (
          <TargetComponent key={i} {...rfieldMeta} {...events}>
            {subFields ? subFields : displayName}
          </TargetComponent>
        );
      });
    }
    return '';
  };
  return (
    <>
      <div className='gridfilter-topbar'>
        <Toolbar className='menu-toolbar left'>
          {dataGridToobar && parseToolbarArray(dataGridToobar)}
        </Toolbar>
        <Toolbar className='menu-toolbar right'>
          {actionToolbar && parseToolbarArray(actionToolbar)}
        </Toolbar>
      </div>
      {gridView ? (
        <DataGridToolbar
          toolbarExpanded={toolbarExpanded}
          filterExpanded={filterExpanded}
          rowSizes={rowSizes}
          pageLength={pageLength}
          rowSizeChange={rowSizeChange}
          pageListChange={pageListChange}
          filterSlide={filterSlide}
          namedFilters={namedFilters}
          filterName={filterName}
          setFilterName={setFilterName}
          filterChange={filterChange}
          filter={props.filter}
          resetSettings={resetSettings}
          gridFilterName={props.gridFilterName}
          setGridFilterName={props.setGridFilterName}
          rowLayouts={props.rowLayouts}
          pageSizes={props.pageSizes}
        />
      ) : (
        <DataCardToolbar
          toolbarExpanded={cardToolbarExpanded}
          pageLength={pageLength}
          pageListChange={pageListChange}
          resetSettings={resetSettings}
          rowLayouts={props.rowLayouts}
          pageSizes={props.pageSizes}
        />
      )}
    </>
  );
};
export default ToolBar;
