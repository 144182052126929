import React from 'react';

import Loader from '../../Loader/Loader';

const Slider = React.lazy(() => import('../../Slider/Slider'));

/**
 * Used to populate the Slider Card Content based on the provided action
 * @param{*} props
 * @param{*} ref we have to declare the ref here. it's forwardRef base component, although we are not using it
 * still so we need the ref here.
 * @return {Slider}
 */
const BasicContentSlider = React.forwardRef(({ show, children }, ref) => (
  <React.Suspense fallback={<Loader />}>
    <Slider show={show} cardBody={children} />
  </React.Suspense>
));
export default BasicContentSlider;
