import moment from 'moment';
import {
  CONTAINS_OP,
  EQUALS_OP,
  NOT_EQUALS_OP,
  GREATER_THAN_EQUAL_OP,
  GREATER_THAN_OP,
  LESS_THAN_OP,
  LESS_THAN_EQUAL_OP,
  STARTS_WITH_OP,
  ENDS_WITH_OP,
} from '../../constants/applicationConstants';
import { fetchCacheConfig } from '../Storage/handleCacheConfig';
import { getMappedFilters, getVariableType } from './filterUtils';

/**
 * get Filters
 * @param {*} filters
 * @returns {FilterArray} returns filter array
 */
export function getFilters(filters) {
  try {
    var filterArray = [];
    filters?.map((item) => {
      const { columnName, operator } = getGraphqlFilterName(item?.columnName);
      let itemValue = item.columnValue;
      if (item.columnValue === 'true') {
        itemValue = true;
      } else if (item.columnValue === 'false') {
        itemValue = false;
      }
      filterArray.push({
        field: `${columnName}`,
        operator: getKendoOperator(operator),
        value: itemValue,
      });
    });

    return filterArray;
  } catch (error) {
    return [];
  }
}

/**
 * parse columnName and operator from Graphql filter
 * @param {String} filterName
 * @returns {Array} returns graphQLFilter
 */
const getGraphqlFilterName = (filterName) => {
  const columnMeta = filterName.split('[');
  const columnName = columnMeta[0];
  const operator = columnMeta[1].replace(']', '');
  return {
    columnName,
    operator,
  };
};

/**
 * set Filters according to graph QL filters.
 * Filter for graphQL filter is as follows: [{columnName:'{fieldName}[operator]',columnValue:fieldValue}]
 * @param {*} filters list of kendo grid filters. the format for kendo grid filter is  [{field:'{fieldName},operator:'operator',value:fieldValue}]
 * @returns {FilterArray} returns Filter Array
 */
export function setFilters({
  filters,
  moduleName = '',
  hasDefaultFilters = false,
  isServiceWorker = false,
  convertFilters = false,
  dataColumns,
}) {
  try {
    var filterArray = [];
    var replaceableFilters = [''];

    // set default filters based on tab selected
    if (hasDefaultFilters)
      filterArray = setDefaultFilters(filterArray, moduleName, isServiceWorker);

    // map filters with types from data columns array
    const mappedFilters = getMappedFilters({
      dataColumns,
      filters,
    });
    mappedFilters.map((item) => {
      const op = getGraphQLOperator(item.operator);
      // check if filter is also a part of default filters
      const filteredValue = filterArray.filter(
        (f) => f.columnName === `${item?.field}[${op}]`
      );

      if (
        (!filteredValue?.length > 0 ||
          (filteredValue?.length > 0 &&
            replaceableFilters.includes(item?.field))) &&
        hasDefaultFilters
      ) {
        // check variable type for value
        const variableType = getVariableType(item?.type ?? 'text', item?.value);
        // over-ride filter (check if filterArray have user defined filter then remove it)
        if (variableType !== 'DateRange')
          filterArray = filterArray.filter((f) => {
            const { columnName } = getGraphqlFilterName(f.columnName);
            return columnName !== `${item?.field}`;
          });

        // process user defined filters
        if (variableType) {
          let { value, operator } = item;
          if (variableType === 'Array') {
            // create a comma separated list
            if ((value?.length ?? 0) > 0)
              filterArray.push({
                columnName: `${item.field}[${operator !== EQUALS_OP ? getGraphQLOperator(operator) : EQUALS_OP}]`,
                columnValue: value
                  ?.map((data) => data?.value ?? data?.label ?? '')
                  ?.join(','),
              });
          } else if (variableType === 'Date') {
            // check if filter has date then convert date to a date range

            processDate(filterArray, item);
          } else if (variableType === 'DateRange') {
            // check if filter has date then convert date to a date range
            if (Array.isArray(item?.value)) {
              item?.value?.forEach((obj) => {
                processDateRange(filterArray, obj);
              });
            } else {
              processDateRange(filterArray, item);
            }
          } else {
            filterArray.push({
              columnName: `${item.field}[${op}]`,
              columnValue: value?.toString(),
            });
          }
        }
      }
    });
    return convertFilters ? changeFilterArray(filterArray) : filterArray;
  } catch (error) {
    return [];
  }
}

/**
 * Convert Kendo Filters according to graph QL filters.
 * Filter for graphQL filter is as follows: [{columnName:'{fieldName}[operator]',columnValue:fieldValue}]
 * @param {*} filters list of kendo grid filters. the format for kendo grid filter is  [{field:'{fieldName},operator:'operator',value:fieldValue}]
 * @returns {FilterArray} returns Filter Array
 */
export function kendoFilterToGraphql({ filters, dataColumns }) {
  try {
    var filterArray = [];
    // map filters with types from data columns array
    const mappedFilters = getMappedFilters({
      filters,
      dataColumns,
    });
    mappedFilters.map((item) => {
      const op = getGraphQLOperator(item.operator);
      const variableType = getVariableType(item?.type ?? 'text', item?.value);
      if (variableType) {
        let { value, operator } = item;

        if (variableType === 'Array') {
          // create a comma separated list
          if ((value?.length ?? 0) > 0)
            filterArray.push({
              columnName: `${item.field}[${operator !== EQUALS_OP ? getGraphQLOperator(operator) : EQUALS_OP}]`,
              columnValue: value
                ?.map((data) => data?.value ?? data?.label ?? '')
                ?.join(','),
            });
        } else if (variableType === 'Date') {
          // check if filter has date then convert date to a date range

          processDate(filterArray, item);
        } else if (variableType === 'DateRange') {
          // check if filter has date then convert date to a date range
          if (Array.isArray(item?.value)) {
            item?.value?.forEach((obj) => {
              processDateRange(filterArray, obj);
            });
          } else {
            processDateRange(filterArray, item);
          }
        } else {
          filterArray.push({
            columnName: `${item.field}[${op}]`,
            columnValue: value?.toString(),
          });
        }
      }
    });

    return filterArray;
  } catch (error) {
    return [];
  }
}
/**
 * process Kendo Date as per operator for GraphQL
 * process start and end date according to the operator.
 * if operator is less than date provided then consider provided date as end date
 * if operator is greater than date provided then consider provided date as start date
 * if operator is equal to date provided then consider provided date as start and end date by updating time value for day start and end
 * @param {*} filterArray
 * @param {*} item updated graphql date filter
 */
export const processDate = (filterArray, item) => {
  let startDate = moment(item?.value).format('YYYY-MM-DDT00:00:00.000');
  let endDate = moment(item?.value).format('YYYY-MM-DDT23:59:59.000');
  switch (item?.operator) {
    case 'eq':
      filterArray.push(
        {
          columnName: `${item.field}[${GREATER_THAN_EQUAL_OP}]`,
          columnValue: startDate,
        },
        {
          columnName: `${item.field}[${LESS_THAN_EQUAL_OP}]`,
          columnValue: endDate,
        }
      );
      break;
    case 'gt':
      filterArray.push({
        columnName: `${item.field}[${GREATER_THAN_OP}]`,
        columnValue: endDate,
      });
      break;
    case 'gte':
      filterArray.push({
        columnName: `${item.field}[${GREATER_THAN_EQUAL_OP}]`,
        columnValue: startDate,
      });
      break;
    case 'lt':
      filterArray.push({
        columnName: `${item.field}[${LESS_THAN_OP}]`,
        columnValue: startDate,
      });
      break;
    case 'lte':
      filterArray.push({
        columnName: `${item.field}[${LESS_THAN_EQUAL_OP}]`,
        columnValue: endDate,
      });
      break;
    default:
      break;
  }
};

/**
 * process Kendo Date Range as per operator for GraphQL
 * process start and end date according to the operator.
 * if operator is less than equal to date provided then consider provided date as end date
 * if operator is greater than equal to  date provided then consider provided date as start date
 * @param {*} filterArray
 * @param {*} item updated graphql date filter
 */
export const processDateRange = (filterArray, item) => {
  let startDate = moment(item?.value).format('YYYY-MM-DDT00:00:00.000');
  let endDate = moment(item?.value).format('YYYY-MM-DDT23:59:59.000');
  switch (item?.operator) {
    case 'gte':
      filterArray.push({
        columnName: `${item.field}[${GREATER_THAN_EQUAL_OP}]`,
        columnValue: startDate,
      });
      break;
    case 'lte':
      filterArray.push({
        columnName: `${item.field}[${LESS_THAN_EQUAL_OP}]`,
        columnValue: endDate,
      });
      break;
    default:
      break;
  }
};

/**
 * set Default Filters
 * @param {*} filters
 * @param {*} tab
 * @returns {filters} returns default filters array
 */
export function setDefaultFilters(filters) {
  return filters;
}

/**
 * get Operator for filter value.
 * convert a kendo grid filter operator to GraphQL equivalent filter name
 * @param {*} operator
 * @returns {Operator} Returns Graph QL Operator
 */
export function getGraphQLOperator(operator) {
  var op = 'eq';
  try {
    switch (operator) {
      case 'eq':
        op = `${EQUALS_OP}`;
        break;
      case 'neq':
        op = `${NOT_EQUALS_OP}`;
        break;
      case 'contains':
        op = `${CONTAINS_OP}`;
        break;
      case 'gt':
        op = `${GREATER_THAN_OP}`;
        break;
      case 'gte':
        op = `${GREATER_THAN_EQUAL_OP}`;
        break;
      case 'lt':
        op = `${LESS_THAN_OP}`;
        break;
      case 'lte':
        op = `${LESS_THAN_EQUAL_OP}`;
        break;
      case 'startswith':
        op = `${STARTS_WITH_OP}`;
        break;
      case 'endswith':
        op = `${ENDS_WITH_OP}`;
        break;
      default:
        op = `${EQUALS_OP}`;
        break;
    }
    return op;
  } catch (error) {
    return op;
  }
}

//  #region  Kendo Items
/**
 * get Kendo Operator
 * it returned Kendo Operator from GraphQL operator
 * @param {*} operator
 * @returns {Operator} Returns Kendo Operator
 */
export function getKendoOperator(operator) {
  var op = 'eq';
  try {
    switch (operator) {
      case EQUALS_OP:
        op = 'eq';
        break;
      case NOT_EQUALS_OP:
        op = 'neq';
        break;
      case CONTAINS_OP:
        op = 'contains';
        break;
      case GREATER_THAN_OP:
        op = 'gt';
        break;
      case GREATER_THAN_EQUAL_OP:
        op = 'gte';
        break;
      case LESS_THAN_OP:
        op = 'lt';
        break;
      case LESS_THAN_EQUAL_OP:
        op = 'lte';
        break;
      case STARTS_WITH_OP:
        op = 'startswith';
        break;
      case ENDS_WITH_OP:
        op = 'endswith';
        break;
      default:
        break;
    }
    return op;
  } catch (error) {
    return op;
  }
}

/**
 * Parse Kendo filters as per kendo API
 * it converts dexie/cached filters and returns Kendo filters
 * it also get value from array values and pass to filters.
 * format for kendo filter is [{field:'{fieldName},operator:'operator',value:fieldValue}]
 * @param {*} filters
 * @returns {filters} Returns formatted Kendo Filters
 */
export function parseKendoFilters({ dataColumns, filters }) {
  var andFilter = { logic: 'and', filters: [] };
  try {
    // map filters with types from data columns array
    const mappedFilters = getMappedFilters({
      dataColumns,
      filters,
    });
    mappedFilters.map((item) => {
      // check variable type for value
      const variableType = getVariableType(item?.type ?? 'text', item?.value);
      // process user defined filters
      if (variableType) {
        let { value, operator, field } = item;

        if (variableType === 'Array') {
          // create a filter based on or operator
          if ((value?.length ?? 0) > 0) {
            var orFilter = { logic: 'or', filters: [] };
            value?.map((data) => {
              orFilter.filters.push({
                field: `${item.field}`,
                operator: `${operator !== EQUALS_OP ? operator : EQUALS_OP}`,
                value: data?.value ?? data?.label ?? '',
              });
            });
            andFilter.filters.push(orFilter);
          }
        } else if (variableType === 'Date') {
          // check if filter has date then convert date to a date range
          processKendoDate(andFilter, item);
        } else if (variableType === 'boolean') {
          // check if filter has date then convert date to a date range
          andFilter.filters.push({
            field,
            operator,
            value: ['true', '1'].includes(value?.trim()?.toLowerCase()),
          });
        } else {
          andFilter.filters.push(item);
        }
      }
    });
    return andFilter;
  } catch (error) {
    return andFilter;
  }
}

/**
 * process Kendo single Date filter as per operator to range filter
 * process start and end date according to the operator.
 * if operator is less than date provided then consider provided date as end date
 * if operator is greater than date provided then consider provided date as start date
 * if operator is equal to date provided then consider provided date as start and end date by updating time value for day start and end
 * @param {*} filterArray
 * @param {*} item updated kendo date filter
 */
function processKendoDate(filterArray, item) {
  let startDate = moment(item?.value).format('YYYY-MM-DDT00:00:00');
  let endDate = moment(item?.value).format('YYYY-MM-DDT23:59:59');
  switch (item?.operator) {
    case 'eq':
      filterArray.filters.push(
        {
          field: `${item.field}`,
          operator: `${GREATER_THAN_EQUAL_OP}`,
          value: startDate,
          type: item?.type ?? 'text',
        },
        {
          field: `${item.field}`,
          operator: `${LESS_THAN_EQUAL_OP}`,
          value: endDate,
          type: item?.type ?? 'text',
        }
      );
      break;
    case 'gt':
      filterArray.filters.push({
        field: `${item.field}`,
        operator: `${GREATER_THAN_OP}`,
        value: endDate,
        type: item?.type ?? 'text',
      });
      break;
    case 'gte':
      filterArray.filters.push({
        field: `${item.field}`,
        operator: `${GREATER_THAN_EQUAL_OP}`,
        value: startDate,
        type: item?.type ?? 'text',
      });
      break;
    case 'lt':
      filterArray.filters.push({
        field: `${item.field}`,
        operator: `${LESS_THAN_OP}`,
        value: startDate,
        type: item?.type ?? 'text',
      });
      break;
    case 'lte':
      filterArray.filters.push({
        field: `${item.field}`,
        operator: `${LESS_THAN_EQUAL_OP}`,
        value: endDate,
        type: item?.type ?? 'text',
      });
      break;
    default:
      break;
  }
}
// #endregion

/**
 * Get Filters as Per module name from storage
 * first check local storage for filters, then dexie db
 * parse storage filters to kendo gri filters
 * @param {*} key
 * @param {*} reset
 * @returns {Array} returns filter array from storage
 */
export const getFiltersFromStorage = async ({
  key,
  dataColumns,
  reset = false,
}) => {
  const filterArray = {
    logic: 'and',
    filters: [],
  };

  // check if localStorage has filters
  const filters = await getFilterFromStorage(key);
  if (filters && filters.length > 0 && !reset) {
    var filtersArray = [];
    // map filters with types from data columns array
    const mappedFilters = getMappedFilters({ dataColumns, filters });
    mappedFilters.map((item) => {
      const variableType = getVariableType(item?.type ?? 'text', item?.value);
      if (variableType === 'Date')
        item.value = moment(item.value).format('YYYY-MM-DD');
      filtersArray.push({
        field: item.field,
        operator: item.operator,
        value: variableType === 'Date' ? new Date(item.value) : item.value,
        type: item?.type ?? 'text',
      });
    });
    filterArray.filters = filtersArray;
  }
  return filterArray;
};

/**
 * Get Filter From Storage
 * @async
 * @param {*} key - The name of the key to retrieve configuration for.
 * @returns {Promise<object|null>} The configuration object or null if not found.
 */
const getFilterFromStorage = async (key) =>
  fetchCacheConfig(`${key}`, 'filters') ?? [];

/**
 * changeFilterArray
 * @param {*} inputArray
 */
const changeFilterArray = (inputArray) => {
  const array = inputArray.map((item) => {
    // Extract the operation from the column name using regex
    const match = item.columnName?.match(/\[(.*?)\]/);
    const operation = match ? match[1] : 'eq'; // Default to 'eq' if no operation found

    // Remove the operation from column name
    const columnName = item.columnName?.replace(/\[.*?\]/, '');

    return {
      ColumnName: columnName,
      Op: operation,
      ColumnValue: item.columnValue,
    };
  });
  return array;
};
