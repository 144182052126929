import React, { useState, useEffect } from 'react';
import { MultiViewCalendar } from '@progress/kendo-react-dateinputs';
import { Popup } from '@progress/kendo-react-popup';
import { Button } from '@progress/kendo-react-buttons';
import moment from 'moment';

const EMPTY_SELECTIONRANGE = {
  start: null,
  end: null,
};

const MultiViewCalenderFilter = (props) => {
  const inputRef = React.useRef(null); // Reference for the input field

  const { dateRangefilter, setDateRangeFilter, field } = props;

  const [state, setState] = useState({
    value: EMPTY_SELECTIONRANGE,
    selecting: false, // Tracks whether the user is actively selecting a range
  });
  const [popupVisible, setPopupVisible] = useState(false);

  useEffect(() => {
    // Load existing filter values into the component
    const minDate = dateRangefilter?.find(
      (obj) => obj.field === field && obj.operator === 'gte'
    )?.value;
    const maxDate = dateRangefilter?.find(
      (obj) => obj.field === field && obj.operator === 'lte'
    )?.value;

    setState({
      value: {
        start: minDate ? new Date(minDate) : null,
        end: maxDate ? new Date(maxDate) : null,
      },
      selecting: false,
    });
  }, [dateRangefilter, field]);

  const handleChange = (event) => {
    const { start, end } = event.value;

    if (!state.selecting) {
      // Starting the selection
      setState({
        value: { start, end: null },
        selecting: true,
      });
    } else {
      // Finalizing the range
      const updatedValue = { start, end };

      let currentFilters = [...dateRangefilter];
      if (updatedValue.start && updatedValue.end) {
        if (currentFilters?.length > 0) {
          // Remove any previous filter for this field
          let newFilter = currentFilters?.filter(
            (obj) => !(obj.field === field)
          );
          currentFilters = newFilter;
          /**
           * Filter out the Unique item of Date Range
           */
          const uniqueFilterItem = dateRangefilter?.reduce((acc, curr) => {
            let existing = acc.find((item) => item.field === curr.field);
            if (!existing) {
              acc.push({ field: curr.field, operator: curr.operator });
            }
            return acc;
          }, []);
          /**
           * Remove Duplicate item from filters
           */
          let finalizedFilterList = [];
          uniqueFilterItem.forEach((obj) => {
            finalizedFilterList = dateRangefilter?.filter(
              (nobj) => nobj.field === obj.field
            );
          });
          if (event.value)
            currentFilters.push(
              {
                field,
                operator: 'gte',
                value: moment(updatedValue.start)
                  .startOf('day')
                  .format('YYYY-MM-DD'),
                type: 'dateRange',
              },
              {
                field,
                operator: 'lte',
                value: moment(updatedValue.end)
                  .startOf('day')
                  .format('YYYY-MM-DD'),
                type: 'dateRange',
              }
            );
        } else if (event.value)
          currentFilters.push(
            {
              field,
              operator: 'gte',
              value: moment(updatedValue.start)
                .startOf('day')
                .format('YYYY-MM-DD'),
              type: 'dateRange',
            },
            {
              field,
              operator: 'lte',
              value: moment(updatedValue.end)
                .startOf('day')
                .format('YYYY-MM-DD'),
              type: 'dateRange',
            }
          );
      }

      setDateRangeFilter(currentFilters);
      setState({ value: updatedValue, selecting: false });

      // Trigger onChange to notify the parent component
      if (currentFilters?.length > 0) {
        setPopupVisible(false); // Close popup
        props.onChange({
          text: event.value?.label,
          value: currentFilters?.filter((f) => f.field === field) ?? [],
          operator: event.operator,
          type: 'dateRange',
          syntheticEvent: event.syntheticEvent,
          field,
          formRenderProps: props.formRenderProps ?? '',
        });
      }
    }
  };

  const formatDateRange = () => {
    const { start, end } = state.value;
    if (start && end) {
      return `${moment(start).format('MMM D, YYYY')} - ${moment(end).format('MMM D, YYYY')}`;
    } else if (start && end && start === end) {
      return `${moment(start).format('MMM D, YYYY')}`;
    } else if (start) {
      return moment(start).format('MMM D, YYYY');
    }
    return '';
  };

  const onClearButtonClick = (event) => {
    event.preventDefault();
    let currentFilters = [...dateRangefilter];
    let newFilter = currentFilters.filter((obj) => obj.field !== field);
    currentFilters = newFilter;
    setDateRangeFilter(newFilter);

    setState({ value: EMPTY_SELECTIONRANGE, selecting: false });

    // Trigger onChange to notify the parent component
    props.onChange({
      text: newFilter?.length > 0 ? event.value?.label : '',
      value: newFilter?.length > 0 ? currentFilters : '',
      operator: newFilter?.length > 0 ? event.operator : '',
      type: 'dateRange',
      syntheticEvent: event.syntheticEvent,
      field: field,
      formRenderProps: props.formRenderProps ?? '',
    });
  };

  return (
    <div className="k-filtercell">
      <div className="k-filtercell-wrapper">
        <input
          type="text"
          value={formatDateRange()}
          readOnly
          onClick={() => setPopupVisible(!popupVisible)}
          className="k-textbox k-input k-input-md k-input-solid k-rounded-md"
          ref={inputRef}
        />
        <Popup
          anchor={inputRef.current} // Use inputRef as anchor for the popup
          show={popupVisible}
          popupClass={'popup-content'}
          onBlur={() => setPopupVisible(false)} // Close when clicking outside
          autoClose={false}
          onClose={() => setPopupVisible(false)} // Close popup
        >
          <MultiViewCalendar
            mode="range"
            value={state.value}
            onChange={handleChange}
            topView="month"
            selectionRange
          />
        </Popup>
        <Button
          title="Clear"
          disabled={!state.value.start && !state.value.end}
          onClick={onClearButtonClick}
          icon="filter-clear"
        />
      </div>
    </div>
  );
};

export default MultiViewCalenderFilter;
