import React from 'react';
import { Notification } from '@progress/kendo-react-notification';
import { EVENTS_DATA_TYPES } from '../../../../constants/eventDataTypes';
import './NotificationRenderer.scss';

/**
 * Renders a notification with the given information.
 * @param {Object} notification - The notification information.
 * @param {Object} notification.style - The style of the notification.
 * @param {string} notification.icon - The icon of the notification.
 * @param {boolean} notification.closable - Indicates if the notification can be closed.
 * @param {Function|string} notification.callBack - The callback function to be called when the notification is clicked.
 * @param {string} notification.title - The title of the notification.
 * @param {string} notification.description - The description of the notification.
 * @param {string} notification.notificationId - The ID of the notification.
 * @param {number} notification.count - The count of the notification.
 * @param {Function} onNotificationClose - The callback function to be called when the notification is closed.
 * @returns {JSX.Element} The notification view.
 */
const NotificationRenderer = ({ notification, onNotificationClose }) => {
  let {
    style,
    icon,
    closable,
    callBack,
    title,
    description,
    notificationId,
    count,
  } = notification;
  /**
   * It's calling by service woker so it's showing as unused method here.
   */
  var reloadAllClients = () => {
    if (navigator.serviceWorker && navigator.serviceWorker.controller) {
      navigator.serviceWorker.controller.postMessage({
        type: EVENTS_DATA_TYPES.RELOAD,
      });
    }
  };
  if (typeof callBack === 'string') {
    // eslint-disable-next-line no-eval
    callBack = eval(`(${callBack.toString()})`);
  }

  // #region  Notification View/Settings
  return (
    <Notification
      className="k-notification-wrap"
      type={{
        style,
        icon,
      }}
      closable={closable}
      onClose={() => onNotificationClose(notificationId)}
    >
      <div onClick={callBack ? callBack : undefined}>
        <span className="heading">
          {title} {count > 0 && <span>({count})</span>}
        </span>
        <br />
        <span dangerouslySetInnerHTML={{ __html: description }} />
      </div>
    </Notification>
  );

  // #endregion
};
export default NotificationRenderer;
