import * as React from 'react';
import { Dialog, DialogActionsBar } from '@progress/kendo-react-dialogs';
import { Button } from '@progress/kendo-react-buttons';

/**
 * Kendo Dialogue Component to show confirmation text
 * @type {React.FC<Props>}
 * @returns {React.ReactElement} The Dialogue component.
 */
const Dialogue = (props) => {
  const { title, message, onClose, onClick, onCancel } = props;
  return (
    <Dialog title={title} onClose={onClose}>
      <p
        style={{
          margin: '25px',
          textAlign: 'center',
        }}
      >
        {message}
      </p>
      <DialogActionsBar>
        <Button title="No" onClick={onCancel}>
          No
        </Button>
        <Button title="Yes" themeColor="primary" onClick={onClick}>
          Yes
        </Button>
      </DialogActionsBar>
    </Dialog>
  );
};
export default Dialogue;
