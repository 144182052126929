/**
 * Handle Toolbar Elements Array
 * @param {function} resetFilterAndSort
 * @param {function} filterSlide
 * @param {function} sortSlide
 * @param {Object} actionButtonsArray
 * @returns {Array} returns array of toolbar items
 */
const ToolBarItems = ({
 resetFilterAndSort,
 filterSlide,
 sortSlide,
 isResetAllowed,
 refreshData,
 isGridSortable,
 }) => ({
  dataGridToobar: [
    {
      type: 'ToolbarItem',
      show: true,
      displayType: 'both',
      fields: [
        {
          type: 'ButtonGroup',
          show: true,
          displayType: 'both',
          fields: [
            {
              type: 'Button',
              fillMode: 'outline',
              togglable: true,
              themeColor: 'primary',
              icon: 'cog',
              show: true,
              displayType: 'grid',
              title: 'Settings',
              events: {
                onClick: 'toggleToolbar',
              },
            },
            {
              type: 'Button',
              fillMode: 'outline',
              themeColor: 'primary',
              togglable: true,
              icon: 'arrows-swap',
              show: isGridSortable?.sortable,
              displayType: 'both',
              events: {
                onClick: 'handleDraggableToggle',
              },
            },
            {
              type: 'Button',
              fillMode: 'outline',
              themeColor: 'primary',
              icon: 'filter',
              show: true,
              displayType: 'grid',
              title: 'Filter',
              events: {
                onClick: 'toggleFilter',
              },
            },
            {
              type: 'Button',
              fillMode: 'outline',
              themeColor: 'primary',
              togglable: true,
              icon: 'cog',
              show: true,
              displayType: 'card',
              events: {
                onClick: 'toggleCardToolbar',
              },
            },
            {
              type: 'Button',
              fillMode: 'outline',
              themeColor: 'primary',
              icon: 'sort-desc',
              show: true,
              displayType: 'card',
              events: {
                onClick: sortSlide,
              },
            },
            {
              type: 'Button',
              fillMode: 'outline',
              themeColor: 'primary',
              icon: 'filter',
              show: true,
              displayType: 'card',
              events: {
                onClick: filterSlide,
              },
            },
            {
              type: 'Button',
              fillMode: 'outline',
              themeColor: 'primary',
              icon: 'filter-clear',
              show: true,
              displayType: 'both',
              disabled: !isResetAllowed,
              title: 'Reset Filters and Sort',
              events: {
                onClick: resetFilterAndSort,
              },
            },
            {
              type: 'Button',
              fillMode: 'outline',
              themeColor: 'primary',
              icon: 'reload',
              show: true,
              displayType: 'both',
              title: 'Refresh Data',
              events: {
                onClick: refreshData,
              },
            },
          ],
        },
      ],
    },
  ],
  actionToolbar: [],
});
export default ToolBarItems;
