// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.divider {
  font-size: 20px;
  margin: 0 4px;
  vertical-align: middle;
  min-height: 1.5em;
}

.k-toolbar {
  align-items: center;
  z-index: 99;
}
.k-toolbar .toolbar-icon-wrapper {
  padding-top: 24.8px;
  margin-left: 10px;
  cursor: pointer;
}
.k-toolbar .toolbar-icon-wrapper .toolbar-setting-selector {
  font-size: 24px;
}

@keyframes fadeInOpacity {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}
.toolbar-grid-wrapper,
.toolbar-card-wrapper {
  max-height: 0;
  opacity: 0;
  transition: all 0.2s linear;
  position: relative;
  top: -100px;
  z-index: 1;
  margin: 0;
  padding-right: 14px;
  padding-bottom: 14px;
  border-bottom: 1px solid rgba(0, 0, 0, 0.12);
}
.toolbar-grid-wrapper .filter,
.toolbar-card-wrapper .filter {
  display: flex;
  align-items: flex-end;
}
.toolbar-grid-wrapper .named-filters,
.toolbar-card-wrapper .named-filters {
  color: red;
}
.toolbar-grid-wrapper.toolbar-active,
.toolbar-card-wrapper.toolbar-active {
  margin-bottom: 20px;
  max-height: 200px;
  animation-name: fadeInOpacity;
  animation-iteration-count: 1;
  animation-timing-function: ease-in;
  animation-duration: 0.5s;
  opacity: 1;
  top: 0;
  padding: 0 1% 1% 1%;
  display: flex;
  align-items: flex-end;
}
.toolbar-grid-wrapper .k-floating-label-container,
.toolbar-card-wrapper .k-floating-label-container {
  width: 20%;
  margin-right: 14px;
}`, "",{"version":3,"sources":["webpack://./src/assets/scss/common/Menu.scss"],"names":[],"mappings":"AAAA;EACE,eAAA;EACA,aAAA;EACA,sBAAA;EACA,iBAAA;AACF;;AAGA;EACE,mBAAA;EACA,WAAA;AAAF;AACE;EACE,mBAAA;EACA,iBAAA;EACA,eAAA;AACJ;AAAI;EACE,eAAA;AAEN;;AAEA;EACE;IACE,UAAA;EACF;EACA;IACE,UAAA;EACF;AACF;AACA;;EAEE,aAAA;EACA,UAAA;EACA,2BAAA;EACA,kBAAA;EACA,WAAA;EACA,UAAA;EACA,SAAA;EACA,mBAAA;EACA,oBAAA;EACA,4CAAA;AACF;AAAE;;EACE,aAAA;EACA,qBAAA;AAGJ;AADE;;EACE,UAAA;AAIJ;AAFE;;EACE,mBAAA;EACA,iBAAA;EACA,6BAAA;EACA,4BAAA;EACA,kCAAA;EACA,wBAAA;EACA,UAAA;EACA,MAAA;EACA,mBAAA;EACA,aAAA;EACA,qBAAA;AAKJ;AAHE;;EACE,UAAA;EACA,kBAAA;AAMJ","sourcesContent":[".divider {\n  font-size: 20px;\n  margin: 0 4px;\n  vertical-align: middle;\n  min-height: 1.5em;\n}\n@import '../abstracts/variables';\n\n.k-toolbar {\n  align-items: center;\n  z-index: 99;\n  .toolbar-icon-wrapper {\n    padding-top: calc(1.55 * 16px);\n    margin-left: 10px;\n    cursor: pointer;\n    .toolbar-setting-selector {\n      font-size: 24px;\n    }\n  }\n}\n@keyframes fadeInOpacity {\n  0% {\n    opacity: 0;\n  }\n  100% {\n    opacity: 1;\n  }\n}\n.toolbar-grid-wrapper,\n.toolbar-card-wrapper {\n  max-height: 0;\n  opacity: 0;\n  transition: all 0.2s linear;\n  position: relative;\n  top: -100px;\n  z-index: 1;\n  margin: 0;\n  padding-right: 14px;\n  padding-bottom: 14px;\n  border-bottom: 1px solid $component-border;\n  .filter {\n    display: flex;\n    align-items: flex-end;\n  }\n  .named-filters {\n    color: red;\n  }\n  &.toolbar-active {\n    margin-bottom: 20px;\n    max-height: 200px;\n    animation-name: fadeInOpacity;\n    animation-iteration-count: 1;\n    animation-timing-function: ease-in;\n    animation-duration: 0.5s;\n    opacity: 1;\n    top: 0;\n    padding: 0 1% 1% 1%;\n    display: flex;\n    align-items: flex-end;\n  }\n  .k-floating-label-container {\n    width: 20%;\n    margin-right: 14px;\n  }\n}\n"],"sourceRoot":""}]);
// Exports
export default ___CSS_LOADER_EXPORT___;
