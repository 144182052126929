import { Button, ButtonGroup } from '@progress/kendo-react-buttons';
import { Dialog } from '@progress/kendo-react-dialogs';
import React from 'react';
import {
  TransformWrapper,
  TransformComponent,
  useControls,
} from 'react-zoom-pan-pinch';

/**
 * A component that displays a full size image that can be zoomed and also has controls
 * @component
 * @param {function} onClose - A callback function to close the popup.
 * @returns {JSX.Element} - ImagePinchToZoom Component
 */
const ImagePinchToZoom = ({
  images,
  index,
  alt,
  width,
  onClose,
  title,
  description,
}) => {
  const [selectedIndex, setSelectedIndex] = React.useState(index);

  /**
   * Controls for zoomed image to zoom in zoom out or reset
   * @returns {JSX.Element} - Controls
   */
  const Controls = () => {
    const { zoomIn, zoomOut, resetTransform } = useControls();
    return (
      <div className="zoom-toolbar">
        <ButtonGroup>
          <Button
            icon={'zoom-in'}
            fillMode="outline"
            themeColor="primary"
            className="action-button"
            onClick={() => {
              zoomIn();
            }}
          >
            Zoom In
          </Button>
          <Button
            icon={'zoom-out'}
            fillMode="outline"
            themeColor="primary"
            className="action-button"
            onClick={() => {
              zoomOut();
            }}
          >
            Zoom Out
          </Button>
          <Button
            icon={'reset'}
            fillMode="outline"
            themeColor="primary"
            className="action-button"
            onClick={() => {
              resetTransform();
            }}
          >
            Reset
          </Button>
          <Button
            icon={'arrow-chevron-left'}
            fillMode="outline"
            themeColor="primary"
            className="action-button"
            disabled={parseInt(selectedIndex) <= 0}
            onClick={() => {
              setSelectedIndex(
                selectedIndex === 0
                  ? selectedIndex
                  : parseInt(selectedIndex) - 1
              );
            }}
          />
          <Button
            icon={'arrow-chevron-right'}
            fillMode="outline"
            themeColor="primary"
            className="action-button"
            disabled={
              images?.length === 1 ||
              parseInt(selectedIndex) + 1 === images?.length
            }
            onClick={() => {
              setSelectedIndex(
                selectedIndex === images.length
                  ? selectedIndex
                  : parseInt(selectedIndex) + 1
              );
            }}
          />
        </ButtonGroup>
      </div>
    );
  };

  /**
   * Custom Title Bar for Zoom Pinch Im view popup
   * @returns
   */
  const CustomTitleBar = () => (
    <div
      className="custom-title"
      style={{
        fontSize: '18px',
        lineHeight: '1.3em',
      }}
    >
      <div className="title">
        <strong>{title}</strong>
        {description ? <div className="description"> {description}</div> : ''}
      </div>
      <div className="resize-button">
        <Button
          icon={'minimize'}
          fillMode={'flat'}
          className="k-window-titlebar-action k-dialog-titlebar-action"
          onClick={onClose}
        />
      </div>
    </div>
  );
  return (
    <Dialog
      title={title ? <CustomTitleBar /> : 'Quick View'}
      className={'zoom-popup zoom-pinch-popup'}
      onClose={onClose}
    >
      <TransformWrapper>
        <Controls />
        <TransformComponent>
          <img
            src={
              images[selectedIndex].ImageBlobType +
              images[selectedIndex].ImageBlob
            }
            alt={alt}
            width={width}
            className={'zoom-image'}
          />
        </TransformComponent>
      </TransformWrapper>
    </Dialog>
  );
};
export default ImagePinchToZoom;
