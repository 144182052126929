// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.field-wrapper-description {
  position: relative;
}
.field-wrapper-description .open-editor-icon {
  right: 23px !important;
  top: 56px !important;
}
.field-wrapper-description .open-editor-icon .icon-full-screen {
  font-size: 20px;
  color: rgba(128, 128, 128, 0.7);
}
.field-wrapper-description textarea {
  padding-right: 35px !important;
}

.field-description-main {
  background-color: white;
}
.field-description-main textarea {
  height: 70vh;
}
.field-description-main .k-card-header {
  padding: 10px;
}
.field-description-main .card-title-secondary {
  margin: 0;
  font-size: 24px;
  color: #354052;
  font-family: Roboto, "Helvetica Neue", sans-serif;
}
.field-description-main .k-card-body {
  padding-block: 8px;
  padding-inline: 8px;
}
.field-description-main .lcs-action {
  margin-top: 10px;
}`, "",{"version":3,"sources":["webpack://./src/Modules/Core/Components/DescriptionTextArea/DescriptionTextArea.scss"],"names":[],"mappings":"AAAA;EACE,kBAAA;AACF;AAAE;EACE,sBAAA;EACA,oBAAA;AAEJ;AADI;EACE,eAAA;EACA,+BAAA;AAGN;AAAE;EACE,8BAAA;AAEJ;;AACA;EACE,uBAAA;AAEF;AADE;EACE,YAAA;AAGJ;AADE;EACE,aAAA;AAGJ;AADE;EACE,SAAA;EACA,eAAA;EACA,cAAA;EACA,iDAAA;AAGJ;AADE;EACE,kBAAA;EACA,mBAAA;AAGJ;AADE;EACE,gBAAA;AAGJ","sourcesContent":[".field-wrapper-description {\n  position: relative;\n  .open-editor-icon {\n    right: 23px !important;\n    top: 56px !important;\n    .icon-full-screen {\n      font-size: 20px;\n      color: rgb(128 128 128 / 70%);\n    }\n  }\n  textarea {\n    padding-right: 35px !important;\n  }\n}\n.field-description-main {\n  background-color: white;\n  textarea {\n    height: 70vh;\n  }\n  .k-card-header {\n    padding: 10px;\n  }\n  .card-title-secondary {\n    margin: 0;\n    font-size: 24px;\n    color: #354052;\n    font-family: Roboto, \"Helvetica Neue\", sans-serif;\n  }\n  .k-card-body {\n    padding-block: 8px;\n    padding-inline: 8px;\n  }\n  .lcs-action {\n    margin-top: 10px;\n  }\n}\n"],"sourceRoot":""}]);
// Exports
export default ___CSS_LOADER_EXPORT___;
