import React from 'react';
import { Chip } from '@progress/kendo-react-buttons';
import moment from 'moment';
import './AppliedFilters.scss';
/**
 * Handle Applied Filter For Mobile Cards
 * @returns {JSX.Element} The AppliedFilters component
 */
const AppliedFilters = ({
  filter,
  setFilter,
  filterSlide,
  setDateRangeFilter,
  setFilterName,
  setGridFilterName,
  gridFilterName,
  dataColumns,
}) => {
  /**
   * Remove a filter
   * @param {*} field
   */
  const onRemove = (field) => {
    const newFilters = filter.filters.filter(
      (filterValue) => filterValue.field !== field
    );
    setFilter({ ...filter, filters: newFilters });
    if (setDateRangeFilter) setDateRangeFilter(newFilters);
    if (gridFilterName?.FilterName?.length) {
      setFilterName({});
      setGridFilterName({});
    }
  };

  /**
   * Get Field Name
   * @param {*} field
   * @returns
   */
  const getFieldName = (field) =>
    dataColumns?.find((f) => f.field === field)?.title ?? field;

  /**
   * Get Field Value
   */
  const getFieldValue = (field, value) => {
    const columnField = dataColumns?.find((f) => f.field === field) ?? {};
    let valueText = value;
    if (Array.isArray(value) && columnField?.type !== 'dateRange') {
      valueText = value?.length > 1 ? `Multiple` : `${value[0]?.value}`;
    } else if (
      value instanceof Date &&
      !isNaN(value.getTime()) &&
      columnField?.type !== 'dateRange'
    ) {
      valueText = moment(value).format('YYYY-MM-DD');
    } else if (columnField?.type === 'dateRange') {
      // Filter for the specified field
      const dateFilters = filter?.filters?.filter((f) => f.field === field);

      // Sort the filters: 'lt' first, then 'gt'
      const sortedFilters = dateFilters?.sort((a, b) => {
        if (a.operator === 'lte' && b.operator === 'gte') return 1;
        if (a.operator === 'gte' && b.operator === 'lte') return -1;
        return 0; // Keep original order for equal operators
      });

      // Map and format the values
      valueText = `${sortedFilters
        ?.map((f) => moment(f.value).format('YYYY-MM-DD'))
        .join(' - ')}`;
    }
    return valueText;
  };

  /**
   * remove date range filters
   * @param {*} filters
   * @returns
   */
  const getDistinctFilters = (filters) =>
    filters?.reduce((accumulator, currentItem) => {
      const existingItem = accumulator?.find(
        (item) => item.field === currentItem.field
      );
      if (!existingItem) {
        accumulator.push(currentItem);
      }
      return accumulator;
    }, []);
  return (
    <div>
      <div className="applied-filters">
        <span className="k-icon k-font-icon k-i-filter" role="presentation" />
        <div className="filters-list">
          {getDistinctFilters(filter?.filters)?.map((filterName, index) => (
            <Chip
              className="chips-list"
              key={index}
              value={filterName?.value}
              removable={true}
              onRemove={(e) => onRemove(filterName.field)}
            >
              <span
                onClick={filterSlide}
                className="filter-content"
                key={index}
              >
                <div className="filter">
                  <div>
                    <div>
                      <span className="field-name">
                        {getFieldName(filterName?.field)}
                      </span>
                    </div>
                    <div>
                      {getFieldValue(filterName?.field, filterName?.value)}{' '}
                      {Array.isArray(filterName?.value) &&
                        filterName?.value?.length > 1 && (
                          <>
                            {'('}
                            {filterName?.value?.length}
                            {')'}
                          </>
                        )}
                    </div>
                  </div>
                </div>
              </span>
            </Chip>
          ))}
        </div>
      </div>
    </div>
  );
};
export default AppliedFilters;
