import * as React from 'react';
import './MessageAlert.scss';

const MessageAlert = ({ type, message, className = '', isIcon, iconName }) => (
  <div className={`alert alert-${type}${className}`} role="alert">
    {isIcon && <span className={`k-icon k-font-icon k-i-${iconName}`} />}
    {message}
  </div>
);

export default MessageAlert;
