import {
  setCacheConfig,
  getCacheConfig,
} from '../../../../../Utils/Storage/handleCacheConfig';

const getNameFilterKey = (moduleName, dataTableName) =>
  `${moduleName}.${dataTableName}.NamedFilter`;
/**
 * get data from db and return array
 * @param {*} entry
 * @returns {Array} returns Array
 */
export const getDatafromDB = async (moduleName, dataTableName) => {
  const key = getNameFilterKey(moduleName, dataTableName);
  const data = await getCacheConfig(key);
  return data;
};

/**
 * used to search data from dexie table
 * @param {String} moduleName
 * @param {Object} dataSet // {key: value,}
 * @returns {Array} returns filtered data array from index db
 */
export const searchDatafromDB = async (moduleName, dataTableName, dataSet) => {
  const key = getNameFilterKey(moduleName, dataTableName);
  const getFilterData = await getCacheConfig(key);
  const result = getFilterData?.find(
    (object) => object.FilterName === dataSet?.FilterName
  );
  return result;
};

/**
 * Map the data into the array and return the updated array
 * @param {*} entry
 * @returns {Array} returns updated array
 */
export const bulkUpdateInDB = async (moduleName, entry, dataTableName) => {
  const key = getNameFilterKey(moduleName, dataTableName);
  setCacheConfig(key, entry);
};

/**
 * delete Data from Index DB
 * @param {*} entry
 * @param {*} moduleName
 */
export const deleteDataInDB = async (moduleName, filterName, dataTableName) => {
  const key = getNameFilterKey(moduleName, dataTableName);
  const getFilterData = await getCacheConfig(key);
  const result = getFilterData.filter((f) => f.FilterName !== filterName);
  setCacheConfig(key, result);
};
