import React from 'react';

import { buildNotification } from '../AppNotification/BaseNotification';
import GenerateNotification from '../AppNotification/GenerateNotification';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCopy } from '@fortawesome/free-solid-svg-icons';
import './CopyTextButton.scss';
import {
  NOTIFICATION_TYPES,
  EVENTS_DATA_TYPES,
} from '../../../../constants/eventDataTypes';
/**
 * CopyTextButton component for copying text on text field
 */
const CopyTextButton = ({ keyName, text }) => {
  const [isCopied, setIsCopied] = React.useState(false);
  /**
   * handleCopy
   */
  const handleCopy = async (event) => {
    event.stopPropagation(); // Prevent the click from bubbling up to the parent
    try {
      await navigator.clipboard.writeText(text);
      GenerateNotification(
        buildNotification({
          title: `${keyName} Copied Successfully!`,
          style: 'success',
        }),
        NOTIFICATION_TYPES.APP,
        EVENTS_DATA_TYPES.APPLICATION_NOTIFICATION
      );
      setIsCopied(true);
    } catch (err) {
      console.error('Failed to copy: ', err);
    }
  };
  return (
    <span
      onClick={handleCopy}
      className={`open-editor-icon ${isCopied ? 'copied-icon' : ''}`}
    >
      <FontAwesomeIcon
        className="icon-full-screen copy-icon k-mr-1"
        icon={faCopy}
      />
    </span>
  );
};

export default CopyTextButton;
