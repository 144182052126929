import React from 'react';
import ResizeHandler from '../Components/ResizeHandler/resizeHandler';
import { resizeWorker } from '../../../Utils/Layouts/resizeWorker';

const initialState = {
  networkStatus: true,
};

export const GlobalContext = React.createContext(initialState);

export const GlobalProvider = ({ children }) => {
  /**
   * Set Dimensions
   */
  const [dimensions, setDimensions] = React.useState();
  const [networkStatus, setNetworkStatus] = React.useState(true);

  /**
   * Device Information
   */
  const [deviceInfo, setDeviceInfo] = React.useState();

  React.useEffect(() => {
    ResizeHandler({ setDimensions, setDeviceInfo, resizeWorker });
    window.onload = onloadDimension();
    function onloadDimension() {
      if (dimensions === undefined) {
        setTimeout(() => {
          setDimensions({
            height: window.innerHeight,
            width: window.innerWidth,
          });
          setDeviceInfo(resizeWorker());
        }, 250);
      }
    }
  }, []);

  /**
   * Set flag of Network is Connected or not
   * @param {*} applicationState
   */
  function toggleNetworkConnectivity(applicationState) {
    setNetworkStatus(applicationState);
  }

  return (
    <GlobalContext.Provider
      value={{
        isNetworkConnected: networkStatus,
        toggleNetworkConnectivity,
        dimensions,
        deviceInfo,
      }}
    >
      {children}
    </GlobalContext.Provider>
  );
};
