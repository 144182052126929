import React from 'react';
import moment from 'moment';

/** This module contains utility functions for the application.
 * @module Utils
 */

/**
 * Serializes the response and returns the object.
 * @async
 * @function serializeResponse
 * @param {Response} response - The response object to be serialized.
 * @param {boolean} isServiceWorker - Boolean to check if it is a service worker.
 * @returns {Promise<any>} A Promise that resolves to the serialized object.
 */
export async function serializeResponse(response, isServiceWorker) {
  let serialized;
  if (isServiceWorker) serialized = response;
  else serialized = await response.json();
  return serialized;
}

/**
 * Returns a new response object containing serialized data.
 * @async
 * @function getCacheResponse
 * @param {Object} data - The data object to be serialized and added to the response.
 * @returns {Promise<Response>} A Promise that resolves to the new response object.
 */
export const getCacheResponse = async (data) =>
  new Response(JSON.stringify(data.body), data);

/**
 * The environment the application is running in.
 * @constant
 * @type {string}
 */
export const environment =
  process.env.APP_ENV !== 'Prod' ? process.env.APP_ENV : '';

/**
 * Replaces single quotes with double quotes and wraps property names in quotes.
 * @function replaceQuotes
 * @param {string} value - The value to be processed.
 * @returns {string} The processed string.
 */
export const replaceQuotes = (value) => {
  let jsonObj = value.replace(/'/g, '"');
  return jsonObj.replace(/([{,])(\s*)([A-Za-z0-9_\\-]+?)\s*:/g, '$1"$3":');
};

/**
 * Parses a value and returns null if the value is empty or null space.
 * @function parseEmptyOrNullSpaceValue
 * @param {any} value - The value to be parsed.
 * @returns {any} The parsed value or null.
 */
export const parseEmptyOrNullSpaceValue = (value) => {
  if (!isEmptyOrNullSpace(value)) return value;
  return null;
};

/**
 * Formats a currency string value using the USD currency.
 * @function parseCurrencyString
 * @param {string} value - The value to be formatted.
 * @returns {string} The formatted currency string or null.
 */
export const parseCurrencyString = (value) => {
  if (!isEmptyOrNullSpace(value?.toString())) {
    const format = { style: 'currency', currency: 'USD' };
    const currencyFormat = new Intl.NumberFormat('en-US', format);

    return currencyFormat.format(value?.toString());
  }
  return null;
};

/**
 * Formats a number
 * @function parseCurrencyString
 * @param {int} value - The value to be converted.
 * @returns {int} The formatted number or null.
 */
export const parseNumber = (value) => {
  if (value) {
    return parseInt(value);
  }
  return null;
};

/**
 * Formats a datetime value to a specified format.
 * @function formatDateTime
 * @param {string} value - The datetime value to be formatted.
 * @param {string} format - The format for the datetime value.
 * @returns {string} The formatted datetime string or null.
 */
export const formatDateTime = (value, format) => {
  if (!isEmptyOrNullSpace(value)) {
    return moment(value).format(format ?? 'MM/DD/YYYY');
  }
  return null;
};

/**
 * Ensure that passed date is always a Date
 * @param {*} value
 * @returns
 */
export const ensureDate = (value) => {
  // If x is already a Date, return it as is
  if (value instanceof Date) {
    return value;
  }

  // If x is a string, convert it to a Date
  if (typeof value === 'string') {
    return new Date(moment(x).format('YYYY-MM-DDT00:00:00.000'));
  }

  // Handle invalid cases, return an invalid date if x is not a string or Date
  return ''; // Return an invalid Date object
};
/**
 * check If Value is Empty or has null
 * @param {string} value - The value to be checked.
 * @returns {Boolean} returns if value is null
 */
export const isEmptyOrNullSpace = (value) => {
  if (value && typeof value === 'string') {
    if (value?.trim()) return false;
  }
  return true;
};

/**
 * Returns the previous value of a given variable using React hooks.
 * @param {*} value - The variable to get the previous value for.
 * @returns {*} The previous value of the given variable.
 */
export const usePrev = (value) => {
  const ref = React.useRef();

  React.useEffect(() => {
    ref.current = value;
  }, [value]);

  return ref.current;
};

/**
 * parse Date for Kendo
 * @param {*} value
 */
export const parseDateForKendo = (value) =>
  value ? moment(value)?.format('YYYY-MM-DDT00:00:00.000') : null;

/**
 * get Updated Tab Name , Show Tab For which fields are updated
 * @param {*} fieldName
 * @returns
 */
export const getUpdatedTabName = (fieldName, tabsList) => {
  for (const tab of tabsList) {
    if (tab.fields.includes(fieldName)) {
      return tab.name;
    }
  }
  return null;
};

/**
 * check if tab values are updated
 * @param {*} fieldName
 * @returns
 */
export const tabValueChanged = (updatedFields, tab) => {
  for (const key in updatedFields) {
    if (tab.fields.includes(key)) {
      return true;
    }
  }
  return false;
};
/**
 * update query string for filters
 * @param {*} e json object
 * @returns {json} Array
 */
export const getQueryStr = (e) =>
  JSON.stringify(e).replace(/"([^"]+)":/g, '$1:');

/**
 * Remove the column name from
 * @param {*} columnName
 * @param {*} columnsArray
 * @returns
 */
export const removeColumns = ({ columnNames, columnsArray }) =>
  columnsArray.map((item) => {
    const updatedItem = { ...item };
    columnNames.forEach((columnName) => {
      delete updatedItem[columnName];
    });
    return updatedItem;
  });

/**
 * check if id is a valid unique identifier or not
 * @param {*} id
 * @returns
 */
export const isUID = (id) => {
  const uidRegex = /^[A-Za-z0-9_-]{8,}$/;
  return uidRegex.test(id);
};

/**
 * get File Extension from base 64 string
 * @param {*} base64String
 * @returns
 */
export const getFileExtension = (base64String) => {
  const base64StringWithoutData = base64String.replace(
    /data:[^;]+;base64,/,
    ''
  );
  const decodedString = atob(base64StringWithoutData);
  const match = /(?:\.([^.]+))?$/.exec(decodedString);
  return match ? match[1] : null;
};

/**
 * get value for an object from its key
 * @param {*} obj
 * @param {*} key
 * @returns
 */
export const getValueByKey = (obj, key) => {
  const keys = key.split('.');

  let value = obj;

  for (let i = 0; i < keys.length; i += 1) {
    if (value && Object.prototype.hasOwnProperty.call(value, keys[i])) {
      value = value[keys[i]];
    } else {
      value = undefined;

      break;
    }
  }

  return value;
};

/**
 * get Updated Fields
 * @param {*} original
 * @param {*} updated
 * @returns
 */
export const getUpdatedFields = (original, updated) => {
  const updatedFields = {};

  for (const key in updated) {
    if (
      JSON.stringify(original ? original[key] : '') !==
      JSON.stringify(updated[key])
    ) {
      updatedFields[key] = updated[key];
    }
  }

  return updatedFields;
};
/**
 * formatDate
 * @param  string
 * @returns string
 */
export const formatDate = (dateStr) => {
  // Create a new Date object from the string
  const date = new Date(dateStr);

  // Get the day, month, and year
  const day = date.getDate();
  const month = date.toLocaleString('default', { month: 'short' });
  const year = date.getFullYear();

  // Format the date
  const formattedDate = `${day} ${month} ${year}`;
  return formattedDate;
};
/**
 * convertToLocalDate
 * @param  string
 * @returns string
 */
export const convertToLocalDate = (inputDateStr) => {
  if (!inputDateStr) {
    return '';
  }

  // Use template literal to append 'Z'
  let dateStr = inputDateStr.endsWith('Z') ? inputDateStr : `${inputDateStr}Z`; // Append 'Z' to signify UTC

  // Create a Date object from the UTC date string
  const date = new Date(dateStr);

  // Extract local date and time parts
  const day = String(date.getDate()).padStart(2, '0');
  const month = String(date.getMonth() + 1).padStart(2, '0'); // Months are zero-indexed
  const year = date.getFullYear();

  // Return the formatted date as MM-DD-YYYY
  return `${month}-${day}-${year}`;
};

/**
 * formatDateStandard
 * @param  string
 * @returns string
 */
export const formatDateStandard = (dateStr) => {
  if (!dateStr) {
    return '';
  }
  const date = new Date(dateStr);

  // Extract local date and time parts
  const day = String(date.getDate()).padStart(2, '0');
  const month = String(date.getMonth() + 1).padStart(2, '0'); // Months are zero-indexed
  const year = date.getFullYear();

  // Return the formatted date as DD-MM-YYYY
  return `${month}-${day}-${year}`;
};

/**
 * extractNumberFromString
 * @param  string
 * @returns int
 */
export const extractNumberFromString = (str) => {
  const match = str.match(/\d+/); // Match one or more digits
  return match ? match[0] : null; // Return the matched number or null if no match
};

/**
 * isComponentUpdate
 * @param prevProps
 * @param nextProps
 */
export const isComponentUpdate = (prevProps, nextProps) =>
  prevProps.shouldUpdate === nextProps.shouldUpdate;

/**
 * getClientInfo
 */
export const getClientInfo = () => ({
  // IP Address cannot be directly obtained via client-side JS
  // You'll need to use a server-side method or an external API
  IpAddress: null,
  UserAgent: navigator.userAgent,

  // Os: navigator.platform,
  Os: (() => {
    const userAgent = navigator.userAgent.toLowerCase();
    if (userAgent.includes('win')) return 'Windows';
    if (userAgent.includes('mac')) return 'MacOS';
    if (userAgent.includes('linux')) return 'Linux';
    if (userAgent.includes('android')) return 'Android';
    if (userAgent.includes('iphone') || userAgent.includes('ipad'))
      return 'iOS';
    return 'Unknown';
  })(),
  Device: null,
  RequestBrowser: (() => {
    const agent = navigator.userAgent.toLowerCase();
    if (agent.includes('chrome')) return 'Chrome';
    if (agent.includes('firefox')) return 'Firefox';
    if (agent.includes('safari')) return 'Safari';
    if (agent.includes('edge')) return 'Edge';
    if (agent.includes('opera')) return 'Opera';
    if (agent.includes('trident') || agent.includes('msie'))
      return 'Internet Explorer';
    return 'Unknown';
  })(),
});
