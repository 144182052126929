/**
 * Represents the base notification object.
 * @typedef {object} BaseNotification
 * @property {string} notificationId - The unique identifier of the notification.
 * @property {string} style - The style of the notification (e.g., 'info').
 * @property {boolean} icon - Indicates whether the notification should display an icon.
 * @property {boolean} closable - Indicates whether the notification can be closed.
 * @property {boolean} isRead - Indicates whether the notification has been read.
 * @property {string} title - The title of the notification.
 * @property {string} description - The description or content of the notification.
 * @property {boolean} isWebPush - Indicates whether the notification is for web push.
 * @property {number} timeout - The timeout duration for the notification (in milliseconds).
 * @property {Date} time - The timestamp of when the notification was created.
 * @property {boolean} isNotify - Indicates whether the notification should be displayed.
 * @property {string} display - The display configuration for the notification.
 * @property {Function} callBack - The callback function to be executed when the notification is interacted with.
 */

/**
 * Represents the display configuration for the notification.
 * @typedef {object} DisplayNotification
 * @property {string} SHOW - The notification should be shown.
 * @property {string} HIDDEN - The notification should be hidden.
 */

/**
 * The base notification object.
 * @type {BaseNotification}
 */

import { v4 as uuidv4 } from 'uuid';
import { DisplayNotification } from '../../../../constants/applicationConstants';

export const BaseNotification = {
  notificationId: uuidv4(),
  style: 'info',
  icon: true,
  closable: true,
  isRead: false,
  title: '',
  description: '',
  isWebPush: false,
  timeout: 7000,
  time: new Date(),
  isNotify: true,
  display: DisplayNotification.SHOW,
  callBack: () => {},
};

/**
 * Override base Notification object based on new definition
 * Builds a new notification object with a generated UUID.
 * @param {object} notification - The notification object to build.
 * @returns {object} The built notification object with a generated UUID.
 */
export const buildNotification = (notification) => {
  let notificationObj = { ...notification, notificationId: uuidv4() };
  return { ...BaseNotification, ...notificationObj };
};
