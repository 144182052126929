/*
  NotificationList component renders a list of notifications based on provided data.
  It filters out notifications with empty titles and displays them based on the specified display criteria.

  Props:
  - notifications: An array of notification objects to be rendered.
  - counts: A count of notification to be rendered.
  - setNotifications: React state to set the render notifications.
  - setIsDismissed: If Notifications wants to  be dismissed.

*/

import React from 'react';
import {
  Card,
  CardBody,
  CardHeader,
  CardTitle,
} from '@progress/kendo-react-layout';
import { updatePanelAllNotifications } from './NotificationHandler';
import { closeNotification } from '../../../../Utils/Notifications/notificationUtils';
import { DisplayNotification } from '../../../../constants/applicationConstants';
/**
 * Show List of Notifications
 * @param {*} props
 * @returns {React.ReactElement} The List Of Notifications
 */
const NotificationList = (props) => {
  // Notification State and counts props.
  let { counts, notifications, setNotifications, setIsDismissed, setCounts } =
    props;

  /**
   * Dismiss All notifications
   */
  const dismissAll = () => {
    setNotifications([]);
    updatePanelAllNotifications();
    setCounts(0);
    setIsDismissed(true);
  };

  /**
   * On close of specific notification
   * @param {*} notification
   */
  const onClose = (notificationId) => {
    setNotifications(closeNotification(notifications, notificationId));
  };
  return (
    <>
      <div className="notification-detail">
        <div className="notification-title">
          <h6>Notifications</h6>
        </div>
        {counts !== 0 && (
          <div className="notification-dismiss-all">
            <a
              onClick={dismissAll}
              type="button"
              className="notification-dismiss-name"
            >
              Dismiss All
            </a>
          </div>
        )}
      </div>
      <div className="top-nav-list">
        <div className="app-notification-list">
          {counts === 0 && (
            <div className="notifications-empty">
              <div className="nodata-content">
                No new notifications from this session
              </div>
            </div>
          )}
          {notifications &&
            notifications
              .filter(
                ({ title }) => title !== '' // Filter out notifications with empty titles
              )
              .map(
                (
                  {
                    title,
                    style,
                    description,
                    notificationId,
                    isRead,
                    callBack,
                    count,
                    display,
                  },
                  i
                ) =>
                  (display === DisplayNotification.SHOW ||
                    DisplayNotification.SILENT) && (
                    <Card
                      key={i}
                      className={`app-notification-${style} ${
                        isRead ? 'read' : ''
                      }`}
                      onClick={() => {
                        let callbackFun = callBack;
                        if (typeof callbackFun === 'string') {
                          // eslint-disable-next-line no-eval
                          callbackFun = eval(`(${callbackFun.toString()})`);
                        }
                        return callbackFun ? callbackFun() : undefined;
                      }}
                    >
                      <div className="notification-icon">
                        <span
                          className={`k-icon sis-ico-${style}`}
                          alt={style}
                        />
                      </div>
                      <div
                        className={`notification-content k-notification-content ${
                          callBack ? 'isClickable' : ''
                        }`}
                      >
                        <CardHeader>
                          <CardTitle>
                            {title} {count > 0 && <span>({count})</span>}
                          </CardTitle>
                        </CardHeader>
                        <CardBody>
                          <span
                            className="notification-body"
                            dangerouslySetInnerHTML={{ __html: description }}
                          />
                        </CardBody>
                      </div>
                      <div className="notification-close-icon">
                        <span
                          key={notificationId}
                          onClick={() => onClose(notificationId)}
                          className="k-icon k-i-close"
                        />
                      </div>
                    </Card>
                  )
              )

              .reverse()}
        </div>
      </div>
    </>
  );
};
export default NotificationList;
