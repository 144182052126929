/**
 * Utility function to reorder grid data based on drag-and-drop action.
 * @param {Array} gridData - The current data of the grid.
 * @param {Object} activeItem - The item that is currently being dragged.
 * @param {Object} dataItem - The item where the dragged item is dropped.
 * @param {String} dataItemKey - The key to identify grid items (e.g., id).
 * @param {String} orderSeqKey - The key for the order sequence field.
 * @param {String} direction - The direction where the item is dropped ('before' or 'after').
 * @param {Object} isGridSortable - Object that may contain sendPartialData flag.
 * @returns {{ reorderedData: Array, updatedAffectedItems: Array }} - Returns the reordered data and affected items.
 */
export const reorderGridData = (
  gridData,
  activeItem,
  dataItem,
  dataItemKey,
  orderSeqKey,
  direction,
  isGridSortable
) => {
  const sendPartialData = isGridSortable?.sendPartialData ?? false; // Default to false if not available

  if (
    !activeItem ||
    !dataItem ||
    activeItem[dataItemKey] === dataItem[dataItemKey] ||
    direction === null
  ) {
    return { reorderedData: gridData, updatedAffectedItems: [] };
  }

  // Create a copy of the grid data
  const reorderedData = JSON.parse(JSON.stringify(gridData)); // Deep copy for manipulation
  const prevIndex = reorderedData.findIndex(
    (item) => item[dataItemKey] === activeItem[dataItemKey]
  );

  if (prevIndex === -1) {
    return { reorderedData: gridData, updatedAffectedItems: [] };
  }

  let nextIndex =
    reorderedData.findIndex(
      (item) => item[dataItemKey] === dataItem[dataItemKey]
    ) + (direction === 'before' ? -1 : 0);

  if (prevIndex > nextIndex) nextIndex += 1;
  nextIndex = Math.max(0, Math.min(reorderedData.length - 1, nextIndex));

  // Remove the active item from its original position
  const [movedItem] = reorderedData.splice(prevIndex, 1);

  // Insert the active item at its new position
  reorderedData.splice(nextIndex, 0, movedItem);

  // Determine the items to return based on sendPartialData flag
  let updatedAffectedItems;
  if (sendPartialData) {
    updatedAffectedItems = reorderedData.slice(
      Math.min(prevIndex, nextIndex),
      Math.max(prevIndex, nextIndex) + 1
    );
  } else {
    updatedAffectedItems = reorderedData;
  }

  // Assign sequence numbers after deciding what to return
  updatedAffectedItems.forEach((item, index) => {
    item[orderSeqKey] = index + 1; // Assign sequential values starting from 1
  });

  // Return the reordered data and the updated affected items or full data
  return { reorderedData, updatedAffectedItems };
};

export default reorderGridData;
