import React from 'react';
import { orderBy } from '@progress/kendo-data-query';
import { Tooltip } from '@progress/kendo-react-tooltip';

import '../../../../../assets/scss/common/List.scss';
import '../../../../../assets/scss/common/Toolbar.scss';

import { Button } from '@progress/kendo-react-buttons';
import {
  RowStatus,
  DEFAULT_OFFSET,
} from '../../../../../constants/applicationConstants';
import { save, isValid } from '../../../../../Utils/DataTables/actionHandler';

import Loader from '../../Loader/Loader';
import ToolBar from '../../ToolBar/ToolBar';
import { errorGenerator } from '../../../../../Utils/Notifications/notificationUtils';

import {
  getCacheConfig,
  setCacheConfig,
} from '../../../../../Utils/Storage/handleCacheConfig';

import { getDatafromDB } from '../Service/namedFiltersService';
import Dialogue from '../../Dialogue/Dialogue';
import AppliedFilters from '../Filter/AppliedFilters';
import { GlobalContext } from '../../../Context/GlobalContextState';

const Grid = React.lazy(() => import('../Grid/Grid'));

/**
 * Handle Data Grid, DataGridToolbar and Cards
 * Call the API based on filter and sort change
 * @param {*} props
 * @returns {React.Component} returns Data List Component
 */
const List = (props) => {
  const {
    dataTableName,
    filterName,
    setNamedFilter,
    namedFilter,
    listQuery,
    listAPI,
    gridColumnsList,
    GRID_ID,
    gridColumnMethods,
    setIsRefresh,
    isRefresh,
    actionButtonsArray,
    Card,
    gridMethods,
    getToolbar,
    setSelectedState,
    filterType,
    setGridFilterName,
    gridFilterName,
    isFilterable,
    inlineEdit = true,
    show,
    dateRangefilter,
    setDateRangeFilter,
    updateDataItem,
    setCheckedDataItems,
    inEditMode,
    setActionButtonsArray,
    setInEditMode,
    isEditable = true,
    refreshGridData,
    isFavourite,
    isCardonMobileView,
    GridListView,
    noRecordText = '',
    selectable,
    lookupCheckedItems,
    editDataHandler,
    lookupSelectionMode,
    getTokensFromStorage,
    userAuthObj,
    dataColumns,
    setFilters,
    setSortHandler,
    orderSeqKey,
    setDraggableCallback,
    isGridSortable,
    defaultSort = [],
    convertFilters = false,
  } = props;
  const { idToken, accessToken } = getTokensFromStorage();

  const {
    state,
    handleSlide,
    gridView,
    dispatch,
    pageListChange,
    resetSettings,
    discard,
    cancel,
    exitEdit,
    setGridData,
    loading,
    pageChange,
    setInEdit,
    gridData,
    length,
    prevFilter,
    prevSort,
    setIsLoading,
    initDataList,
    setDataList,
    setLength,
    checkedDataItems,
    scrollHandler,
    rowLayoutConfigName,
    pageLengthConfigName,
    moduleName,
    filterSlide,
    apiKeyName,
    setFilterName,
    sliderAction,
    setSliderAction,
    showToolbar = true,
    newDataItem = {},
    originalList,
    isSimpleMode = false,
    isSelectable,
    rowLayouts,
    pageSizes,
  } = props;
  const listController = new AbortController();
  const { isNetworkConnected } = React.useContext(GlobalContext);
  /**
   * used to handle draggable switch
   */
  const [draggableToggle, setDraggableToggle] = React.useState(false);
  /**
   * used to show title of  dialog box
   */
  const [title, setTitle] = React.useState('');
  /**
   * used to show messages in dialog box
   */
  const [confirmationMessage, setConfirmationMessage] = React.useState('');

  /**
   * used to show info and error message in dialog box
   */
  const [showDialogue, setShowDialogue] = React.useState(false);

  /**
   * set action add, edit and delete and perform action according to data
   */
  const [actionName, setActionName] = React.useState('');

  /**
   * list Named Filters
   */
  const listNamedFilters = async () => {
    const getFilterData = await getDatafromDB(moduleName, dataTableName);
    if (getFilterData) {
      setNamedFilter(getFilterData);
    }
  };
  /**
   *  Show Confirmation Dialogue based on Action Type
   * @param {*} action
   * @param {*} value
   */
  const toggleDialogue = (action, value) => {
    if (value) {
      setTitle('Confirmation');
      switch (action) {
        case 'Delete':
          setConfirmationMessage(
            props?.deleteMsg ??
              `Confirm that you want to delete selected items.`
          );
          break;
        default:
          break;
      }
      setActionName(action);
    }
    setShowDialogue(value);
  };

  /**
   * this method is used to handle the isFavourite property of grid item
   * @param {Object} dataItem
   */
  const handleFavouriteArray = async ({ dataItem }) => {
    const dataItemKeyValue = dataItem[GRID_ID] ?? '';
    /**
     * update the selected item isFavourite property to true
     * if it's already true set it to false
     */
    const updatedData = gridData?.map((item) => {
      if (
        item[GRID_ID] === dataItemKeyValue &&
        (!item?.isFavourite || item?.isFavourite === false)
      ) {
        item.isFavourite = true;
      } else if (
        item[GRID_ID] === dataItemKeyValue &&
        item?.isFavourite === true
      ) {
        item.isFavourite = false;
      }
      return item;
    });
    setGridData(updatedData);

    /**
     * update grid data in localstorage and index db
     */
    const getFilterData = await getCacheConfig(
      `${moduleName}.${dataTableName}`
    );
    const updatedDbData = getFilterData?.map((item) => {
      if (
        item[GRID_ID] === dataItemKeyValue &&
        (!item?.isFavourite || item?.isFavourite === false)
      ) {
        item.isFavourite = true;
      } else if (
        item[GRID_ID] === dataItemKeyValue &&
        item?.isFavourite === true
      ) {
        item.isFavourite = false;
      }
      return item;
    });
    setCacheConfig(`${moduleName}.${dataTableName}`, updatedDbData);
  };
  /**
   * Get Data from API
   */
  const listApi = async (isReload = false) => {
    var isFilterChanged = false;
    var isSortChanged = false;

    if (typeof idToken !== 'undefined' && idToken !== null) {
      if (listQuery) {
        // check if filter updated
        isFilterChanged =
          JSON.stringify(prevFilter) !== JSON.stringify(props.filter?.filters)
            ? true
            : false;

        // check if sort updated
        isSortChanged =
          JSON.stringify(prevSort) !== JSON.stringify(props?.sort)
            ? true
            : false;
        const filter = setFilters({
          filters: props.filter?.filters ?? [],
          moduleName: dataTableName,
          hasDefaultFilters: true,
          convertFilters,
          dataColumns,
        });

        const sort = setSortHandler({
          sorts: props?.sort ?? [],
          dataColumns,
          dataTableName,
        });
        // Save Filter And Sort IN Dexie DB only When Value is updated
        const getFilterData = await getCacheConfig(
          `${moduleName}.${dataTableName}.Filter`
        );
        if (!getFilterData || isSortChanged || isFilterChanged) {
          let defaultFilter = props.filter?.filters ?? [];
          if (props.filterName?.FilterName?.length > 0) {
            defaultFilter = getFilterData?.filters;
          }
          let tabData = {
            ...getFilterData,
            ConfigId: `${moduleName}.${dataTableName}.Filter`,
            filters: defaultFilter,
            SelectedNamedFilter: props?.filterName?.FilterName,
            sort: props?.sort ?? [],
            GridColumnOrder: '',
            column_resize: '',
          };

          setCacheConfig(`${moduleName}.${dataTableName}.Filter`, tabData);
        }
        setIsLoading(true);
        if (isFilterChanged || isSortChanged || isRefresh) state.page.skip = DEFAULT_OFFSET;

        const data = await listAPI({
          accessToken,
          listQuery,
          limit: state.page.take,
          offset: state.page.skip,
          filter,
          sort:
            props?.sort?.length > 0
              ? sort
              : setSortHandler({
                  sorts: defaultSort ?? [],
                  dataColumns,
                  dataTableName,
                }),
          moduleName: dataTableName,
          isServiceWorker: false,
          listController,
        });
        if (data?.isError) {
          const { message } = data;
          errorGenerator({ message });
          setDataList([], dataTableName);
        } else if (isFilterChanged || isSortChanged || isReload) {
          if (data && Object.prototype.hasOwnProperty.call(data, apiKeyName)) {
            initDataList(data[apiKeyName] ?? [], dataTableName);
          } else {
            initDataList([], dataTableName);
          }
        } else if (data[apiKeyName]) {
          if (data && Object.prototype.hasOwnProperty.call(data, apiKeyName)) {
            setDataList(data[apiKeyName] ?? [], dataTableName);
          } else {
            setDataList([], dataTableName);
          }
        }
        /** Set Total Count */
        setLength(parseInt(data?.TotalCount ?? 0));
        setTimeout(() => {
          setIsLoading(false);
        }, 100);
      }
    }
  };

  /**
   * Check if filter is changed then it will call the API after some delay so user can write the complete word
   */
  React.useEffect(() => {
    let isFilterChanged =
      JSON.stringify(prevFilter) !== JSON.stringify(props.filter?.filters ?? [])
        ? true
        : false;
    let timeOutId;
    // Check if filters have changed
    if (props.filter ?? []) {
      if (isFilterChanged) {
        // Set timeout with 500ms delay
        timeOutId = setTimeout(() => listApi(), 500);
      } else {
        listApi();
      }
    }

    /**
     * Reset Timeout and Abort API call when component is unmount.
     */
    return () => {
      clearTimeout(timeOutId);
      if (isFilterChanged) {
        listController.abort();
      }
    };
  }, [props.filter, state.page, props.sort]);

  React.useEffect(() => {
    props.setInEditMode(state.inEdit);
  }, [state.inEdit]);

  /** @event
   * list Named Filters on add/edit
   */
  React.useEffect(() => {
    listNamedFilters();
  }, [filterName]);

  const [toolbar, setToolbar] = React.useState({});

  React.useEffect(() => {
    if (isRefresh) {
      listApi(isRefresh);
      setIsRefresh(false);
    }
  }, [isRefresh]);

  /**
   * remove row
   * @param {*} dataItem
   */
  const removeItem = () => {
    if (checkedDataItems.length > 0) {
      const newData = gridData
        .map((item) =>
          checkedDataItems?.some(
            (dataItem) => dataItem[GRID_ID] === item[GRID_ID]
          )
            ? {
                ...item,
                inEdit: false,
                rowStatus:
                  item.rowStatus === RowStatus.New
                    ? RowStatus.Discard
                    : RowStatus.Deleted,
              }
            : item
        )
        ?.filter((f) => f.rowStatus !== RowStatus.Discard);
      initDataList(newData);
      setInEditMode(false);
      setSelectedState([]);
      updateDataItem({
        data: newData,
        tableName: dataTableName,
        isChanged: true,
      });
    }
  };
  React.useEffect(() => {
    /**
     * Create Action Buttons Array based on the Selected Items
     */
    if (typeof isSimpleMode !== 'undefined') {
      if (checkedDataItems.length > 0 && !isSimpleMode) {
        const tempActionButtonsArray = [];
        tempActionButtonsArray.push(
          {
            type: 'Button',
            fillMode: 'outline',
            themeColor: 'primary',
            show:
              (!inEditMode ||
                checkedDataItems.filter(
                  (data) => (data?.inEdit ?? false) === true
                )?.length <= 0) &&
              isEditable,
            displayType: 'grid',
            className: `action-button Edit`,
            icon: `edit`,
            title: 'Edit',
            events: {
              onClick: enterEdit,
            },
          },
          {
            type: 'Button',
            fillMode: 'outline',
            themeColor: 'primary',
            title: 'Delete',
            show:
              !inEditMode ||
              checkedDataItems.filter(
                (data) => (data?.inEdit ?? false) === true
              )?.length <= 0,
            displayType: 'grid',
            className: `action-button Delete`,
            icon: `delete`,
            events: {
              onClick: () => {
                toggleDialogue('Delete', true);
              },
            },
          },
          {
            type: 'Button',
            title: 'Confirm',
            fillMode: 'outline',
            themeColor: 'primary',
            show:
              inEditMode &&
              checkedDataItems.filter(
                (data) => (data?.inEdit ?? false) === false
              )?.length <= 0,
            displayType: 'grid',
            className: `action-button Save`,
            icon: `check`,
            events: {
              onClick: () => {
                saveData();
              },
            },
          },
          {
            type: 'Button',
            fillMode: 'outline',
            title: 'Cancel',
            themeColor: 'primary',
            show:
              inEditMode &&
              checkedDataItems.filter(
                (data) => (data?.inEdit ?? false) === false
              )?.length <= 0,
            displayType: 'grid',
            className: `action-button Cancel`,
            icon: `cancel`,
            events: {
              onClick: () => {
                revertChanges();
                setInEdit(false);
                setInEditMode(false);
              },
            },
          }
        );
        setActionButtonsArray(tempActionButtonsArray);
      } else if (actionButtonsArray?.length) {
        setActionButtonsArray([]);
      }
    }
  }, [checkedDataItems, inEditMode, gridData]);

  React.useEffect(() => {
    const tempToolbar = getToolbar();
    setToolbar(tempToolbar);
  }, [
    actionButtonsArray,
    userAuthObj,
    props.filter,
    props.sort,
    gridData,
    props.dataList,
    checkedDataItems,
    lookupCheckedItems,
  ]);

  // Grid Inline Methods

  const addNew = () => {
    if (
      isValid({
        gridData,
        checkedDataItems,
        dataTableName,
        GRID_ID,
      })
    ) {
      const maxGridId = gridData.reduce((max, item) => {
        const dataID = parseInt(item[GRID_ID]);
        // prevent same rows addition
        return dataID > max ? dataID : max;
      }, 0);
      const newRowData = [
        ...gridData,
        {
          ...newDataItem,
          inEdit: true,
          rowStatus: RowStatus.New,
          selected: true,
          [GRID_ID]: `${maxGridId + 1}`,
        },
      ];
      setDataList(newRowData);
      if (Array.isArray(props.selectedState)) {
        setSelectedState([
          { id: `${maxGridId + 1}`, checked: true, status: 'new_row' },
          ...props.selectedState,
        ]);
      } else {
        setSelectedState([
          { id: `${maxGridId + 1}`, checked: true, status: 'new_row' },
        ]);
      }
      if (Array.isArray(props.checkedDataItems)) {
        setCheckedDataItems(
          [newRowData, ...props.checkedDataItems],
          dataTableName
        );
      } else {
        setCheckedDataItems([newRowData], dataTableName);
      }
      setInEdit(true);
    }
  };

  /**
   * refresh Data Table
   */
  const refreshDataTable = async () => {
    if (typeof props.refreshGridData === 'function') {
      setIsLoading(true);
      await refreshGridData(dataTableName);
      setIsLoading(false);
      setIsRefresh(true);
    }
  };
  const saveData = () => {
    save({
      gridData,
      selectedState: props.selectedState,
      dataTableName: props.dataTableName,
      checkedDataItems,
      GRID_ID,
      setDataList,
      exitEdit,
      setSelectedState,
      updateDataItem,
    });
  };
  /**
   * delete rows from grid
   */
  const remove = () => {
    if (checkedDataItems.length > 0) {
      checkedDataItems.map((item) => {
        let index = gridData.findIndex(
          (record) => record[GRID_ID] === item[GRID_ID]
        );
        const newData = gridData.splice(index, 1);
        setDataList(newData);
        setSelectedState(
          checkedDataItems.filter((f) => f[GRID_ID] !== item[GRID_ID])
        );
      });
    }
  };
  /**
   * enter all rows in edit mode on edit button click
   */
  const enterEdit = () => {
    if (checkedDataItems.length > 0) {
      const newData = gridData.map((item) =>
        checkedDataItems.some((dataItem) => dataItem[GRID_ID] === item[GRID_ID])
          ? {
              ...item,
              inEdit: true,
              rowStatus:
                item.rowStatus === RowStatus.New
                  ? RowStatus.New
                  : RowStatus.Modified,
            }
          : item
      );
      setDataList(newData);
      setInEdit(true);
    }
  };

  /**
   * revert grid pending changes
   */
  const revertChanges = () => {
    const newData = originalList.map((data) => {
      const selectedItem = props.checkedDataItems?.find(
        (itemObj) => itemObj.id === data[GRID_ID]
      );
      if (selectedItem) {
        // get original item
        return originalList?.find((itemObj) => itemObj.id === data[GRID_ID]);
      }

      return data;
    });
    initDataList(newData);
    setInEdit(false);
    setSelectedState([]);
  };

  React.useEffect(() => {
    props.checkedDataItems?.forEach((item) => {
      if (item?.rowStatus === RowStatus.New) {
        setInEdit(true);
      }
    });
  }, [props.checkedDataItems]);

  /**
   * Get the connected/disconnected state from global state and set the message
   * for connected and disconnected mode.
   */
  const noRecord = () => {
    if (!loading) {
      if (isNetworkConnected)
        return props?.filter?.filters?.length > 0 ? (
          <div
            className="no-record filters-applied"
            style={{
              padding: '20px 13px',
              textAlign: 'center',
            }}
          >
            <div
              style={{
                display: 'flex',
                flexDirection: 'row',
                gap: '2%',
                alignItems: 'flex-start',
                textAlign: 'start',
                justifyContent: 'center',
              }}
            >
              <span
                className="k-icon k-i-search k-font-icon no-filter-icon"
                style={{ fontSize: '70px', alignSelf: 'center' }}
              />
              <span
                style={{
                  display: 'flex',
                  flexDirection: 'column',
                  alignItems: 'center',
                }}
              >
                It seems you have filtered out all of your records, try removing
                one of your filters to see results.
                <Button
                  style={{ width: '142px' }}
                  title="Clear"
                  onClick={props.resetFilters}
                  fillMode={'outline'}
                  themeColor="primary"
                  icon="filter-clear"
                  size="small"
                >
                  Reset Filters
                </Button>
              </span>
            </div>
          </div>
        ) : (
          <div
            className="no-record"
            style={{
              padding: '20px 13px',
              textAlign: 'center',
            }}
          >
            {noRecordText ?? 'No record found'}
          </div>
        );
      return (
        <div
          className="no-record"
          style={{
            padding: '20px 13px',
            textAlign: 'center',
          }}
        >
          No more data available in offline mode.
        </div>
      );
    }
    return (
      <div
        className="no-record"
        style={{
          padding: '20px 13px',
          textAlign: 'center',
        }}
      ></div>
    );
  };
  return (
    <div className="list-dataTable main-wrapper">
      <Tooltip
        openDelay={100}
        position={'bottom'}
        anchorElement="target"
        parentTitle={true}
        autoHide={true}
      >
        {showToolbar && (
          <ToolBar
            toolbar={toolbar}
            gridView={gridView}
            handleSlide={handleSlide}
            rowSizeChange={(e) => {
              dispatch({
                type: 'ROW_LAYOUT',
                payload: {
                  value: e.target.value,
                  moduleName: dataTableName,
                  rowLayoutConfigName,
                },
              });
            }}
            setDraggableToggle={setDraggableToggle}
            rowSizes={state.rowSizes}
            pageLength={state.pageLength}
            pageListChange={pageListChange}
            resetSettings={resetSettings}
            filterSlide={filterSlide}
            namedFilters={namedFilter}
            filterName={filterName}
            setFilterName={setFilterName}
            filterChange={props.filterChange}
            filter={props.filter}
            gridFilterName={props.gridFilterName}
            setGridFilterName={props.setGridFilterName}
            enterEdit={enterEdit}
            cancel={cancel}
            remove={remove}
            save={saveData}
            addNew={addNew}
            refreshDataTable={refreshDataTable}
            revertChanges={revertChanges}
            rowLayouts={rowLayouts}
            pageSizes={pageSizes}
          />
        )}
      </Tooltip>
      <React.Suspense fallback={<Loader />}>
        {gridView && (
          <>
            {props.filter?.filters?.length > 0 && (
              <div className="grid-applied-filters">
                <AppliedFilters
                  filter={props.filter}
                  setFilter={props.setFilter}
                  filterSlide={filterSlide}
                  dataTableName={dataTableName}
                  setDateRangeFilter={setDateRangeFilter}
                  setGridFilterName={setGridFilterName}
                  setFilterName={setFilterName}
                  gridFilterName={gridFilterName}
                  dataColumns={dataColumns}
                />
              </div>
            )}
            <div className="grid-wrapper">
              <Grid
                filter={props.filter}
                setFilter={props.setFilter}
                filterType={filterType}
                setData={setGridData}
                page={state.page}
                sort={props.sort}
                loading={loading}
                pageChange={pageChange}
                setSort={props.setSort}
                handleSlide={handleSlide}
                pageListChange={pageListChange}
                data={gridData}
                total={length}
                checkedItems={(e) => {
                  props.setSelectedState(e, dataTableName);
                }}
                rowLayoutConfigName={rowLayoutConfigName}
                pageLengthConfigName={pageLengthConfigName}
                selectedState={props.selectedState}
                rowSizes={state.rowSizes}
                pageLength={state.pageLength}
                discard={discard}
                cancel={cancel}
                inlineEdit={inlineEdit}
                exitEdit={exitEdit}
                setInEdit={setInEdit}
                scrollHandler={scrollHandler}
                dataTableName={dataTableName}
                gridColumnsList={gridColumnsList}
                dataItemKey={GRID_ID}
                gridColumnMethods={gridColumnMethods}
                gridMethods={gridMethods}
                setFilterName={setFilterName}
                setGridFilterName={setGridFilterName}
                gridFilterName={gridFilterName}
                sliderAction={sliderAction}
                setSliderAction={setSliderAction}
                isFilterable={isFilterable}
                dateRangefilter={dateRangefilter}
                setDateRangeFilter={setDateRangeFilter}
                isFavourite={isFavourite}
                moduleName={moduleName}
                handleFavouriteArray={handleFavouriteArray}
                noRecordText={noRecordText}
                selectable={selectable}
                dataColumns={dataColumns}
                isSelectable={isSelectable}
                orderSeqKey={orderSeqKey}
                setDraggableCallback={setDraggableCallback}
                draggableToggle={draggableToggle}
                isGridSortable={isGridSortable}
                resetFilters={props?.resetFilters}
                noRecord={noRecord}
              />
            </div>
          </>
        )}
        {!gridView && isCardonMobileView && (
          <>
            {props.filter?.filters?.length > 0 && (
              <AppliedFilters
                filter={props.filter}
                setFilter={props.setFilter}
                filterSlide={filterSlide}
                dataColumns={dataColumns}
              />
            )}
            <Card
              data={orderBy(gridData, props.sort)}
              loading={loading}
              checkedItems={(e) => {
                dispatch({ type: 'SELECTED_STATE', payload: e });
              }}
              selectedState={props.selectedState}
              setSelectedState={(e) => {
                setSelectedState(e, dataTableName);
              }}
              selected={props.selected}
              checkedDataItems={checkedDataItems}
              gridMethods={gridMethods}
              scrollHandler={scrollHandler}
              dataTableName={dataTableName}
              show={show}
              sliderAction={sliderAction}
              setSliderAction={setSliderAction}
              isFilterable={isFilterable}
              noRecordText={noRecordText}
              dataItemKey={GRID_ID}
              moduleName={moduleName}
              noRecord={noRecord}
            />
          </>
        )}
        {!gridView && !isCardonMobileView && GridListView && (
          <GridListView
            isSimpleMode={isSimpleMode}
            data={orderBy(
              gridData?.filter((f) => f.rowStatus !== RowStatus.Deleted),
              props.sort
            )}
            setData={setGridData}
            total={length}
            loading={loading}
            noRecordText={noRecordText}
            checkedItems={(e) => {
              dispatch({ type: 'SELECTED_STATE', payload: e });
            }}
            selectedState={props.selectedState}
            setSelectedState={(e) => {
              setSelectedState(e, dataTableName);
            }}
            selected={props.selected}
            checkedDataItems={checkedDataItems}
            gridMethods={gridMethods}
            scrollHandler={scrollHandler}
            dataTableName={dataTableName}
            show={show}
            sliderAction={sliderAction}
            setSliderAction={setSliderAction}
            isFilterable={isFilterable}
            dataItemKey={GRID_ID}
            handleFavouriteArray={handleFavouriteArray}
            toggleDialogue={toggleDialogue}
            setCheckedDataItems={setCheckedDataItems}
            editDataHandler={editDataHandler}
            lookupSelectionMode={lookupSelectionMode}
          />
        )}
      </React.Suspense>
      {showDialogue && (
        <Dialogue
          title={title}
          message={confirmationMessage}
          onClick={() => {
            if (actionName === 'Delete') {
              removeItem();
              setInEdit(false);
              setInEditMode(false);
            }
            toggleDialogue(actionName, false);
          }}
          onClose={() => {
            setInEdit(false);
            setInEditMode(false);
            toggleDialogue(actionName, false);
          }}
          onCancel={() => {
            setInEdit(false);
            setInEditMode(false);
            toggleDialogue(actionName, false);
          }}
        />
      )}
    </div>
  );
};
export default List;
