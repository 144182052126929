import React from 'react';
import { Field } from '@progress/kendo-react-form';
import { Hint, Label } from '@progress/kendo-react-labels';

/**
 * Message for the  Dialogue Component to show confirmation text
 * @type {React.FC<Props>}
 * @returns {React.ReactElement} The Dialogue component.
 */
const DialogueMessage = (props) => {
  const {
    FormInput,
    filterValue,
    setFilterNameExisted,
    setFilterValue,
    filterNameExisted,
  } = props;
  return (
    <div>
      <div className="row">
        <div className="filter-col">
          <span className="k-floating-label-container k-label">
            <Label className="k-label">Filter Name</Label>
          </span>
          <Field
            name="FilterName"
            component={FormInput}
            autoComplete="off"
            data={filterValue}
            className="search-field"
            onChange={(e) => {
              if (filterNameExisted) {
                setFilterNameExisted(false);
              }
              setFilterValue(e?.target?.value);
            }}
          />
        </div>
      </div>

      {filterNameExisted && (
        <Hint className="named-filters-alert">
          Filter with the same name already exists.
        </Hint>
      )}
    </div>
  );
};
export default DialogueMessage;
