// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.lcs-action {
  display: flex;
  align-items: center;
  justify-content: right;
}
.lcs-action button {
  margin: 5px;
}`, "",{"version":3,"sources":["webpack://./src/Modules/Core/Components/Editor/Editor.scss"],"names":[],"mappings":"AAAA;EACI,aAAA;EACA,mBAAA;EACA,sBAAA;AACJ;AAAI;EACI,WAAA;AAER","sourcesContent":[".lcs-action {\n    display: flex;\n    align-items: center;\n    justify-content: right;\n    button {\n        margin: 5px;\n    }\n}"],"sourceRoot":""}]);
// Exports
export default ___CSS_LOADER_EXPORT___;
