// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.k-list-item.k-selected {
  color: #3f51b5;
  background: #d8dcf0;
}

.k-multiselect-popup .k-custom-item .k-svg-i-plus {
  display: none;
}`, "",{"version":3,"sources":["webpack://./src/Modules/Core/Components/Filter/CheckBoxListSelectFilter.scss"],"names":[],"mappings":"AAAA;EACE,cAAA;EACA,mBAAA;AACF;;AAII;EACE,aAAA;AADN","sourcesContent":[".k-list-item.k-selected {\n  color: #3f51b5;\n  background: #d8dcf0;\n}\n\n.k-multiselect-popup {\n  .k-custom-item {\n    .k-svg-i-plus {\n      display: none;\n    }\n  }\n}\n"],"sourceRoot":""}]);
// Exports
export default ___CSS_LOADER_EXPORT___;
