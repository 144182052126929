// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.applied-filters {
  padding: 0px 10px 0px 14px;
  display: flex;
  align-items: center;
}
.applied-filters .k-i-filter {
  margin: 0 8px 0 0;
  font-size: 20px;
}
.applied-filters .filters-list .chips-list {
  margin: 2px;
}
.applied-filters .filters-list .chips-list .filter-content {
  padding-block: 0.5em;
  padding-inline: 0;
  margin-block: -0.5em;
  margin-inline: 0;
  min-width: 0;
  display: flex;
  flex-flow: row nowrap;
  align-items: center;
  overflow: hidden;
  flex: 1 1 auto;
}
.applied-filters .filters-list .chips-list .filter-content .filter {
  display: flex;
  align-items: center;
}
.applied-filters .filters-list .chips-list .filter-content .filter .field-name {
  color: #7f8fa4;
  text-transform: uppercase;
  font-size: 12px;
  font-weight: 400;
  overflow-wrap: break-word;
}
.applied-filters .filters-list .chips-list .filter-content .count {
  background-color: #363636;
  height: 18px;
  width: 18px;
  border-radius: 50%;
  text-align: center;
  vertical-align: middle;
  padding: 2px;
  color: white;
  margin-left: 8px;
  font-size: 12px;
}

.grid-applied-filters {
  margin: 0 0 14px 0;
}`, "",{"version":3,"sources":["webpack://./src/Modules/Core/Components/DataTable/Filter/AppliedFilters.scss"],"names":[],"mappings":"AAAA;EACE,0BAAA;EACA,aAAA;EACA,mBAAA;AACF;AAAE;EACE,iBAAA;EACA,eAAA;AAEJ;AACI;EACE,WAAA;AACN;AAAM;EAYE,oBAAA;EACA,iBAAA;EACA,oBAAA;EACA,gBAAA;EACA,YAAA;EACA,aAAA;EACA,qBAAA;EACA,mBAAA;EACA,gBAAA;EACA,cAAA;AATR;AAXQ;EACE,aAAA;EACA,mBAAA;AAaV;AAZU;EACE,cAAA;EACA,yBAAA;EACA,eAAA;EACA,gBAAA;EACA,yBAAA;AAcZ;AADQ;EACE,yBAAA;EACA,YAAA;EACA,WAAA;EACA,kBAAA;EACA,kBAAA;EACA,sBAAA;EACA,YAAA;EACA,YAAA;EACA,gBAAA;EACA,eAAA;AAGV;;AAGA;EACE,kBAAA;AAAF","sourcesContent":[".applied-filters {\n  padding: 0px 10px 0px 14px;\n  display: flex;\n  align-items: center;\n  .k-i-filter {\n    margin: 0 8px 0 0;\n    font-size: 20px;\n  }\n  .filters-list {\n    .chips-list {\n      margin: 2px;\n      .filter-content {\n        .filter {\n          display: flex;\n          align-items: center;\n          .field-name {\n            color: #7f8fa4;\n            text-transform: uppercase;\n            font-size: 12px;\n            font-weight: 400;\n            overflow-wrap: break-word;\n          }\n        }\n        padding-block: 0.5em;\n        padding-inline: 0;\n        margin-block: -0.5em;\n        margin-inline: 0;\n        min-width: 0;\n        display: flex;\n        flex-flow: row nowrap;\n        align-items: center;\n        overflow: hidden;\n        flex: 1 1 auto;\n        .count {\n          background-color: #363636;\n          height: 18px;\n          width: 18px;\n          border-radius: 50%;\n          text-align: center;\n          vertical-align: middle;\n          padding: 2px;\n          color: white;\n          margin-left: 8px;\n          font-size: 12px;\n        }\n      }\n    }\n  }\n}\n.grid-applied-filters {\n  margin: 0 0 14px 0;\n}\n"],"sourceRoot":""}]);
// Exports
export default ___CSS_LOADER_EXPORT___;
