import React from 'react';
/** Create empty Pipe Context so we can add child context in it */
const PipeContext = React.createContext(<>{}</>);

/**  Set the children context array as value in Pipe Context Provider */
export const PipeProvider = (props) => (
  <PipeContext.Provider value={props.component}>
    {props.children}
  </PipeContext.Provider>
);

/** return an Object of Pipe Context */
export const usePipe = () => React.useContext(PipeContext);

/** create pipe component with pipe context */
export const Pipe = () => {
  const pipe = usePipe();

  return <>{pipe}</>;
};

/** Used to recieve multiple Context array and our wrapper component */
export const Pipeline = (props) => {
  const pipe = usePipe();
  const [component, ...components] = props.components;

  return (
    <PipeProvider
      component={<Piping components={[...components, pipe]} />}
      children={component}
    />
  );
};

/**  Create nested pipe provider component */
export const Piping = (props) => {
  const [component, ...components] = props.components;

  return (
    <PipeProvider
      component={<Piping components={components} />}
      children={component}
    />
  );
};
