/**
 * dimensions for devices in portrait mode
 */
const breakpoints = {
  smPhone: 320,
  phone: 576,
  tablet: 820,
  laptop: 992,
  desktop: 1024,
  lDesktop: 1200,
  xlDesktop: 1440,
  xxlDesktop: 1920,
};
export default breakpoints;
