import React from 'react';
import { RadioButton } from '@progress/kendo-react-inputs';
import { getter } from '@progress/kendo-react-common';

import LookupTreeList from './LookupTree';

/**
 * Lookup Sidebar
 * Includes TreeView of Data
 */
const LookupSidebar = ({
  dataSet,
  selectedValue,
  setSelectedTree,
  selectedTree,
  setSelectedTreeObj,
  treeOptionsList,
}) => {
  /**
   * Tree List Start
   */
  const handleTreeChangeEvent = React.useCallback(
    (e) => {
      setSelectedTree(e.value);
      setSelectedTreeObj({});
    },
    [selectedValue]
  );
  const selectedTreeOption = treeOptionsList.find(
    (option) => option.value === selectedTree
  );
  return (
    <>
      <div className="tree-radio-wrapper">
        {treeOptionsList?.map((item, i) => (
          <div className="tree-radio-btn-wrapper" key={i}>
            <RadioButton
              name="LookupTree"
              value={item?.value}
              checked={selectedTree === item?.value}
              label={item?.label}
              onChange={handleTreeChangeEvent}
            />
          </div>
        ))}
      </div>
      <LookupTreeList
        className="lookup-tree"
        setSelectedTreeObj={setSelectedTreeObj}
        selectedValue={selectedValue}
        subItemField={selectedTreeOption?.subItemField}
        dataItemKey={selectedTreeOption?.dataItemKey}
        dataSet={dataSet?.slice()}
        idGetter={getter(selectedTreeOption?.dataItemKey)}
        columns={selectedTreeOption?.columns}
      />
    </>
  );
};

export default LookupSidebar;
