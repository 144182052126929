/**
 * Retrieves an item from local storage by its key and returns it.
 * @param {string} key - The key of the item to retrieve from local storage.
 * @returns {any} The item retrieved from local storage, or null if it does not exist.
 */
export const getItem = (key) => {
  try {
    return JSON.parse(localStorage.getItem(key));
  } catch (error) {
    return localStorage.getItem(key);
  }
};

/**
 * Adds an item to local storage with the given key and value.
 * @param {string} key - The key to use for the item in local storage.
 * @param {any} value - The value to add to local storage.
 * @returns {null} If the key parameter is not provided, or undefined.
 */
export const setItem = (key, value) => {
  if (key) {
    try {
      return localStorage.setItem(key, JSON.stringify(value));
    } catch (error) {
      return localStorage.setItem(key, value);
    }
  }
  return null;
};

/**
 * Updates an existing item in local storage by its key, changing the value of a specific property.
 * @param {string} key - The key of the item in local storage to update.
 * @param {string} name - The name of the property to update.
 * @param {any} value - The new value for the specified property.
 * @returns {null} If the item does not exist in local storage or the name parameter is not provided.
 */
export const updateItem = (key, name, value = '') => {
  const item = getItem(key);
  if (item) {
    if (name) {
      item[name] = value;
      try {
        return localStorage.setItem(key, JSON.stringify(item));
      } catch (error) {
        return localStorage.setItem(key, value);
      }
    }
  }
  return null;
};

/**
 * Retrieves a specific property of an item from local storage by its key.
 * @param {string} key - The key of the item to retrieve from local storage.
 * @param {string} name - The name of the property to retrieve from the item.
 * @returns {any} The property value of the item retrieved from local storage, or null if it does not exist.
 */
export const fetchItem = (key, name) => {
  try {
    const item = getItem(key);
    if (item && name) return item[name];
    return item;
  } catch (error) {
    return localStorage.getItem(key);
  }
};

/**
 * Removes an item from local storage by its key.
 * @param {string} key - The key of the item to remove from local storage.
 * @returns {null} If the item does not exist in local storage.
 */
export const removeItem = (key) => {
  try {
    return localStorage.removeItem(key);
  } catch (error) {
    return null;
  }
};

/**
 * Checks if local storage is available in the current browser.
 * @returns {boolean} A boolean value indicating if local storage is available.
 */
export const isLocalStorageAvailable = () => {
  let key = 'test';
  try {
    localStorage.setItem(key, key);
    localStorage.removeItem(key);
    return true;
  } catch (e) {
    return false;
  }
};
