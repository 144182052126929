/*
  AppNotifications component manages the display and handling of application notifications.
  It listens for notifications from the service worker, updates the local state, and renders the notifications.

  Usage:
  <AppNotifications />

  Dependencies:
  - GlobalContext: Provides access to global application context and dimensions.
  - NotificationGroup: Component that wraps the notification list.
  - Fade: Component that provides fade animation for notifications.
  - NotificationRenderer: Component that renders individual notifications.

*/

import React from 'react';
import { NotificationGroup } from '@progress/kendo-react-notification';
import { Fade } from '@progress/kendo-react-animation';
import {
  EVENTS_DATA_TYPES,
  DEVICE_TYPES,
} from '../../../../constants/eventDataTypes';
import {
  APP_CONFIG,
  DisplayNotification,
  AUTH_TOKENS,
} from '../../../../constants/applicationConstants';
import { saveNotification } from './NotificationHandler';
import NotificationRenderer from './NotificationRenderer';
import eventBus from '../../../../Utils/Common/eventBus';
import {
  closeNotificationPopup,
  filterOutDuplicates,
} from '../../../../Utils/Notifications/notificationUtils';

import { GlobalContext } from '../../Context/GlobalContextState';
import './AppNotification.scss';
import {
  syncSessionStorage,
  syncStorage,
} from '../../../../Utils/Storage/storageUtils';

let notifications = [];
/**
 * Display Notification
 * @returns {React.Component} returns App Notification Component
 */
const AppNotifications = () => {
  const { dimensions, deviceInfo } = React.useContext(GlobalContext);
  const [notificationStlye, setNotificationStyle] = React.useState({
    right: 0,
    bottom: 0,
    zIndex: '9999',
  });
  /**
   * Remove the notification from local storage and update the React state.
   * @param {*} notification
   */
  const onClose = (notificationId) => {
    let filteredNotifications = closeNotificationPopup(notify, notificationId);
    notifications = filteredNotifications;
    setNotify(filteredNotifications);
  };

  // #region React hooks.
  const [notify, setNotify] = React.useState([]);
  React.useEffect(() => {
    /**
     * Event Bus listener
     */
    eventBus.on(EVENTS_DATA_TYPES.APPLICATION_NOTIFICATION, (notification) => {
      updateLocalState(notification);
    });
    return () => {
      // Remove the event bus listener that may caused memory leak.
      eventBus.remove(EVENTS_DATA_TYPES.APPLICATION_NOTIFICATION);
    };
  }, []);
  // #region  Service worker messages and process those messages.
  /**
   * Service worker message handler. Push the notification into state and show them.
   */
  if ('serviceWorker' in navigator) {
    navigator.serviceWorker.onmessage = (event) => {
      if (event.data && event.data.type === EVENTS_DATA_TYPES.RELOAD) {
        // Reload the page when the message is received
        window.location.reload();
      }
      /**
       * Handle event of Sync Index db data with local storage
       */
      if (event.data && event.data.type === EVENTS_DATA_TYPES.SYNC_DB_LS) {
        const activityModule = event?.data?.notification;
        let moduleName = activityModule?.moduleName;
        if (moduleName === APP_CONFIG) {
          let subModules = activityModule?.subModules;
          subModules?.forEach((element) => {
            syncStorage(element);
          });
        }
      }
      /**
       * Handle event of Sync Index db data with session storage
       */
      if (event.data && event.data.type === EVENTS_DATA_TYPES.SYNC_DB_SS) {
        const activityModule = event?.data?.notification;
        let moduleName = activityModule?.moduleName;
        if (moduleName === AUTH_TOKENS) {
          let subModules = activityModule?.subModules;
          subModules?.forEach((element) => {
            syncSessionStorage(element);
          });
        }
      }

      /**
       * Handle event of Sync to process notifications on user screen.
       */
      if (
        event.data &&
        (event.data.type === EVENTS_DATA_TYPES.PROCESS_NOTIFICATION ||
          event.data.type === EVENTS_DATA_TYPES.DELAY_NOTIFICATION)
      ) {
        updateLocalState(event.data.notification);
      }
      /**
       * Handle event of Sync to delete all the db's and reset index db cache
       * if configuration is defined and true.
       */
      if (
        event.data &&
        event.data.type === EVENTS_DATA_TYPES.RESET_ENVIRONMENT
      ) {
        // resetEnvironment();
      }

      /**
       * Handle event of Sync to trigger the update event of service worker.
       */
      if (
        event.data &&
        event.data.type === EVENTS_DATA_TYPES.UPDATE_SERVICE_WORKER
      ) {
        navigator.serviceWorker.getRegistrations().then((regs) =>
          regs.forEach((reg) => {
            reg.update();
          })
        );
      }
    };
  }
  // #endregion

  /**
   * Set timer to autoclose notifications
   */
  React.useEffect(() => {
    if (notify.length > 0) {
      let notification = notify[notify.length - 1];
      if (notification.isNotify) {
        if (notification.timeout > 0)
          setTimeout(() => {
            setTimer(notification);
          }, notification.timeout);
      }
    }
  }, [notify]);

  // #endregion

  /**
   * Update Local storage and React state.
   * @param {*} notification
   */
  const updateLocalState = (notification) => {
    let notificationsShowed = [];
    if (notification) {
      // check if notification already exist

      setNotify((prev) => {
        notificationsShowed = prev.findIndex(
          (f) =>
            f.title === notification.title &&
            f.description === notification.description
        );
        if (notificationsShowed === -1) {
          notifications.push(notification); // Push current notification to the local array for reference
          saveNotification(notification); // Save notification to Local storage.
          const filteredArray = filterOutDuplicates(
            [...prev, notification],
            'notificationId'
          );
          return [...filteredArray];
        }
        // If notification is already in state hook then check if Needs to be displayed
        // and should show in group or separate.
        if (!prev[notificationsShowed].isNotify && notification.isNotify) {
          prev[notificationsShowed].isNotify = notification.isNotify;
          prev[notificationsShowed].count = 0;
        } else {
          prev[notificationsShowed].count =
            prev[notificationsShowed]?.count === 0
              ? 1
              : prev[notificationsShowed]?.count;
          let count = prev[notificationsShowed]?.count ?? 1;

          count += 1;

          prev[notificationsShowed].count = count;
        }
        saveNotification(prev[notificationsShowed]); // Save notification to Local storage.
        return [...prev];
      });

      // add the current notification to the state.
      notifications = notifications.filter((value) => value.isNotify); // Remove all notifications have already been displayed".
    }
  };

  /**
   * Auto close the notification and update react state.
   * @param {*} notification
   */
  const setTimer = (notification) => {
    let localNotification = notifications.map((value) => {
      if (
        value.notificationId === notification.notificationId &&
        value.isNotify
      ) {
        value.isNotify = false;
        return value;
      }
      return value;
    });
    notifications = localNotification;
    setNotify(localNotification);
  };
  React.useEffect(() => {
    if (
      deviceInfo?.type === DEVICE_TYPES.PHONE ||
      deviceInfo?.type === DEVICE_TYPES.TABLET
    ) {
      setNotificationStyle({
        top: 0,
        zIndex: '9999',
      });
    } else {
      setNotificationStyle({
        right: 0,
        bottom: 0,
        zIndex: '9999',
      });
    }
  }, [dimensions]);
  // #region  Notification View/Settings
  return (
    <NotificationGroup style={notificationStlye}>
      <Fade>
        {notify.length > 0 &&
          notify
            .filter(
              (notification, index, self) =>
                index ===
                self.findIndex(
                  (n) =>
                    n.notificationId === notification.notificationId &&
                    n.isNotify
                )
            )
            .map(
              (notification, i) =>
                notification.isNotify &&
                (notification.display === DisplayNotification.SHOW ||
                  notification.display === DisplayNotification.ALERT) && (
                  <NotificationRenderer
                    key={i}
                    notification={notification}
                    onNotificationClose={onClose}
                  />
                )
            )
            .reverse()}
      </Fade>
    </NotificationGroup>
  );
  // #endregion
};

export default AppNotifications;
