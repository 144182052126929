import React from 'react';
import { DropDownList } from '@progress/kendo-react-dropdowns';
import { Button, ButtonGroup } from '@progress/kendo-react-buttons';
/**
 * Data Card toolbar (dynamic toolbar for Data Cards)
 * @type {React.FC<Props>}
 * @returns {React.ReactElement} The DataCardToolbar component.
 */
const DataCardToolbar = (props) => {
  const {
    toolbarExpanded,
    pageListChange,
    pageLength,
    resetSettings,
    pageSizes,
  } = props;

  return (
    <>
      {toolbarExpanded && (
        <div
          className={`toolbar-card-wrapper ${
            toolbarExpanded ? 'toolbar-active' : 'toolbar-inactive'
          }`}
        >
          <DropDownList
            data={pageSizes}
            value={pageLength}
            name="pages"
            label="Item per page"
            onChange={pageListChange}
          />
          <ButtonGroup>
            <Button
              icon={'reset'}
              fillMode="outline"
              themeColor="primary"
              className="action-button"
              onClick={resetSettings}
            />
          </ButtonGroup>
        </div>
      )}
    </>
  );
};
export default DataCardToolbar;
