import React from 'react';
import { FieldWrapper } from '@progress/kendo-react-form';
import { TextArea } from '@progress/kendo-react-inputs';
import { Error, Hint, Label } from '@progress/kendo-react-labels';
import { RenderTypes } from '../../../../constants/applicationConstants';

/**
 * TextAreaInput component of TextArea field
 * TextAreaInput component that renders a form TextArea Input field
 * @param {object} fieldRenderProps - the properties for the field TextArea component
 * @returns {JSX.Element} - the TextAreaInput component
 */
export const TextAreaInput = (fieldRenderProps) => {
  const {
    name,
    validationMessage,
    touched,
    label,
    id,
    valid,
    disabled,
    hint,
    type,
    optional,
    max,
    renderTypes,
    value,
    data,
    row,
    requiredField,
    ...others
  } = fieldRenderProps;
  const showValidationMessage = touched && validationMessage;
  const showHint = !showValidationMessage && hint;
  const hintId = showHint ? `${id}_hint` : '';
  const errorId = showValidationMessage ? `${id}_error` : '';
  return (
    <FieldWrapper>
      {label && (
        <span className="k-floating-label-container k-label">
          <Label
            className="k-label"
            editorId={id}
            editorValid={valid}
            editorDisabled={disabled}
            optional={optional}
          >
            {label} {requiredField && <span className="required-field">*</span>}
          </Label>
        </span>
      )}
      {renderTypes === RenderTypes.ReadOnly ? (
        <span className="display-text">{data}</span>
      ) : (
        <div className={'k-form-field-wrap'}>
          <TextArea
            name={name}
            valid={valid}
            value={data ?? ''}
            label={label}
            id={id}
            disabled={disabled}
            rows={row}
            ariaDescribedBy={`${hintId} ${errorId}`}
            {...others}
            type={type}
          />
          {showHint && <Hint id={hintId}>{hint}</Hint>}
          {showValidationMessage && (
            <Error id={errorId}>{validationMessage}</Error>
          )}
        </div>
      )}
    </FieldWrapper>
  );
};

export default TextAreaInput;
