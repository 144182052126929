import React, { useState } from 'react';
import { Dialog } from '@progress/kendo-react-dialogs';
import { ScrollView } from '@progress/kendo-react-scrollview';
import './ImageView.scss';
import { Button, ButtonGroup } from '@progress/kendo-react-buttons';
import { ImageWithFallback } from './ImageWithFallback';
import ZoomPinchImage from './ImagePinchToZoom';
import { GlobalContext } from '../../Context/GlobalContextState';

/**
 * A component that displays a list of images in a popup with a zoom-in feature.
 * @component
 * @param {Array.<{PageNum: number, ImageBlob: string, ImageBlobType: string}>} images - A list of images with their page number, base64-encoded image data, and the image type.
 * @param {function} onClose - A callback function to close the popup.
 * @returns {JSX.Element} - A React component that displays a list of images in a popup.
 */
export const ImageView = (props) => {
  const {
    title = null,
    description = null,
    thumbnailImages = [],
    bigImages = [],
    onClose,
    id = null,
    downloadFile,
  } = props;
  const { isNetworkConnected } = React.useContext(GlobalContext);
  /**
   * The index of the currently selected image.
   * @type {number}
   */
  const [selectedIndex, setSelectedIndex] = useState(0);
  const scrollViewRef = React.useRef(null);
  const [showFullScreen, setShowFullScreen] = useState(false);
  const [selectedImage, setSelectedImage] = useState(null);
  const [isDownloadInProgress, SetIsDownloadInProgress] = useState(false);

  /**
   * A function to handle when an image is clicked.
   * Sets the selected index to the index of the clicked image.
   * @param {number} index - The index of the clicked image.
   * returns {void}
   */
  const handleImageClick = (e, index) => {
    const childElement =
      scrollViewRef?.current?.element.querySelector('.k-scrollview-wrap');
    childElement.style.setProperty('--kendo-scrollview-current', index + 1);
    childElement.style.setProperty('--kendo-scrollview-custom', 'true');
    const thumbnails = document.getElementsByClassName('thumbnail');

    for (let i = 0; i < thumbnails.length; i += 1) {
      const thumbnail = thumbnails[i];
      thumbnail.classList.remove('selected');
    }
    if (e) e.target.classList.add('selected');
    setSelectedIndex(index);
  };

  /**
   * Mutation Observer For Scroll View
   */
  React.useEffect(() => {
    /**
     * get Dom object of scrollview, next and prev button
     */
    const scrollViewElement = scrollViewRef.current.element;
    const nextArrow = scrollViewElement.querySelector('.k-scrollview-next');
    const prevArrow = scrollViewElement.querySelector('.k-scrollview-prev');
    const targetElements =
      scrollViewElement.querySelector('.k-scrollview-wrap');

    /**
     * observe target element style is changed or not
     */
    const observer = new MutationObserver((mutationsList) => {
      for (const mutation of mutationsList) {
        if (
          mutation.type === 'attributes' &&
          mutation.attributeName === 'style'
        ) {
          // Style change detected on the target class
          const targetElement = mutation.target;

          const index = targetElement.style.getPropertyValue(
            '--kendo-scrollview-current'
          );
          const isCustom = targetElement.style.getPropertyValue(
            '--kendo-scrollview-custom'
          );

          if (isCustom === 'false') {
            setSelectedIndex(parseInt(index) - 1);
          }
        }
      }
    });

    // Observe target element for style changes
    observer.observe(targetElements, { attributes: true });

    // reset custom class on click
    const handleArrowClick = () => {
      const childElement =
        scrollViewElement.querySelector('.k-scrollview-wrap');
      childElement.style.setProperty('--kendo-scrollview-custom', 'false');
    };
    scrollViewElement.addEventListener('keydown', handleArrowClick);
    nextArrow?.addEventListener('click', handleArrowClick);
    prevArrow?.addEventListener('click', handleArrowClick);

    return () => {
      nextArrow?.removeEventListener('click', handleArrowClick);
      prevArrow?.removeEventListener('click', handleArrowClick);
    };
  }, []);

  /**
   * Set Selected Image in QuickView
   */
  React.useEffect(() => {
    const element = document.querySelector('.k-scrollview-wrap');
    element.style.setProperty('--kendo-scrollview-current', selectedIndex + 1);
  }, [selectedIndex, isDownloadInProgress, bigImages]);

  /**
   * Custom Title Bar for quick view popup
   * @returns
   */
  const CustomTitleBar = () => (
    <div
      className="custom-title"
      style={{
        fontSize: '18px',
        lineHeight: '1.3em',
      }}
    >
      <div className="title">
        <strong>{title}</strong>
        {description ? <div className="description"> {description}</div> : ''}
      </div>
      <div className="resize-button">
        <Button
          icon={'maximize'}
          fillMode={'flat'}
          className="k-window-titlebar-action k-dialog-titlebar-action"
          onClick={() => {
            setSelectedImage(selectedIndex);
            setShowFullScreen(true);
          }}
        />
      </div>
    </div>
  );

  return (
    <Dialog
      title={title ? <CustomTitleBar /> : 'Quick View'}
      className={'zoom-popup'}
      onClose={onClose}
    >
      <div className="popup-container">
        <div className="actions">
          <ButtonGroup>
            <Button
              className={`action-button`}
              icon={'chevron-left'}
              disabled={parseInt(selectedIndex) <= 0}
              onClick={() =>
                handleImageClick(null, parseInt(selectedIndex) - 1)
              }
              fillMode="outline"
              themeColor="primary"
            />
            <Button
              className={`action-button`}
              icon={'chevron-right'}
              disabled={
                bigImages?.length === 1 ||
                parseInt(selectedIndex) + 1 === bigImages?.length
              }
              onClick={() =>
                handleImageClick(null, parseInt(selectedIndex) + 1)
              }
              fillMode="outline"
              themeColor="primary"
            />
            <Button
              className={`action-button`}
              icon={'download'}
              fillMode="outline"
              themeColor="primary"
              disabled={isDownloadInProgress || !isNetworkConnected}
              onClick={() => {
                (async () => {
                  SetIsDownloadInProgress(true);
                  await downloadFile({ documentId: id });
                  SetIsDownloadInProgress(false);
                })();
              }}
            />
          </ButtonGroup>
        </div>
        <div>
          <ScrollView
            style={{
              width: '100%',
              height: 384,
            }}
            endless={true}
            automaticViewChange={false}
            activeView={selectedIndex}
            currentPage={selectedIndex}
            ref={scrollViewRef}
            pageable={false}
          >
            {bigImages?.map((page, index) => (
              <div className="scroll-view-image image-with-text" key={index}>
                <img
                  className={'big-image'}
                  height={'100%'}
                  width={'100%'}
                  src={page.ImageBlobType + page.ImageBlob}
                  alt={page.Name}
                />
              </div>
            ))}
          </ScrollView>
          {showFullScreen && (
            <ZoomPinchImage
              images={bigImages}
              index={selectedImage}
              title={title}
              width={'100%'}
              onClose={() => setShowFullScreen(false)}
            />
          )}
        </div>

        <div className="scroll-view-footer">
          {thumbnailImages?.map((page, index) => (
            <div key={index}>
              <ImageWithFallback
                className={
                  selectedIndex === index ? 'thumbnail selected' : 'thumbnail'
                }
                src={page.ImageBlobType + page.ImageBlob}
                alt=""
                onClick={(e) => handleImageClick(e, index)}
                fallbackSrc="/631e5920e60d6160f164.svg"
              />
            </div>
          ))}
        </div>
      </div>
    </Dialog>
  );
};

export default ImageView;
