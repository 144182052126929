import React from 'react';
import { Button } from '@progress/kendo-react-buttons';
import { InputClearValue, TextBox } from '@progress/kendo-react-inputs';
import { Icon } from '@progress/kendo-react-common';
import { GlobalContext } from '../../../Context/GlobalContextState';
import { DEVICE_TYPES } from '../../../../../constants/eventDataTypes';
/**
 * Topbar of Lookup page
 * Includes Favorite and Recent button with Lookup search Box
 */
const EMPTY_VALUE = '';
const LookupTopbar = ({
  topbarSearch,
  setTopBarSearch,
  setIsSearch,
  isSearch,
  isSearchEnable = true,
  setIsRecent,
  isRecent,
  isRecentEnable = true,
  isFavouriteEnable = true,
  setIsFilterFavourite,
  searchPlaceHolder,
}) => {
  const { deviceInfo } = React.useContext(GlobalContext);
  const handleChange = React.useCallback((event) => {
    setTopBarSearch(event.target.value);
  }, []);
  const handleClear = React.useCallback(() => {
    setTopBarSearch(EMPTY_VALUE);
    searchData();
  }, []);
  const searchData = () => {
    if (!isSearch) {
      setIsSearch(true);
    }
  };
  return (
    <div className="lookup-topbar">
      {!(
        deviceInfo?.type === DEVICE_TYPES.PHONE ||
        deviceInfo?.type === DEVICE_TYPES.TABLET
      ) && (
        <>
          {isFavouriteEnable && (
            <div className="lookup-btn-wrapper">
              <Button
                icon="star"
                fillMode="outline"
                themeColor={'primary'}
                onClick={() => {
                  setIsFilterFavourite(true);
                }}
              >
                FAVORITE
              </Button>
            </div>
          )}
          {isRecentEnable && (
            <div className="lookup-btn-wrapper">
              <Button
                fillMode="outline"
                themeColor={'primary'}
                icon="reset"
                onClick={() => {
                  if (!isRecent) {
                    setIsRecent(true);
                  }
                }}
              >
                RECENT
              </Button>
            </div>
          )}
        </>
      )}

      {isSearchEnable && (
        <div className="lookup-search-wrapper">
          <fieldset className={'k-form-fieldset'}>
            <TextBox
              value={topbarSearch}
              onChange={handleChange}
              placeholder={searchPlaceHolder}
              suffix={() => (
                <>
                  {topbarSearch !== EMPTY_VALUE && (
                    <InputClearValue onClick={handleClear}>
                      <Icon name="x" />
                    </InputClearValue>
                  )}
                </>
              )}
              style={{
                width: 300,
              }}
            />
            <div className="k-form-buttons k-justify-content-end">
              <Button
                fillMode="outline"
                themeColor={'primary'}
                icon="search"
                onClick={searchData}
              >
                Search
              </Button>
            </div>
          </fieldset>
        </div>
      )}
    </div>
  );
};

export default LookupTopbar;
