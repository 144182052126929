/**
 * API endpoint to check the Network Status
 * @returns {Object} API Response
 */
const checkOnlineStatus = async () => {
  let url = process.env.NetworkStatusEndPoint;
  try {
    let res = await fetch(`${url}`, {
      method: 'HEAD',
    });
    return res.status >= 200 && res.status <= 300;
  } catch (error) {
    return false;
  }
};
export default checkOnlineStatus;
