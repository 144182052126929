import React from 'react';
import { CardHeader, CardTitle } from '@progress/kendo-react-layout';

import { TOGGLE_CLOSE_DATATABLE_SLIDER } from '../../../../constants/actionTypes';
/**
 * Heading of Action Slider
 */
function SliderHeader({ title, handleSlide, countDisplay }) {
  return (
    <CardHeader className="k-hbox">
      <CardTitle className="add-header">
        <span
          dangerouslySetInnerHTML={{ __html: title }}
          style={{ fontSize: 'inherit', fontWeight: 'inherit' }}
        />
        {countDisplay && countDisplay}
      </CardTitle>
      <button
        onClick={() => {
          handleSlide({ action: TOGGLE_CLOSE_DATATABLE_SLIDER });
        }}
        className="close-icon"
        type="button"
      >
        <span className="k-icon k-i-close" style={{ float: 'right' }} />
      </button>
    </CardHeader>
  );
}

export default SliderHeader;
