/**
 * Convert Object to Query String.
 * @param {object} obj - An object to convert from object to query string.
 * @returns {string} - Query string sperated by &.
 */
export const objectToQueryString = (obj) => {
  const queryParams = [];

  for (const key in obj) {
    if (Object.prototype.hasOwnProperty.call(obj, key)) {
      queryParams.push(
        `${encodeURIComponent(key)}=${encodeURIComponent(obj[key])}`
      );
    }
  }

  return queryParams.join('&');
};

export default objectToQueryString;
