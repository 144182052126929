// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.k-grid-toolbar {
  flex-direction: row-reverse;
}

.k-toolbar {
  padding: 0 8px 14px 8px;
  border: none;
}`, "",{"version":3,"sources":["webpack://./src/Modules/Core/Components/Heading/Heading.scss"],"names":[],"mappings":"AAAA;EACE,2BAAA;AACF;;AACA;EACE,uBAAA;EACA,YAAA;AAEF","sourcesContent":[".k-grid-toolbar {\n  flex-direction: row-reverse;\n}\n.k-toolbar {\n  padding: 0 8px 14px 8px;\n  border: none;\n}\n"],"sourceRoot":""}]);
// Exports
export default ___CSS_LOADER_EXPORT___;
