import React from 'react';
import { getDataFromStorage } from '../../../../Utils/Storage/handleCacheConfig';
import { DatePicker } from '@progress/kendo-react-dateinputs';
import { BooleanValues } from '../../../../Utils/Lookups/lookupUtility';
import { ROW_LAYOUT } from '../../../../constants/applicationConstants';
import { DateRangeFilter } from '../DateFilters/DateRange/DateRangeFilter';
import { CheckBoxListSelectFilter } from '../../../../Modules/Core/Components/Filter/CheckBoxListSelectFilter';
import { DropdownFilterCell } from '../../../../Modules/Core/Components/DropDownFilter/DropDownFilter';
import { MultiSelectFilterCell } from '../../../../Modules/Core/Components/Filter/MultiSelectFilter';
import { formatDateTime } from '../../../../Utils/Common/utils';
import MultiViewCalenderFilter from '../DateFilters/MultiViewCalender/MultiViewCalenderFilter';

/**
 * Retrieves row sizes from storage or environment variables.
 * @returns {Promise<string>} Promise that resolves to the row size.
 */
export const getRowSizes = async (rowLayoutConfig) => {
  try {
    const rowSize =
      (await getDataFromStorage(
        rowLayoutConfig,
        ROW_LAYOUT,
        process.env.RowLayout
      )) ?? process.env.RowLayout;
    return rowSize;
  } catch (e) {
    return process.env.RowLayout;
  }
};
/**
 * Handles Boolean Values filter cell.
 * @param {*} props
 * @returns JSX.Element
 */
export const BooleanValuesFilterCell = (props) => {
  var booleanValues = BooleanValues();
  return renderFilterCell(props, booleanValues, 'dropdown');
};

/**
 * Handles Date Range Filter cell.
 * @param {*} props
 * @returns JSX.Element
 */
export const DateRangeFilterCell = (props) => <DateRangeFilter {...props} />;
/**
 * Handles Date Range Filter cell.
 * @param {*} props
 * @returns JSX.Element
 */
export const MultiViewCalenderFilterCell = (props) => (
  <MultiViewCalenderFilter {...props} />
);

/**
 * Returns a grid date.
 * @param {*} props
 * @returns {JSX.Element} returns row cell
 */
export const GridDateCell = (props) => {
  let value = '';
  const { dataItem, field } = props;

  if (field?.includes('.')) {
    const fields = field?.split('.');
    value = dataItem[fields[0]][fields[1]];
  } else {
    value = dataItem[field];
  }
  return <td>{formatDateTime(value)}</td>;
};
/**
 * Renders a filter cell based on type.
 * @param {object} props - The props for the filter cell.
 * @param {object} data - The data to filter by.
 * @param {string} type - The type of filter cell to render.
 * @param {boolean} [isDisabled=false] - Whether the filter is disabled.
 * @returns {JSX.Element} - The rendered filter cell.
 */
export const renderFilterCell = (props, data, type, isDisabled = false) => {
  if (props?.filterType === 'dropdown')
    return (
      <DropdownFilterCell
        {...props}
        data={data}
        rowSizes={getRowSizes(props?.rowLayoutConfig)}
      />
    );

  switch (type) {
    case 'multiSelect':
      return (
        <MultiSelectFilterCell
          {...props}
          data={data}
          rowSizes={getRowSizes(props?.rowLayoutConfig)}
        />
      );
    case 'checkboxList':
      return (
        <CheckBoxListSelectFilter
          {...props}
          data={data}
          rowSizes={getRowSizes(props?.rowLayoutConfig)}
          isDisabled={isDisabled}
        />
      );
    default:
      return (
        <DropdownFilterCell
          {...props}
          data={data}
          rowSizes={getRowSizes(props?.rowLayoutConfig)}
        />
      );
  }
};
