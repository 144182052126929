import React from 'react';
/**
 * ImageWithFallback  Class
 * @param {*} hasValue
 * @returns {React.ReactElement} The ImageWithFallback Component
 */
export const ImageWithFallback = (props) => {
  const { src, fallbackSrc, width, height, onClick } = props;
  const [imageSrc, setImageSrc] = React.useState(src);

  /**
   * set Fallback Image
   */
  const onError = () => {
    setImageSrc(fallbackSrc);
  };

  return (
    <div onClick={onClick} key={props?.key}>
      <img
        src={imageSrc}
        onError={onError}
        className={props?.className}
        alt={props?.alt}
        style={{ width, height }}
      />
    </div>
  );
};

export default ImageWithFallback;
