// Reference of the code https://developer.mozilla.org/en-US/docs/Web/API/notification
/**
 * Push Notification
 * @param {*} param0
 */
const PushNotification = (notification) => {
  let { title, description } = notification;
  if (Notification?.permission === 'granted') {
    // If the user granted the permission to get notified.

    const n = new Notification(`${title} ${description}`, { tag: `${title}` });
  } else if (Notification && Notification.permission !== 'denied') {
    // Get the popup to allow/reject notification.
    Notification.requestPermission((status) => {
      // If permission is granted
      if (status === 'granted') {
        const n = new Notification(`${title} ${description} `, {
          tag: `${title}`,
        });
      } else {
        console.log('Permission denied'); // If the user refuses to get notified, we can fallback to console.
      }
    });
  } else {
    console.log('Permission denied'); //  If the user refuses to get notified, we can fallback to console.
  }
};

export default PushNotification;
