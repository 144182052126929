// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.switch-label {
  float: left;
  font-size: 13px;
  margin-right: 14px;
  color: rgba(0, 0, 0, 0.54);
}

.k-switch-off .k-switch-thumb {
  background-color: #ffffff;
  border: 1px solid rgba(0, 0, 0, 0.08);
}

.k-switch-md .k-switch-track {
  width: 38px !important;
  height: 18px !important;
}

.k-switch-md .k-switch-thumb {
  width: 24px !important;
  height: 24px !important;
}

.k-switch-md.k-switch-on .k-switch-thumb-wrap {
  left: calc(100% - 5px + 3px) !important;
}`, "",{"version":3,"sources":["webpack://./src/Modules/Core/Components/FormElements/InputSwitch.scss"],"names":[],"mappings":"AAAA;EACE,WAAA;EACA,eAAA;EACA,kBAAA;EACA,0BAAA;AACF;;AACA;EACE,yBAAA;EACA,qCAAA;AAEF;;AAAA;EACE,sBAAA;EACA,uBAAA;AAGF;;AADA;EACE,sBAAA;EACA,uBAAA;AAIF;;AAFA;EACE,uCAAA;AAKF","sourcesContent":[".switch-label {\n  float: left;\n  font-size: 13px;\n  margin-right: 14px;\n  color: rgba(0, 0, 0, 0.54);\n}\n.k-switch-off .k-switch-thumb {\n  background-color: #ffffff;\n  border: 1px solid rgba(0, 0, 0, 0.08);\n}\n.k-switch-md .k-switch-track {\n  width: 38px !important;\n  height: 18px !important;\n}\n.k-switch-md .k-switch-thumb {\n  width: 24px !important;\n  height: 24px !important;\n}\n.k-switch-md.k-switch-on .k-switch-thumb-wrap {\n  left: calc(100% - 5px - -3px) !important;\n}\n"],"sourceRoot":""}]);
// Exports
export default ___CSS_LOADER_EXPORT___;
