import React from 'react';
import { ListView } from '@progress/kendo-react-listview';
import Loader from '../Loader/Loader';
import './GridListViewBase.scss';

/**
 * Get Craft Estimates Tab Cards for mobile
 * React functional component to render cards for a data table.
 * Handle Card Layout for a list of Craft Estimates items
 * Used to Handle all the operations of Craft Estimates Card
 * Handle Card swipe and next/prev logic.
 * @param {Object} props - Component props
 * @param {Array<Object>} props.cardData - Array of objects representing data to be displayed on cards
 * @param {Object} props.checkedItems - Object containing the selected state of each card
 * @param {Object} props.cardEvents - Object containing card event methods
 * @param {Function} props.scrollHandler - Method to handle scrolling behavior
 * @param {Boolean} props.loading - Flag to indicate if data is still loading
 * @param {String} props.dataTableName - Name of the data table
 * @param {Object} props.selectedState - Object containing selected state of cards
 * @param {Function} props.setSelectedState - Method to set the selected state of cards
 * @param {Object} props.gridMethods - Object containing various methods for the grid
 * @param {Boolean} props.show - Flag to indicate whether to show the cards
 * @param {Object} props.sliderAction - Object containing slider action information
 * @returns {JSX.Element} React component
 */
const GridListViewBase = ({
  items,
  data,
  loading,
  scrollHandler,
  ItemRenderer,
  noRecordText,
}) => {
  /**
   * used to get the value from array by using key name
   * @param {Object} obj
   * @param {String} key
   * @returns
   */
  const getValueByKey = (obj, key) => {
    const keys = key.split('.');
    let value = obj;

    for (let i = 0; i < keys.length; i += 1) {
      if (value && Object.prototype.hasOwnProperty.call(value, keys[i])) {
        value = value[keys[i]];
      } else {
        value = undefined;
        break;
      }
    }

    return value;
  };

  /**
   * Render the list based on items array
   * @param {*} props
   * @returns
   */
  const BaseItemRender = (props) => {
    let item = props.dataItem;
    return (
      <div className="k-listview-item row p-2 border-bottom align-middle">
        {items?.map((obj) => (
          <div className={`col ${obj?.class}`}>
            {getValueByKey(item, obj.field)}
          </div>
        ))}
      </div>
    );
  };
  return (
    <>
      {loading && <Loader />}
      {data?.length > 0 && (
        <div className="grid-list list-wrapper" onScroll={scrollHandler}>
          <ListView
            data={data}
            item={ItemRenderer ?? BaseItemRender}
            style={{
              width: '100%',
            }}
          />
        </div>
      )}
      {((!loading && data?.length <= 0) ||
        (!loading && typeof data === 'undefined')) && (
        <h6 className="no-record"> {noRecordText ?? 'No record found'}</h6>
      )}
    </>
  );
};

export default GridListViewBase;
