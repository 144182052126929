import { getDataTableColumns } from '../Filters/filterUtils';

/**
 * This file handles actions related to data saving and validation.
 * It contains common methods used in grid, list, and card operations.
 *
 */

/**
 * save edit changes
 */
export const save = ({
  gridData,
  selectedState,
  setSelectedState,
  dataTableName,
  checkedDataItems,
  GRID_ID,
  setDataList,
  exitEdit,
  updateDataItem,
}) => {
  // validate Data
  const filteredData = gridData.filter((data) => {
    let matchingState;
    if (Array.isArray(selectedState)) {
      const nonEmptySelectedState = [];
      selectedState.forEach((obj) => {
        if (obj?.id?.length > 0) {
          nonEmptySelectedState.push(obj);
        }
      });
      matchingState = nonEmptySelectedState.find(
        (obj) => obj.id === data[GRID_ID]
      );
    } else {
      matchingState = selectedState[data[GRID_ID]];
    }

    return matchingState;
  });
  if (isValid({ gridData, checkedDataItems, dataTableName, GRID_ID })) {
    const newData = filteredData.map((item) => ({
      ...item,
      inEdit: false,
    }));
    setDataList([...gridData, ...newData]);

    const inEditmode = gridData.filter((item) => item.inEdit === true);
    exitEdit(filteredData, inEditmode > 0);
    setSelectedState([]);
    newData.forEach((newObj) => {
      const matchedObj = gridData.find(
        (obj) => obj[GRID_ID] === newObj[GRID_ID]
      );
      if (matchedObj) {
        // Update the values of the matched object
        Object.keys(newObj).forEach((key) => {
          matchedObj[key] = newObj[key];
        });
      }
    });
    const updatedGridData = gridData.filter((item) => item.inEdit !== true);

    updateDataItem({
      data: updatedGridData,
      tableName: dataTableName,
      isChanged: true,
    });
  } else {
    const newData = filteredData.map((item) => ({
      ...item,
      isChange: true,
    }));
    setDataList([...gridData, ...newData]);
  }
};

/**
 * check if grid row is valid
 * @returns {boolean}
 */
export const isValid = ({
  gridData,
  checkedDataItems,
  dataTableName,
  GRID_ID,
}) => {
  const editedData = gridData?.filter((item) =>
    checkedDataItems.some((checkItem) => checkItem[GRID_ID] === item[GRID_ID])
  );
  const allFieldsHaveData = editedData?.every((item) =>
    rowFieldHaveData({ item, dataTableName })
  );
  if (allFieldsHaveData) return true;
  return false;
};

/**
 * check if grid row has data
 * @param {*} item
 * @returns {boolean}
 */
const rowFieldHaveData = ({ item, dataTableName }) => {
  const columns = getDataTableColumns(dataTableName);
  for (const field in item) {
    if (
      (item[field] === undefined ||
        item[field] === null ||
        item[field] === '') &&
      columns?.filter((f) => f.field === field)[0]?.isRequired === true
    )
      return false;
  }
  return true;
};
