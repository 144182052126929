import { getter } from '@progress/kendo-react-common';

const emailRegex = new RegExp(/\S+@\S+\.\S+/);
const phoneRegex = new RegExp(/^[0-9 ()+-]+$/);

export const emailValidator = (value) => {
  if (!value) {
    return 'Email field is required.';
  }

  if (!emailRegex.test(value)) {
    return 'Email is not in a valid format.';
  }

  return '';
};

export const userNameValidator = (value) => {
  if (!value) {
    return 'User Name is required';
  }

  if (value.length < 5) {
    return 'User name should be at least 5 characters long.';
  }

  return '';
};

export const phoneValidator = (value) => {
  if (!value) {
    return 'Phone number is required.';
  }

  if (!phoneRegex.test(value)) {
    return 'Not a valid phone number.';
  }

  return '';
};
export const requiredValidator = (value) =>
  value ? '' : 'Error: This field is required.';
export const passwordValidator = (value) =>
  value && value.length > 6 ? '' : 'Password must be at least 6 charcater.';
const userNameGetter = getter('username');
const emailGetter = getter('email');
export const formValidator = (values) => {
  const userName = userNameGetter(values);
  const emailValue = emailGetter(values);
  if (userName && emailValue && emailRegex.test(emailValue)) {
    return {};
  }
  return {
    VALIDATION_SUMMARY: 'Please fill in the following fields.',
    username: !userName ? 'User Name is required.' : '',
    email:
      emailValue && emailRegex.test(emailValue)
        ? ''
        : 'Email is required and should be in a valid format.',
  };
};

/**
 * check form is valid7p5l,4m6tne dfwxsz
 * @param {*} param0
 * @returns
 */
export const isFromValid = ({ formData, requiredFields }) => {
  let isValid = true;
  requiredFields?.forEach((field) => {
    if (formData[field] == null || formData[field].length <= 0) {
      isValid = false;
    }
  });
  return isValid;
};

/**
 * Validate Field value is valid or not
 * @param {String} value
 * @returns
 */
export const isValidate = (validateForm, value) => {
  if (value != null) {
    return validateForm && value.length <= 0;
  }
  return validateForm && true;
};
