/**
 * return List of Authenticated Routes
 * @param routes
 * @returns
 */
export const AuthRoutes = async (routes) => {
  return routes.filter((item) => {
    let isAllowed = false;
    let isModule = false;

    /**
     * Validate Access Level of route
     */
    if (isModule || item?.auth_name) {
      return isAllowed;
    }
    return true;
  });
};

/**
 * Check route is authenticated or not
 * @param {*} route
 * @returns
 */
export const isRouteAuthenticated = (route) => {
  let isAllowed = false;
  return (isAllowed = true);
};
