import React from 'react';
import { DatePicker } from '@progress/kendo-react-dateinputs';
import './Grid.scss';
import Loader from '../../Loader/Loader';
import { formatDateTime } from '../../../../../Utils/Common/utils';

/**
 * A React lazy component that renders a data grid.
 * @type {React.LazyExoticComponent<React.ComponentType<any>>}
 */
const DataGrid = React.lazy(() => import('../../DataGrid/DataGrid'));

/**
 * The delay (in milliseconds) before showing a tooltip.
 * @type {number}
 */
const tooltipDelay = 100;

/**
 * The position where tooltips are shown relative to their target element.
 * @type {string}
 */
const tooltipPosition = 'bottom';

/**
 * The position where tooltips are shown relative to their target element.
 * @type {string}
 */
const filterable = true;

/**
 * Settings for the data grid's pager.
 * @type {{buttonCount: number, info: boolean, type: string, pageSizes: boolean, previousNext: boolean}}
 */
const pagerSettings = {
  buttonCount: 5,
  info: true,
  type: 'numeric',
  pageSizes: false,
  previousNext: true,
};

/**
 * Data Grid Wrapper Component
 * @param {*} props
 * @returns {JSX.Element}
 */
const Grid = (props) => {
  const {
    dataTableName,
    gridColumnsList,
    dataItemKey,
    gridColumnMethods,
    filterType,
    setFilterName,
    setGridFilterName,
    gridFilterName,
    dateRangefilter,
    setDateRangeFilter,
    isFilterable,
    inlineEdit,
    isFavourite,
    moduleName,
    handleFavouriteArray,
    noRecord,
    selectable,
    dataColumns,
    isSelectable,
    orderSeqKey,
    setDraggableCallback,
    draggableToggle,
    isGridSortable,
  } = props;
  let storageName = `${dataTableName}OrderColumn`;
  const gridObj = React.useRef(null);
  const [gridColumns, setGridColumns] = React.useState([]);
  const minDate = new Date();
  minDate.setHours(0, 0, 0, 0);
  /** Show Approved rejected Inactive Tab */
  const cellWithBackGround = (props) => {
    const { Status, status } = props.dataItem;
    return (
      <td colSpan={1} title={Status?.Name ?? status ?? ''}>
        <div
          className={`badge ${
            Status?.Name ?? status?.replaceAll(' ', '-')?.toLowerCase() ?? ''
          }`}
        >
          <div
            className={`circle ${
              Status?.Name ?? status.replaceAll(' ', '-')?.toLowerCase() ?? ''
            }`}
          />
          <span>{Status?.Name ?? status} </span>
        </div>
      </td>
    );
  };

  /**
   * Show Description for the grid object
   * @param {*} props
   * @param {*} field the column field object
   * @param {*} key the description field in dropdown
   * @param {*} defaultValue the default value to show if description doesn't exist for the dropdown
   * @returns {JSX.Element}
   */
  const cellWithDescription = (props) => {
    const { field, key, defaultColumn } = props;
    const Item = props.dataItem[field];
    let value = '';
    // if cell is an object then show value based on key
    if (Item) {
      // if description not found then use default value
      value = Item[key] ?? Item[defaultColumn];
    }
    // if cell is field  then show value based on cell
    else if (props.field) value = props.dataItem[props.field] ?? '';

    return (
      <td colSpan={1} title={value}>
        {value ?? ''}
      </td>
    );
  };

  /**
   * Returns a grid date.
   * @param {*} props
   * @returns {JSX.Element} returns row cell
   */
  const gridDateCell = (props) => {
    let value = '';
    const { dataItem, field } = props;

    if (field.includes('.')) {
      const fields = field?.split('.');
      value = dataItem[fields[0]][fields[1]];
    } else {
      value = dataItem[field];
    }
    return (
      <td>
        {dataItem.inEdit && gridColumns[field]?.editable ? (
          <div className="k-filtercell">
            <DatePicker
              min={minDate}
              value={props.value}
              onChange={(e) => {
                props.onChange({
                  dataItem: props.dataItem,
                  field: props.field,
                  value: e.target.value,
                });
              }}
            />
          </div>
        ) : (
          formatDateTime(value)
        )}
      </td>
    );
  };
  /**
   * Get Grid Column List
   */
  React.useEffect(() => {
    (async () => {
      const data = await gridColumnsList({
        cellWithBackGround,
        cellWithDescription,
        gridDateCell,
        tab: dataTableName,
        rowLayoutConfigName: props.rowLayoutConfigName,
        pageLengthConfigName: props.pageLengthConfigName,
        dateRangefilter,
        setDateRangeFilter,
        ...gridColumnMethods,
        gridData: props.data,
      });
      return data;
    })().then((data) => {
      setGridColumns(data);
    });
  }, [props.data, dateRangefilter]);
  return (
    <React.Suspense fallback={<Loader />}>
      <DataGrid
        dataTableName={dataTableName}
        data={props.data}
        total={props.total}
        dataItemKey={dataItemKey}
        storageName={storageName}
        setData={props.setData}
        rowSizes={props.rowSizes}
        pageLength={props.pageLength}
        sort={props.sort}
        setSort={props.setSort}
        setFilter={props.setFilter}
        filter={props.filter}
        resetFilters={props.resetFilters}
        loading={props.loading}
        page={props.page}
        pageChange={props.pageChange}
        checkedItems={props.checkedItems}
        selectedState={props.selectedState}
        exitEdit={props.exitEdit}
        setInEdit={props.setInEdit}
        columns={gridColumns}
        isSelectable={isSelectable}
        ref={gridObj}
        tooltipDelay={tooltipDelay}
        tooltipPosition={tooltipPosition}
        filterable={filterable}
        pagerSettings={pagerSettings}
        inlineEdit={inlineEdit}
        selectable={selectable}
        filterType={filterType}
        selected={props.selected}
        isResizable={true}
        isScrollable={true}
        scrollHandler={props.scrollHandler}
        setFilterName={setFilterName}
        setGridFilterName={setGridFilterName}
        gridFilterName={gridFilterName}
        isFilterable={isFilterable}
        isFavourite={isFavourite}
        moduleName={moduleName}
        handleFavouriteArray={handleFavouriteArray}
        noRecord={noRecord}
        dataColumns={dataColumns}
        orderSeqKey={orderSeqKey}
        setDraggableCallback={setDraggableCallback}
        draggableToggle={draggableToggle}
        isGridSortable={isGridSortable}
      />
    </React.Suspense>
  );
};

export default Grid;
