import * as React from 'react';
import { reorderIcon } from '@progress/kendo-svg-icons';
import { SvgIcon } from '@progress/kendo-react-common';


/**
 * DragHandleCell component represents a custom table cell used for dragging rows in a data grid.
 * It provides a drag handle icon that can be used to reorder rows.
 *
 * @param {Object} props - The properties passed to the component.
 * @param {number} props.ariaColumnIndex - The index of the column for accessibility purposes.
 * @param {Object} props.dataItem - The data item represented by the current row.
 * @param {Function} props.selectionChange - The function to handle selection change events.
 * @param {number} props.columnIndex - The index of the column in the grid.
 * @param {number} props.columnsCount - The total number of columns in the grid.
 * @param {string} props.rowType - The type of the row (e.g., data row or group header).
 * @param {boolean} props.expanded - A flag indicating if the current row is expanded.
 * @param {number} props.dataIndex - The index of the current data item.
 * @param {boolean} props.isSelected - A flag indicating if the row is selected.
 * @param {Object} rest - Additional props to be passed down to the table cell element.
 *
 * @returns {JSX.Element} A table cell (<td>) element containing a drag handle icon.
 */
export const DragHandleCell = (props) => {
  const {
    ariaColumnIndex,
    dataItem,
    selectionChange,
    columnIndex,
    columnsCount,
    rowType,
    expanded,
    dataIndex,
    isSelected,
    // Filter out non-td props from rest
    ...rest
  } = props;

  // Remove any invalid props (like 'render') from `rest`
  const validProps = Object.keys(rest).reduce((acc, key) => {
    // Only include valid props for <td>
    if (key !== 'render') {
      acc[key] = rest[key];
    }
    return acc;
  }, {});

  return (
    <td {...validProps} style={{ touchAction: 'none' }}>
      <span style={{ cursor: 'move' }} data-drag-handle="true">
        <SvgIcon style={{ pointerEvents: 'none' }} icon={reorderIcon} />
      </span>
    </td>
  );
};
