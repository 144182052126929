import React from 'react';

const DataCardHOC = (Component) => (props) => {
  /**
   * A function that filters checked rows and returns an array of objects containing their ids and count.
   * @returns {Array<{id: string, count: number}>} Array of objects containing the ids and count of the checked rows.
   */
  const FilterCheckedRows = () => {
    let checkedRows = [];

    for (var key in props.selectedState) {
      if (props.selectedState[key]?.checked) {
        checkedRows.push({ id: key, count: 0 });
      }
    }
    return checkedRows;
  };

  /** the left slide action */
  const [left, setLeftState] = React.useState(0);
  /** get OriginalOffset */
  const [originalOffset, setOriginalOffset] = React.useState(0);
  /** get slide velocity */
  const [velocity, setVelocity] = React.useState(0);
  /** get time when card dragged */
  const [timeOfLastDragEvent, setTimeOfLastDragEvent] = React.useState(0);
  /** get when touch card started slide velocity */
  const [touchStartX, setTouchStartX] = React.useState(0);
  /** set selected element */
  const [selectedElement, setSelectedElement] = React.useState(null);
  /** set selected card */
  const [cardSelected, setCardSelected] = React.useState(FilterCheckedRows());
  /** set previously selected item */
  const [prevTouchX, setPrevTouchX] = React.useState(0);
  /** set if card is touched */
  const [beingTouched, setBeingTouched] = React.useState(false);
  /** set interval id */
  const [intervalId, setIntervalId] = React.useState(null);

  /**
   * Set selected Data List in State Variable
   */
  const setSelectedList = () => {
    const checkedlist = {};
    cardSelected.map((dataList) => {
      checkedlist[dataList.id] = { ...dataList, checked: true };
    });
    props.setSelectedState(checkedlist);
  };

  /**
   * Animate Card back to normal position after selection
   */
  const animateSlidingToZero = () => {
    let { cleft, cbeingTouched, cvelocity } = { left, beingTouched, velocity };
    if (!cbeingTouched && cleft < -0.01) {
      cvelocity += 10 * 0.033;
      cleft += cvelocity;
      if (cleft < -350) {
        window.clearInterval(intervalId);
      }
      setLeftState(cleft);
      setVelocity(cvelocity);
    } else if (!cbeingTouched) {
      cleft = 0;
      cvelocity = 0;
      window.clearInterval(intervalId);
      if (selectedElement) {
        selectedElement.style.left = cleft;
        selectedElement
          .closest('.list-card')
          ?.classList?.remove('animated', 'swipe-left', 'swipe-right');
        setSelectedElement(null);
      }

      setLeftState(cleft);
      setVelocity(cvelocity);
      setIntervalId(null);
      setOriginalOffset(0);
    }
  };

  /**
   * Make element selected
   */
  const handleElementSelected = () => {
    window.setTimeout(() => {
      const isLeft = left < -100;
      const isRight = left > 220;

      /**
       * Add Element to selected element array
       * if it's swipe to left and right
       */
      if (selectedElement && (isLeft || isRight)) {
        const id = selectedElement.getAttribute('data-id');
        // check if card is already selected
        const isCardSelected = cardSelected?.find((card) => card.id === id);
        if (isCardSelected) {
          /**
           * Increment/Decrement the swipe count
           */
          const selectedCards = cardSelected.map((f) => {
            if (f.id === id) {
              if (f?.count >= 0) {
                if (isLeft) {
                  f.count += 1;
                } else if (isRight) {
                  f.count -= 1;
                }
              } else {
                f.count = 1;
              }
            }
            return f;
          });
          setCardSelected(selectedCards);
        } else if (isLeft) {
          const selectedObj = { id, count: 1 };
          setCardSelected([...cardSelected, selectedObj]);
        }
        setSelectedList();
      }
    }, 250);
  };

  /**
   * Handle Touch Start Event
   * @param {*} clientX
   */
  const handleStart = (clientX) => {
    if (intervalId !== null) {
      window.clearInterval(intervalId);
    }
    setOriginalOffset(left);
    setVelocity(0);
    setTimeOfLastDragEvent(Date.now());
    setTouchStartX(clientX);
    setBeingTouched(true);
    setIntervalId(null);
  };

  /**
   * Handle Touch Drag Event
   * @param {*} clientX
   */
  const handleMove = (clientX) => {
    if (beingTouched) {
      const touchX = clientX;
      const currTime = Date.now();
      const elapsed = currTime - timeOfLastDragEvent;
      const velocity = (20 * (touchX - prevTouchX)) / elapsed;
      let deltaX = touchX - touchStartX + originalOffset;
      let className = '';
      /**
       * check the card Swipe vale
       * if it's greater then 0 then card swiped at right side
       * if it's less then 0 then card swiped at left side
       */
      if (deltaX > 0) {
        className = 'swipe-right';
        selectedElement?.closest('.list-card')?.classList?.remove('swipe-left');
      } else {
        className = 'swipe-left';
        selectedElement
          ?.closest('.list-card')
          ?.classList?.remove('swipe-right');
      }
      setLeftState(deltaX);

      /**
       * Swipe selected card to left or right
       */
      if (selectedElement) {
        selectedElement.style.left = `${deltaX}px`;
        selectedElement.style.left = `${deltaX}px`;
        selectedElement
          .closest('.list-card')
          ?.classList?.add('animated', className);
      }

      setVelocity(velocity);
      setTimeOfLastDragEvent(currTime);
      setPrevTouchX(touchX);
    }
  };

  /**
   * Handle Touch End Event
   */
  const handleEnd = () => {
    handleElementSelected();
    setVelocity(velocity);
    setTouchStartX(0);
    setBeingTouched(false);
    animateSlidingToZero();
  };

  /**
   * Handle touch start event
   * @param {*} touchStartEvent
   */
  const handleTouchStart = (touchStartEvent) => {
    setTouchStartX(touchStartEvent.targetTouches[0].clientX);

    handleStart(touchStartEvent.targetTouches[0].clientX);
  };

  /**
   * Handle touch move event
   * @param {*} touchStartEvent
   */
  const handleTouchMove = (touchMoveEvent) => {
    if (touchMoveEvent?.touches?.length <= 1) {
      setSelectedElement(
        touchMoveEvent.targetTouches[0].target.closest('.swipeItem')
      );
      handleMove(touchMoveEvent.targetTouches[0].clientX);
    }
  };

  /**
   * Handle touch end event
   */
  const handleTouchEnd = () => {
    handleEnd();
  };

  /**
   * Handle mouse down event
   * @param {*} mouseDownEvent
   */
  const handleMouseDown = (mouseDownEvent) => {
    setTouchStartX(mouseDownEvent.clientX);
    handleStart(mouseDownEvent.clientX);
    // removed to enable selection of cards, mouseDownEvent.preventDefault();
  };

  /**
   * Handle mouse move event
   * @param {*} mouseMoveEvent
   */
  const handleMouseMove = (mouseMoveEvent) => {
    if (beingTouched) {
      setSelectedElement(mouseMoveEvent.target.closest('.swipeItem'));
      handleMove(mouseMoveEvent.clientX);
    }
  };

  /**
   * Handle mouse up event
   */
  const handleMouseUp = () => {
    handleEnd();
  };

  /**
   * Handle mouse leave event
   */
  const handleMouseLeave = () => {
    handleMouseUp();
  };

  React.useEffect(() => {
    // push Page on right when vertical toolbar shown
    const header = document.querySelector('.page');
    if (cardSelected.length > 0) {
      header?.classList?.add('push-page');
    } else {
      header?.classList?.remove('push-page');
    }
    (async () => {
      setSelectedList();
    })();
  }, [cardSelected]);

  const args = {
    cardEvents: {
      cardSelected,
      setCardSelected,
      handleTouchStart,
      handleTouchMove,
      handleTouchEnd,
      handleMouseDown,
      handleMouseMove,
      handleMouseUp,
      handleMouseLeave,
    },
  };
  return <Component {...args} {...props} />;
};

export default DataCardHOC;
