/**
 * Creates a debounced function that delays invoking the provided function until after `ms` milliseconds have elapsed since the last time it was called.
 * it prevents loops by setting a clear timeout
 * @param {Function} fn - The function to debounce.
 * @param {number} ms - The number of milliseconds to delay before invoking the debounced function.
 * @returns {Function} - A debounced version of the provided function.
 */
const debounce = (fn, ms) => {
  let timer;
  return (_) => {
    clearTimeout(timer);
    timer = setTimeout((__) => {
      timer = null;
      fn.apply(this);
    }, ms);
  };
};

/**
 * A component that handles window resize events and updates state accordingly.
 * @param {Object} props - The component's props.
 * @param {Function} props.setDimensions - A function to update the dimensions state.
 * @param {Function} props.setDeviceInfo - A function to update the device info state.
 * @param {Function} props.resizeWorker - A function that returns device info based on current dimensions.
 * @returns {Function} - A cleanup function to remove the event listener when the component unmounts.
 */
const ResizeHandler = ({ setDimensions, setDeviceInfo, resizeWorker }) => {
  const delay = 250;

  /**
   * when multiple requests are sent, it prevents loops
   * A debounced function that updates state based on the current window dimensions and device info.
   * @function
   */
  const debouncedHandleResize = debounce(() => {
    setDimensions({
      height: window.innerHeight,
      width: window.innerWidth,
    });
    setDeviceInfo(resizeWorker());
  }, delay);

  /**
   * Adds an event listener for window resize events.
   * @function
   */ window.addEventListener('resize', debouncedHandleResize);
  return (_) => {
    /**
     * Removes the event listener for window resize events.
     * @function
     */
    window.removeEventListener('resize', debouncedHandleResize);
  };
};

export default ResizeHandler;
