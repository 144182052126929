import React from 'react';
import {
  TOGGLE_FILTER_DATATABLE_SLIDER,
  TOGGLE_SORT_DATATABLE_SLIDER,
} from '../../../../../constants/actionTypes';
import Loader from '../../Loader/Loader';

const FilterSlider = React.lazy(() => import('../Filter/Filter'));
const SortSlider = React.lazy(() => import('../Sort/Sort'));

const Slider = React.lazy(() => import('../../Slider/Slider'));
import {
  getMappedFilters,
  countObjectsFrequency,
} from '../../../../../Utils/Filters/filterUtils';

/**
 * Used to populate the Slider Card Content based on the provided action
 * @param {*} Component
 * @returns {Slider}
 */
const ContentSliderHoc = (Component) =>
  React.forwardRef((props, ref) => {
    const {
      lookups, // lookups for filter dropdown list
      setLookups, // set lookups
      dataTableName, // selected tab Name
      setNamedFilter, // et list of named filters
      namedFilter, // list of named filters
      setFilterName, // set named filter
      filterName, // selected named filter
      actionType,
      setActionType,
      deleteFilter,
      saveFilters,
      applyFilter,
      resetSort,
      moduleName,
      handleSlide,
      show,
      gridColumnsList,
      dataColumns,
      dateRangefilter,
      setDateRangeFilter,
      title,
      gridFilterName,
      setGridFilterName,
    } = props;
    /** Set Action Type */
    const [actions, setAction] = React.useState('');

    React.useImperativeHandle(
      ref,
      () => ({
        actions,
        setAction,
        handleSlide,
      }),
      [actions]
    );
    const actionSlidersMap = {
      [TOGGLE_FILTER_DATATABLE_SLIDER]: (
        <React.Suspense fallback={<Loader />}>
          <FilterSlider
            gridColumnsList={gridColumnsList}
            moduleName={moduleName}
            handleSlide={handleSlide}
            title={`<span class="k-icon k-font-icon k-i-filter k-button-icon" style="font-size: inherit;font-weight: inherit;" ></span> ${title}`}
            setFilter={props.setFilter}
            filter={props.filter}
            selected={props.selected}
            getFilter={props.getFilter}
            lookups={lookups}
            setLookups={setLookups}
            filterName={filterName}
            setFilterName={setFilterName}
            setNamedFilter={setNamedFilter}
            namedFilter={namedFilter}
            setSort={props.setSort}
            sort={props.sort}
            dataTableName={dataTableName}
            saveFilters={saveFilters}
            actionType={actionType}
            setActionType={setActionType}
            applyFilter={applyFilter}
            deleteFilter={deleteFilter}
            filterChange={props.filterChange}
            dateRangefilter={dateRangefilter}
            setDateRangeFilter={setDateRangeFilter}
            getMappedFilters={getMappedFilters}
            countObjectsFrequency={countObjectsFrequency}
            dataColumns={dataColumns}
            gridFilterName={gridFilterName}
            setGridFilterName={setGridFilterName}
          />
        </React.Suspense>
      ),
      [TOGGLE_SORT_DATATABLE_SLIDER]: (
        <React.Suspense fallback={<Loader />}>
          <SortSlider
            moduleName={moduleName}
            initialSort={props.initialSort ?? {}}
            dataTableName={dataTableName}
            handleSlide={handleSlide}
            title={`Sort ${title}`}
            setSort={props.setSort}
            sort={props.sort}
            resetSort={resetSort}
            dataColumns={dataColumns}
          />
        </React.Suspense>
      ),
    };
    /** Get Slider Children */
    const getChildren = () => {
      if (show) {
        return actionSlidersMap[actions.action];
      }
      return null;
    };
    const children = getChildren();
    const args = {
      actions,
      setAction,
      children,
      actionSlidersMap,
      resetSort,
    };
    return (
      <div className="dataTable-slider">
        <Component {...args} {...props} ref={ref} />
      </div>
    );
  });
export default ContentSliderHoc;
