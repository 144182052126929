import * as React from 'react';
import { GlobalContext } from '../../Context/GlobalContextState';
import checkOnlineStatus from '../../../../Utils/NetworkStatus/checkOnlineStatus';
import { EVENTS_DATA_TYPES } from '../../../../constants/eventDataTypes';

const NetworkStatus = () => {
  const { toggleNetworkConnectivity, isNetworkConnected } =
    React.useContext(GlobalContext);

  /**
   * Toggle network connectivity.
   */
  const updateNewtorkStatus = async () => {
    const result = await checkOnlineStatus();
    if ('serviceWorker' in navigator) {
      if (navigator.serviceWorker.controller) {
        navigator.serviceWorker.controller.postMessage({
          type: EVENTS_DATA_TYPES.NETWORK_STATUS,
          isNetworkConnected: result,
        });
      }
    }
    toggleNetworkConnectivity(result);
  };

  // #region  Offline event triggers automatically when network goes offline.
  window.onoffline = () => {
    toggleNetworkConnectivity(false);
  };
  // #endregion

  // #region  Online event triggers automatically when network goes Online.
  window.ononline = async () => {
    await updateNewtorkStatus();
  };
  // #endregion

  // #region  Periodicaly check the network status by sending network call.
  React.useEffect(() => {
    (async () => {
      await updateNewtorkStatus();
    })();
    const interval = setInterval(async () => {
      await updateNewtorkStatus();
    }, process.env.NETWORK_STATUS_INTERVAL);
    return () => clearInterval(interval); // This represents the unmount function, in which you need to clear your interval to prevent memory leaks.
  }, []);
  // #endregion
  return (
    <>
      {
        <span
          className={`${isNetworkConnected ? 'sis-ico-wifi' : 'sis-ico-nowifi'}`}
        />
      }
    </>
  );
};

export default NetworkStatus;
