import React from 'react';
import { FieldWrapper } from '@progress/kendo-react-form';
import { Error, Hint, Label } from '@progress/kendo-react-labels';
import { DatePicker } from '@progress/kendo-react-dateinputs';
import moment from 'moment';
import { RenderTypes } from '../../../../constants/applicationConstants';
import { formatDateTime } from '../../../../Utils/Common/utils';

/**
 * DatePickerInput component of Date Picker field
 * DatePickerInput component that renders a form datepicker field
 * @param {object} fieldRenderProps - the properties for the field datepicker component
 * @returns {JSX.Element} - the DatePickerInput component
 */
export const DatePickerInput = (fieldRenderProps) => {
  const {
    validationMessage,
    touched,
    label,
    id,
    valid,
    disabled,
    hint,
    type,
    required,
    optional,
    renderTypes,
    readOnly,
    data,
    value,
    showValidationMessage,
    min,
    max,
    ...others
  } = fieldRenderProps;
  let dateValue = data;

  if (min && data && data < min) {
    // set minimum value
    dateValue = min;
  } else if (max && data && data > max) {
    // set maximum value
    dateValue = max;
  }

  const setValidationMessage =
    (touched && validationMessage) || showValidationMessage;
  const showHint = !setValidationMessage && hint;
  const hintId = showHint ? `${id}_hint` : '';
  const errorId = setValidationMessage ? `${id}_error` : '';
  return (
    <FieldWrapper>
      {label && (
        <span className="k-floating-label-container k-label">
          <Label
            editorId={id}
            editorValid={valid}
            editorDisabled={disabled}
            optional={optional}
          >
            {label}
          </Label>
        </span>
      )}
      {renderTypes === RenderTypes.ReadOnly ? (
        <span className="display-text">
          {data ? moment(data).format('MM/DD/YYYY') : ''}
        </span>
      ) : (
        <div className={'k-form-field-wrap'}>
          <DatePicker
            value={dateValue}
            valid={valid}
            type={type}
            max={max ? max : undefined}
            min={min ? min : undefined}
            id={id}
            {...others}
            required={required}
          />
          {showHint && <Hint id={hintId}>{hint}</Hint>}
          {setValidationMessage && (
            <Error id={errorId}>{validationMessage}</Error>
          )}
        </div>
      )}
    </FieldWrapper>
  );
};

export default DatePickerInput;
