import moment from 'moment';
import { getMappedFilters, getVariableType } from '../Filters/filterUtils';
import {
  fetchCacheConfig,
  getCacheConfig,
  setCacheConfig,
} from '../Storage/handleCacheConfig';

/**
 * Get Filter From Storage
 * @async
 * @param {string} moduleName - The name of the module to retrieve configuration for.
 * @param {*} dataTable - The name of the dataTable to retrieve configuration for.
 * @returns {Promise<object|null>} The configuration object or null if not found.
 */
export const getFilterFromStorage = async ({ module, dataTable }) =>
  fetchCacheConfig(`${module}.${dataTable}.Filter`, 'filters') ?? [];

/**
 * Get Sort From Storage
 * @async
 * @param {string} moduleName - The name of the module to retrieve configuration for.
 * @param {*} dataTable - The name of the dataTable to retrieve configuration for.
 * @returns {Promise<object|null>} The configuration object or null if not found.
 */
export const getSortFromStorage = async (module, dataTable) =>
  fetchCacheConfig(`${module}.${dataTable}.Filter`, 'sort') ?? [];

/** Get Filters as Per Datatable */
export const getFilter = async ({
  dataTable,
  module,
  dataColumns,
  reset = false,
}) => {
  const filterArray = {
    logic: 'and',
    filters: [],
  };

  // check if localStorage has filters
  const filters = await getFilterFromStorage({
    module,
    dataTable,
  });
  if (filters && filters.length > 0 && !reset) {
    // map filters with types from data columns array
    const mappedFilters = getMappedFilters({ dataColumns, filters });
    var filtersArray = ProcessDateFilters(mappedFilters);
    filterArray.filters = filtersArray;
  }
  return filterArray;
};

export const resetFilterAndSortFromStorage = async (
  moduleName,
  dataTableName
) => {
  let getFilterData = await getCacheConfig(
    `${moduleName}.${dataTableName}.Filter`
  );
  getFilterData.filters = [];
  getFilterData.sort = [];
  setCacheConfig(`${moduleName}.${dataTableName}.Filter`, getFilterData);
};

/**
 * Convert Date Filter
 * @param {*} filterArray
 * @returns
 */
export const ProcessDateFilters = (filtersList) => {
  var filtersArray = [];
  filtersList.map((item) => {
    const variableType = getVariableType(item?.type ?? 'text', item?.value);
    if (variableType) {
      item.value =
        variableType === 'Date'
          ? moment(item.value).format('YYYY-MM-DD')
          : item.value;
      filtersArray.push({
        field: item.field,
        operator: item.operator,
        value: variableType === 'Date' ? new Date(item.value) : item.value,
      });
    }
  });
  return filtersArray;
};
