import React from 'react';
import { Slide } from '@progress/kendo-react-animation';
import './Slider.scss';

const Slider = (props) => (
  <div>
    <Slide direction="left" style={{ float: 'right' }} className="slider">
      {props.show && (
        <>
          <div className="overlay" />
          <div className="slider-content-card">{props.cardBody}</div>
        </>
      )}
    </Slide>
  </div>
);

export default Slider;
