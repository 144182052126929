import {
  EDIT_DATA,
  UPDATE_DATA_LIST,
  GET_DATA,
  GET_DATA_LIST,
  INIT_DATA_LIST,
  CHECKED_ITEM_DATA_LIST,
  GET_DATA_BY_ID,
  SELECTED_STATE,
  SET_DATA_KEY,
} from '../../../../constants/actionTypes';
/** DataTable Reducer it's accept state and action and returning a new state */
const reducer = (state, action) => {
  const { payload, type } = action;
  switch (type) {
    /**
     * Used to Update The DataTable State by appending the new Item Data.
     */
    case GET_DATA_LIST: {
      let mapData = [
        ...new Map(
          [...state.dataList, ...action.payload].map((item) => [
            item[state.DataIdKey],
            item,
          ])
        ).values(),
      ];
      return {
        ...state,
        dataList: [...mapData],
        total: [action?.payload?.total],
      };
    }

    /**
     * Used to Intialize the DataTable Data State
     */
    case INIT_DATA_LIST: {
      return {
        ...state,
        dataList: [...action.payload],
      };
    }

    /**
     * Used to Update The DataList State by just appending the Single item Data
     */
    case GET_DATA: {
      return {
        ...state,
        dataList: [...state.dataList, action.payload],
        total: [action.payload.total],
      };
    }

    /**
     * Used to Update the Single Data Item
     */
    case EDIT_DATA: {
      const updatedData = action.payload;
      const updatedDataList = state.dataList.map((data) => {
        if (data[state.DataIdKey] === updatedData[state.DataIdKey]) {
          return updatedData;
        }
        return data;
      });

      return {
        ...state,
        dataList: updatedDataList,
      };
    }

    /**
     * Used to Update the data in bulk.
     */
    case UPDATE_DATA_LIST: {
      const updatedDataList = state.dataList.map((item) => {
        const item2 = action?.payload?.find(
          (i2) => i2[state.DataIdKey] === item[state.DataIdKey]
        );
        return item2 ? { ...item, ...item2 } : item;
      });

      return {
        ...state,
        dataList: [...updatedDataList],
      };
    }

    /**
     * return the specfied ID Data List
     */
    case GET_DATA_BY_ID: {
      const dataId = payload;
      const data =
        state?.dataList?.filter(
          (data) => data[state.DataIdKey] === dataId
        )[0] ?? {};
      if (data) return data;
      return null;
    }

    /**
     * Used to Set the Value of checkedDataItems state.
     */
    case CHECKED_ITEM_DATA_LIST:
      return {
        ...state,
        checkedDataItems: action.payload,
      };

    /**
     * Used to Set the Value of SELECTED_STATE.
     */
    case SELECTED_STATE:
      return { ...state, selectedState: action.payload };

    /**
     * Used to Set Data id key according data set
     */
    case SET_DATA_KEY:
      return { ...state, DataIdKey: action?.payload };
    default:
      return state;
  }
};
export default reducer;
