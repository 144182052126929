import * as React from 'react';
import { DropDownList } from '@progress/kendo-react-dropdowns';
import { Button } from '@progress/kendo-react-buttons';
import { Hint } from '@progress/kendo-react-labels';
import { filterBy, orderBy } from '@progress/kendo-data-query';

/**
 * DropDownFilter For Inline Grid Operations Cell Class
 * @typedef {object} Props
 * @property {object} dataItem - The data item that is being edited.
 * @property {boolean} required - Whether the field is required or not.
 * @property {array} data - The data of the grid.
 * @property {boolean} isUnique - Whether the data of the field is unique or not.
 * @property {string} field - The name of the field.
 * @property {array} data - The data of the dropdown.
 * @returns {JSX.Element} - A dropdown component with filtered and localized data.
 */
export const DropDownFilter = (props) => {
  const { dataItem, required, isUnique } = props;

  const [filterValue, setFilterValue] = React.useState('');
  const [data, setData] = React.useState([]);
  React.useEffect(() => {
    setData(props.data);
  }, [props.data]);
  const defaultItem = { value: '', label: '(EMPTY)' };
  const field = props.field || '';

  const dataValue = dataItem[field] === null ? '' : dataItem[field];

  /**
   * Returns the filtered data for the dropdown.
   *  @returns {array} - The filtered data for the dropdown.
   */
  const getUniqueData = () => {
    let gridDropdownData = props?.data.filter(
      (item) => item[field] !== dataItem[field]
    );
    gridDropdownData = gridDropdownData.map((item) => item[field]);
    const filterdData = props.data.filter(
      (item) => !gridDropdownData.includes(item.value)
    );
    return filterdData;
  };
  let dropdownData = props.data;

  const rowLayout = `${props?.rowSizes} dd-list-item`;

  React.useEffect(() => {
    if (isUnique) {
      dropdownData = getUniqueData();
    }
    setData(dropdownData);
  }, [props.data]);
  /**
   * handle blur
   */
  const handleBlur = () => {
    setData(props.data);
    setFilterValue('');
  };

  /**
   * handle drop down Close
   */
  const handleClose = () => {
    setData(props.data);
    setFilterValue('');
  };
  /**
   * Handle change event on dropdown filter
   * @param {Object} e
   */
  const handleChange = (e) => {
    let newDataItem = {};
    if (props.onChange) {
      if (props.changeDataItem) {
        newDataItem = props.changeDataItem(e);
      } else {
        newDataItem = props.dataItem;
      }
      props.onChange({
        dataIndex: 0,
        dataItem: newDataItem,
        field: props.field,
        syntheticEvent: e.syntheticEvent,
        value: e.target.value.value,
      });
    }
  };
  /**
   * Clear Filters
   * @param {*} event
   */
  const onClearButtonClick = (event) => {
    event.preventDefault();
    dataItem[field] = '';
    if (props.onChange) {
      props.onChange({
        dataIndex: 0,
        dataItem: props.dataItem,
        field: props.field,
        syntheticEvent: event.syntheticEvent,
        value: '',
      });
    }
  };

  /**
   * List No Data render
   * @param {*} element
   * @returns
   */
  const listNoDataRender = (element) => {
    const noData = (
      <div className="k-list k-list-md">
        <div className="k-nodata">
          <div>{props?.noDataText ?? 'No DATA FOUND'}</div>
        </div>
      </div>
    );
    return React.cloneElement(
      element,
      {
        ...element.props,
      },
      noData
    );
  };

  /**
   * drop down list items renderer
   * @param {*} li list of elements
   * @returns rendered options
   */
  const itemRender = (li) => {
    const itemChildren = <span className={rowLayout}>{li.props.children}</span>;
    return React.cloneElement(li, li.props, itemChildren);
  };

  /**
   * filter change
   * @param {*} event
   */
  const filterChange = (event) => {
    setFilterValue(event.filter.value);
    const filterData = filterBy(getUniqueData().slice(), event.filter);
    setData(filterData);
  };
  // Returns a dropdown component with filtered and localized data.
  return (
    <td>
      {dataItem.inEdit ? (
        <>
          <div className="k-filtercell">
            <DropDownList
              required={
                required && !dataValue && dataItem.isChange ? true : false
              }
              textField="label"
              dataItemKey="value"
              defaultItem={defaultItem}
              value={props.data.find((c) => c.value === dataValue)}
              data={orderBy(data?.filter((f) => f.label && f.value) ?? [], [
                { dir: 'asc', field: 'label' },
              ])}
              onChange={handleChange}
              filter={filterValue}
              itemRender={itemRender}
              listNoDataRender={listNoDataRender}
              allowCustom={true}
              filterable={true}
              onFilterChange={filterChange}
              onBlur={handleBlur}
              onClose={handleClose}
            />
            {!(props?.hideClear ?? false) && (
              <Button
                title="Clear"
                disabled={!dataValue}
                onClick={onClearButtonClick}
                icon="filter-clear"
              />
            )}
          </div>
          <Hint className="k-form-error">
            {required && !dataValue && dataItem.isChange
              ? 'Please select a value'
              : ''}
          </Hint>
        </>
      ) : (
        dataValue?.toString()
      )}
    </td>
  );
};
export default DropDownFilter;
