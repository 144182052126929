import moment from 'moment';
import {
  getDatafromDB,
  bulkUpdateInDB,
  deleteDataInDB,
} from '../../Modules/Core/Components/DataTable/Service/namedFiltersService';
import {
  errorGenerator,
  messageGenerator,
} from '../Notifications/notificationUtils';
import { getCacheConfig, setCacheConfig } from '../Storage/handleCacheConfig';
import { TOGGLE_FILTER_DATATABLE_SLIDER } from '../../constants/actionTypes';

/**
 * Used to add save name filter and
 * also update the already added filter
 * @param {String} filterTitle
 * @param {String} action
 * @param {Array} filters
 * @param {Boolean} isSlide
 */
export const saveFilters = async ({
  filterTitle,
  action,
  filters,
  isSlide,
  isFilterUpdated,
  isApply,
  filterName,
  setNamedFilter,
  setFilterName,
  setActionType,
  setFilter,
  setSort,
  sort,
  setGridFilterName,
  handleSlide,
  moduleName,
  dataTableName,
  setGridDateRangeFilter,
  getMappedFilters,
  countObjectsFrequency,
  dataColumns,
}) => {
  try {
    // If Filter name is empty show warning */
    if (filterTitle?.trim()?.length > 0) {
      const getFilterData = await getDatafromDB(moduleName, dataTableName);
      // create named filter object for dexie db
      let tempDateRangeFilter = [];
      /**
       * parse Date Range Object from single element to two different element
       * validate start and end range is selected
       */
      let normalFilters = filters.filter((obj) => {
        if (Array.isArray(obj?.value)) {
          /**
           * Check Data Type of object
           */
          const mappedFilters = getMappedFilters({
            dataColumns,
            filters: obj?.value,
          });
          const isDateRangeFilters = mappedFilters?.filter(
            (tempObj) => tempObj.type === 'dateRange'
          );
          if (isDateRangeFilters?.length > 0) {
            const tempArray = countObjectsFrequency(obj?.value);

            tempDateRangeFilter = [...tempDateRangeFilter, ...tempArray];
            return false;
          }
        }
        return true;
      });
      /**
       * Fitler out the Unique item of Date Range
       */
      const uniqueFilterItem = tempDateRangeFilter.reduce((acc, curr) => {
        let existing = acc.find((item) => item.field === curr.field);
        if (!existing) {
          acc.push({ field: curr.field, operator: curr.operator });
        }
        return acc;
      }, []);

      /**
       * Remove Duplicate item from filters
       */
      uniqueFilterItem.forEach((obj) => {
        normalFilters = normalFilters.filter(
          (nobj) => nobj.field !== obj.field
        );
      });
      if (tempDateRangeFilter?.length > 0) {
        const mappedFilters = getMappedFilters({
          dataColumns,
          filters: tempDateRangeFilter,
        });

        tempDateRangeFilter = mappedFilters?.map((obj) => {
          if (obj.type === 'dateRange' || obj.type === 'Date') {
            obj.value = moment(obj?.value).format('YYYY-MM-DD');
          }
          const { type, ...item } = obj;
          return item;
        });
      }

      let ParsedFilters = [...normalFilters, ...tempDateRangeFilter];

      const filter = {
        FilterName: `${filterTitle}`,
        Filters: ParsedFilters ?? [],
        Sort: action === 'edit' ? filterName?.Sort : sort,
      };

      let filterData = [];

      /**
       * Used validate the filter is update or not.
       * if filters are updated then data will be save/update in storage
       */
      if (isFilterUpdated) {
        if (action === 'edit') {
          getFilterData?.map((item) => {
            if (item?.FilterName === filterName?.FilterName) {
              filterData.push(filter);
            } else {
              filterData.push(item);
            }
          });
          bulkUpdateInDB(moduleName, filterData, dataTableName);
        } else {
          filterData = [filter];
          getFilterData?.map((item) => {
            filterData.push(item);
          });
          // append updated value in filters list
          bulkUpdateInDB(moduleName, filterData, dataTableName);
        }
      } else {
        filterData = [filter];
        getFilterData?.map((item) => {
          filterData.push(item);
        });
      }

      setNamedFilter(filterData);
      setFilterName(filter);
      applyFilter(filter, {
        filterName,
        setFilter,
        setSort,
        setGridFilterName,
        handleSlide,
        dataTableName,
        moduleName,
        setGridDateRangeFilter,
        isApply,
        isSlide,
      });

      /**
       * used to check we have to close the slide or not after saving the record
       */
      if (isApply) {
        handleSlide({ action: TOGGLE_FILTER_DATATABLE_SLIDER });
      }
      if (!isApply) {
        messageGenerator({
          title: action === 'edit' ? 'Filter updated' : 'Filter saved',
          message: `Filter ${filterTitle ?? ''} ${
            action === 'edit' ? 'updated' : 'saved'
          } successfully`,
          style: 'success',
        });
      }

      setActionType('edit');
    } else if (isApply) {
      applyFilter(
        {
          Filters: filters ?? [],
          Sort: sort,
        },
        {
          filterName,
          setFilter,
          setSort,
          setGridFilterName,
          handleSlide,
          dataTableName,
          moduleName,
          setGridDateRangeFilter,
          isApply,
        }
      );
    } else {
      messageGenerator({
        title: 'Unable to save filter',
        message: 'Filter name cannot be empty.',
        style: 'warning',
      });
    }
  } catch (error) {
    const { message } = error;
    errorGenerator({ message });
  }
};

/**
 * apply Filter
 * @param {*} e
 */
export const applyFilter = async (
  nameFilterObj,
  {
    handleSlide,
    filterName,
    setFilter,
    setGridFilterName,
    dataTableName,
    moduleName,
    setGridDateRangeFilter,
    isApply,
  }
) => {
  let activeNameFilter = '';
  /**
   * get the active filter value
   */
  if (nameFilterObj) {
    activeNameFilter = nameFilterObj;
  } else if (filterName?.Filter) {
    activeNameFilter = filterName;
  }
  /**
   * set Active filter in main filter state.
   * set the selected name filter in storage
   */

  setGridDateRangeFilter(
    activeNameFilter?.Filters?.filter((obj) => obj.type === 'dateRange') ?? []
  );
  try {
    setFilter({
      logic: 'and',
      filters: activeNameFilter?.Filters ?? [],
    });
    const gridFilterData = await getCacheConfig(
      `${moduleName}.${dataTableName}.Filter`
    );

    setGridFilterName(activeNameFilter);
    if (isApply) {
      if (activeNameFilter?.FilterName) {
        gridFilterData.filters = activeNameFilter?.Filters;
        gridFilterData.SelectedNamedFilter = activeNameFilter?.FilterName;
        setCacheConfig(`${moduleName}.${dataTableName}.Filter`, gridFilterData);
        messageGenerator({
          title: 'Filter applied',
          message: `Filter ${activeNameFilter?.FilterName} applied  successfully`,
          style: 'success',
        });
      } else {
        gridFilterData.filters = [];
        gridFilterData.SelectedNamedFilter = '';
        setCacheConfig(`${moduleName}.${dataTableName}.Filter`, gridFilterData);
      }
      handleSlide({ action: TOGGLE_FILTER_DATATABLE_SLIDER });
    }
  } catch (error) {
    const { message } = error;
    errorGenerator({ message });
  }
};

/**
 * delete Filter
 * @param {*} e
 */
export const deleteFilter = async ({
  moduleName,
  dataTableName,
  filterName,
  setFilterName,
  gridFilterName,
  setGridFilterName,
  setNamedFilter,
  setGridDateRangeFilter,
  setFilter,
}) => {
  try {
    await deleteDataInDB(moduleName, filterName?.FilterName, dataTableName);
    const getFilterData = await getDatafromDB(moduleName, dataTableName);
    setFilterName({});
    setNamedFilter(getFilterData);
    if (filterName?.FilterName === gridFilterName?.FilterName) {
      setGridFilterName({});
      const gridFilterData = await getCacheConfig(
        `${moduleName}.${dataTableName}.Filter`
      );
      gridFilterData.SelectedNamedFilter = '';
      setCacheConfig(`${moduleName}.${dataTableName}.Filter`, gridFilterData);
      setGridDateRangeFilter(
        gridFilterData?.filters?.filter((obj) => obj.type === 'dateRange')
      );
      setFilter({
        logic: 'and',
        filters: gridFilterData.filters ?? [],
      });
    }
    messageGenerator({
      title: 'Filter deleted',
      message: `Filter ${filterName?.FilterName} deleted  successfully`,
      style: 'success',
    });
  } catch (error) {
    const { message } = error;
    errorGenerator({ message });
  }
};
