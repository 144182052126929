import { getValueByKey } from './utils';

/**
 * Custom Form validator
 * @param {*} formRenderProps
 * @param {*} requiredFields
 * @returns
 */
export const isFormValid = (formRenderProps, requiredFields) => {
  for (const fieldName of requiredFields) {
    if (
      formRenderProps.valueGetter(fieldName)?.length === 0 ||
      formRenderProps.valueGetter(fieldName)?.length === undefined
    ) {
      return false;
    }
  }
  return true;
};

/**
 * text Area Validator
 * @param {*} value
 * @returns
 */
export const textAreaValidator = (value) =>
  !value ? 'Please enter a value.' : '';
