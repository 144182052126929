// #region Local Storage
/**
 * NOTIFICATION KEY
 * @typedef NOTIFICATION_KEY
 * @property {string} value
 */
export const NOTIFICATION_KEY = 'Core.Notifications';
// #endregion

/**
 * Application Configurations
 *   @typedef CORE_STORE
 * @property {string} value
 */
export const CORE_STORE = 'Core';

/** Settings */
export const ROW_LAYOUT = 'Core.DataGrid.RowLayout';
export const DEFAULT_LIMIT = 'Core.DataGrid.ItemsPerPage';
export const DROPDOWN_FILTER_THRESHOLD = 10;

/** App Configs */
export const APP_CONFIG_GLOBAL = 'Global App Config';
export const APP_CONFIG = 'App Config';
export const APP_CONFIG_MODIFICATION = 'Modify_App_Config';

// #endregion

/** GraphQL_OPERATORS */
/** Export const CONTAINS_OP
 * @constant {string}
 */
export const CONTAINS_OP = 'contains';
/** Export const EQUALS_OP
 * @constant {string}
 */
export const EQUALS_OP = 'eq';
/** Export const NOT_EQUALS_OP
 * @constant {string}
 */
export const NOT_EQUALS_OP = 'neq';
/** Export const GREATER_THAN_OP
 * @constant {string}
 */
export const GREATER_THAN_OP = 'gt';
/** Export const GREATER_THAN_EQUAL_OP
 * @constant {string}
 */
export const GREATER_THAN_EQUAL_OP = 'gte';
/** Export const LESS_THAN_OP
 * @constant {string}
 */
export const LESS_THAN_OP = 'lt';
/** Export const LESS_THAN_EQUAL_OP
 * @constant {string}
 */
export const LESS_THAN_EQUAL_OP = 'lte';
/** Export const Starts_WITH_OP
 * @constant {string}
 */
export const STARTS_WITH_OP = 'startswith';
/** Export const ENDS_WITH_OP
 * @constant {string}
 */
export const ENDS_WITH_OP = 'endswith';

/**
 * Default Grid filter operators:
 */
export const FilterOperators = {
  text: [
    {
      text: 'grid.filterContainsOperator',
      operator: 'contains',
      displayText: 'Contains',
    },
    {
      text: 'grid.filterEqOperator',
      operator: 'eq',
      displayText: 'Is equal to',
    },
    {
      text: 'grid.filterNotEqOperator',
      operator: 'neq',
      displayText: 'Is not equal to',
    },
    {
      text: 'grid.filterStartsWithOperator',
      operator: 'startswith',
      displayText: 'Starts with',
    },
    {
      text: 'grid.filterEndsWithOperator',
      operator: 'endswith',
      displayText: 'Ends with',
    },
  ],
  numeric: [
    {
      text: 'grid.filterEqOperator',
      operator: 'eq',
      displayText: 'Is equal to',
    },
    {
      text: 'grid.filterNotEqOperator',
      operator: 'neq',
      displayText: 'Is not equal to',
    },
    {
      text: 'grid.filterGteOperator',
      operator: 'gte',
      displayText: 'Is greater than or equal to',
    },
    {
      text: 'grid.filterGtOperator',
      operator: 'gt',
      displayText: 'Is greater than',
    },
    {
      text: 'grid.filterLteOperator',
      operator: 'lte',
      displayText: 'Is less than or equal to',
    },
    {
      text: 'grid.filterLtOperator',
      operator: 'lt',
      displayText: 'Is less than',
    },
  ],
  date: [
    {
      text: 'grid.filterEqOperator',
      operator: 'eq',
      displayText: 'Is equal to',
    },
    {
      text: 'grid.filterAfterOrEqualOperator',
      operator: 'gte',
      displayText: 'Is after or equal to',
    },
    {
      text: 'grid.filterAfterOperator',
      operator: 'gt',
      displayText: 'Is after',
    },
    {
      text: 'grid.filterBeforeOperator',
      operator: 'lt',
      displayText: 'Is before',
    },
    {
      text: 'grid.filterBeforeOrEqualOperator',
      operator: 'lte',
      displayText: 'Is before or equal to',
    },
  ],
  boolean: [
    {
      text: 'grid.filterEqOperator',
      operator: 'eq',
      displayText: 'Is equal to',
    },
    {
      text: 'grid.filterNotEqOperator',
      operator: 'neq',
      displayText: 'Is not equal to',
    },
  ],
};

/**
 * Data Types
 */
export const DataTypes = {
  Text: 'text',
  TextArea: 'textArea',
  Numeric: 'numeric',
  Date: 'date',
  DateTime: 'dateTime',
  Boolean: 'boolean',
  MultiSelect: 'multiSelect',
  DropDown: 'dropDown',
};

/**
 * BOOLEAN_VALUES describes boolean values
 * @constant
 * @typedef BOOLEAN_VALUES
 * @property {string} text
 * @property {string} id
 * @return {string} True/False
 */
export const BOOLEAN_VALUES = [
  { label: '(All)', value: '' },
  { label: 'Checked', value: 'true' },
  { label: 'Not Checked', value: 'false' },
];
/**
 * Form Controls Render Types
 */
export const RenderTypes = {
  ReadOnly: 'ReadOnly',
  Disabled: 'Disabled',
  Editable: 'Editable',
};

/**
 * InLine Row Operation Statuses
 */
export const RowStatus = {
  New: 'New',
  Discard: 'Discard',
  Deleted: 'Deleted',
  Modified: 'Modified',
};

/**
 * Display notification settings
 */
export const DisplayNotification = {
  SHOW: 'Show', // show the notification popup and show notification on notification panel
  SILENT: 'Silent', // Do not show the notification popup but notification will be shown in notification panel
  HIDDEN: 'Hidden', //  Do not show the notification popup and notification in notification panel
  ALERT: 'Alert', // Show the notification popup but no notification will be shown in notification panel.
};

/**
 * Default Offset Value
 */
export const DEFAULT_OFFSET = 1;
