import CryptoJS from 'crypto-js';

/**
 * encrypt Data
 * @param {*} data
 * @returns {string} returns encrypted string
 */
export const encryptData = (data) => {
  try {
    const encryptedData = CryptoJS.AES.encrypt(
      JSON.stringify(data),
      process.env.SECRET_KEY
    ).toString();
    return encryptedData;
  } catch (err) {
    return null;
  }
};

/**
 * decrypt Data
 * @param {*} data
 * @returns {string} returns decrypted string
 */
export const decryptData = (data) => {
  try {
    const bytes = CryptoJS.AES.decrypt(data, process.env.SECRET_KEY);
    const decryptedData = JSON.parse(bytes.toString(CryptoJS.enc.Utf8));
    return decryptedData;
  } catch (err) {
    return null;
  }
};
