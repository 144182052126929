import React from 'react';

import {
  DEVICE_TYPES,
  HEADER_TITLES,
} from '../../../../constants/eventDataTypes';
import { GlobalContext } from '../../Context/GlobalContextState';
import './Heading.scss';

const Heading = ({ title, showHeading = true }) => {
  const { dimensions, deviceInfo } = React.useContext(GlobalContext);
  const [mobileView, setMobileView] = React.useState(false);

  React.useEffect(() => {
    const MobileHeader = () => {
      const header = document.querySelector('#header');
      if (
        deviceInfo?.type === DEVICE_TYPES.PHONE ||
        deviceInfo?.type === DEVICE_TYPES.TABLET
      ) {
        if (header) {
          if (title) {
            header.textContent = title;
            header.classList.add('header-bold');
          } else {
            header.textContent = HEADER_TITLES.MAIN;
            header.classList.remove('header-bold');
          }
          return true;
        }
      }
      if (header) {
        header.textContent = HEADER_TITLES.MAIN;
        header.classList.remove('header-bold');
      }

      return false;
    };
    let isMobileTabletView = MobileHeader();
    setMobileView(isMobileTabletView);
  }, [dimensions, title]);
  return (
    mobileView === false &&
    showHeading &&
    title && (
      <div className="page-title page-button-title">
        <h5 className="k-card-title">{title}</h5>
      </div>
    )
  );
};

export default Heading;
