import { setCacheConfig } from '../../../../Utils/Storage/handleCacheConfig';
/**
 * Handle Reduce States Related to grid
 * @param {*} state
 * @param {*} action
 * @returns {array} The reduce state
 */
const gridReducer = (state, action) => {
  switch (action.type) {
    case 'ROW_LAYOUT':
      setCacheConfig(action.payload.rowLayoutConfigName, action.payload.value);
      return { ...state, rowSizes: action.payload.value };
    case 'IN_EDIT':
      return { ...state, inEdit: action.payload };
    case 'PAGE':
      setCacheConfig(action.payload.pageLengthConfigName, action.payload.take);
      return { ...state, page: action.payload };
    case 'RESET_SETTINGS':
      return {
        ...state,
        rowSizes: action.payload.rowSizes,
        page: action.payload.page,
      };
    case 'SELECTED_STATE':
      return { ...state, selectedState: action.payload };

    default:
      throw new Error();
  }
};
export default gridReducer;
