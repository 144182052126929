/**
 * Used to Print logs
 * @param {String} type
 * @param {String} msg
 */
const log = (type, msg) => {
  const time = new Date().toLocaleTimeString();
  const methodName = new Error().stack.split('\n')[2].trim().split(' ')[1];
  const formatedMsg = `${time}:${type} ${methodName}: ${msg}`;
  switch (type) {
    case 'info':
      console.info(formatedMsg);
      break;
    case 'error':
      console.error(formatedMsg);
      break;
    case 'debug':
      console.log(formatedMsg);
      break;
    case 'warn':
      console.warn(formatedMsg);
      break;
    case 'trace':
      console.trace(formatedMsg);
      break;
    default:
      console.log(formatedMsg);
      break;
  }
};
export default log;
