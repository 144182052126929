import React from 'react';
import { FieldWrapper } from '@progress/kendo-react-form';
import { Error, Hint } from '@progress/kendo-react-labels';
import { Switch } from '@progress/kendo-react-inputs';
import './InputSwitch.scss';

/**
 * InputSwitch component of InputSwitch field
 * InputSwitch component that renders a form InputSwitch field
 * @param {object} fieldRenderProps - the properties for the field InputSwitch component
 * @returns {JSX.Element} - the InputSwitch component
 */
export const InputSwitch = (fieldRenderProps) => {
  const {
    validationMessage,
    name,
    touched,
    label,
    id,
    valid,
    disabled,
    hint,
    optional,
    value,
    showValidationMessage,
    onLabel,
    offLabel,
    ...others
  } = fieldRenderProps;

  // set Validation Message
  const setValidationMessage =
    (touched && validationMessage) || showValidationMessage;
  // show hint variable
  const showHint = !setValidationMessage && hint;
  // hint Id
  const hintId = showHint ? `${id}_hint` : '';
  // error Id
  const errorId = setValidationMessage ? `${id}_error` : '';

  return (
    <FieldWrapper className="switch-wrapper">
      {label && <span className="switch-label">{label}</span>}
      <div className={'k-form-field-wrap'}>
        <Switch
          id={id}
          name={name}
          onLabel={onLabel}
          offLabel={offLabel}
          disabled={disabled}
          {...others}
        />
        {showHint && <Hint id={hintId}>{hint}</Hint>}
        {setValidationMessage && (
          <Error id={errorId}>{validationMessage}</Error>
        )}
      </div>
    </FieldWrapper>
  );
};

export default InputSwitch;
