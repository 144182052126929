import React from 'react';
import { DropDownList } from '@progress/kendo-react-dropdowns';
import { Button, ButtonGroup } from '@progress/kendo-react-buttons';

/**
 * Data Grid toolbar (dynamic toolbar for data grid)
 * @type {React.FC<Props>}
 * @returns {React.ReactElement} The DataGridToolbar component.
 */
const DataGridToolbar = (props) => {
  const {
    toolbarExpanded,
    filterExpanded,
    rowSizeChange,
    filterChange,
    pageListChange,
    rowSizes,
    pageLength,
    namedFilters,
    gridFilterName,
    filterSlide,
    resetSettings,
    setFilterName,
    rowLayouts,
    pageSizes,
  } = props;
  /**
   * Default value of Name Filter Dropdown in toolbar
   */
  const nameFilterDefaultVal = {
    FilterName: 'Select Named Filter',
  };

  /**
   * use to get the name filter Dropdown value
   * @returns {Array} returns grid filter Name
   */
  const getGridFilterName = () => {
    if (gridFilterName?.FilterName?.length) {
      return gridFilterName;
    }
    return nameFilterDefaultVal;
  };

  return (
    <>
      {toolbarExpanded && (
        <div
          className={`toolbar-grid-wrapper ${
            toolbarExpanded || filterExpanded
              ? 'toolbar-active'
              : 'toolbar-inactive'
          }`}
        >
          <DropDownList
            data={rowLayouts}
            name="rowSizes"
            value={rowSizes}
            label="Row Layout"
            onChange={rowSizeChange}
          />
          <DropDownList
            data={pageSizes}
            value={pageLength}
            name="pages"
            label="Item per page"
            onChange={pageListChange}
          />
          <ButtonGroup>
            <Button
              icon={'reset'}
              fillMode="outline"
              themeColor="primary"
              className="action-button"
              onClick={resetSettings}
            />
          </ButtonGroup>
        </div>
      )}

      {filterExpanded && (
        <div
          className={`toolbar-grid-wrapper filter-toolbar ${
            toolbarExpanded || filterExpanded
              ? 'toolbar-active'
              : 'toolbar-inactive'
          }`}
        >
          <div className="filter">
            <DropDownList
              data={namedFilters}
              textField="FilterName"
              dataItemKey="FilterName"
              className="filter-dropdown"
              name="FilterName"
              value={getGridFilterName()}
              label="Filter Name"
              onChange={(e) => {
                filterChange(e, 'grid');
              }}
              ariaDescribedBy={'namedFilters'}
              defaultItem={nameFilterDefaultVal}
            />

            <div>
              <ButtonGroup>
                <Button
                  icon={'gear'}
                  fillMode="outline"
                  themeColor="primary"
                  className="action-button"
                  title="Edit Filter"
                  onClick={() => {
                    setFilterName(gridFilterName);
                    filterSlide({ action: '' });
                  }}
                />
              </ButtonGroup>
            </div>
          </div>
        </div>
      )}
    </>
  );
};
export default DataGridToolbar;
