import { fetchCacheConfig } from '../Storage/handleCacheConfig';

/**
 * Set Sort For GraphQL API
 * @param {*} sorts Sorts Array
 * @returns {string} comma separated string
 */
export function setSortHandler({ sorts, dataColumns, dataTableName = '' }) {
  try {
    var sortArray = [];
    let sortTemp = sorts.filter((obj) => obj?.field !== 'id');
    if (sortTemp?.length && dataTableName)
      sortTemp.map((item) => {
        sortArray.push(
          `${item.dir === 'desc' ? '-' : ''}${
            dataColumns?.filter((f) => f.field === item.field)[0]?.sortField ??
            item.field
          }`
        );
      });
    return sortArray;
  } catch (error) {
    return [];
  }
}

/**
 * Get Sort From Storage.
 * @async
 * @param {*} key - The name of the key to retrieve configuration for.
 * @returns {Promise<object|null>} The configuration object or null if not found.
 */
export const getSortFromStorage = async (key) =>
  fetchCacheConfig(`${key}`, 'sort') ?? [];

export default setSortHandler;
