import { BOOLEAN_VALUES } from '../../constants/applicationConstants';

/**
 * Returns an array of objects containing label and value properties for all available boolean values.
 * @function
 * @returns {Array<Object>} An array of objects containing label and value properties.
 */
export const BooleanValues = () => {
  const booleanValues = BOOLEAN_VALUES;
  return booleanValues.map((item, i) => ({
    label: item.label,
    value: item.value,
  }));
};

export default BooleanValues;
