import React from 'react';
import { FieldWrapper } from '@progress/kendo-react-form';
import { Label, Error, Hint } from '@progress/kendo-react-labels';
import { DropDownList } from '@progress/kendo-react-dropdowns';
import { RenderTypes } from '../../../../constants/applicationConstants';
import { filterBy } from '@progress/kendo-data-query';

/**
 * DropDownInput component of DropDownInput field
 * DropDownInput component that renders a form DropDownInput field
 * @param {object} fieldRenderProps - the properties for the field DropDownInput component
 * @returns {JSX.Element} - the DropDownInput component
 */
export const DropDownInput = (fieldRenderProps) => {
  const {
    validationMessage,
    touched,
    label,
    id,
    valid,
    disabled,
    hint,
    optional,
    renderTypes,
    value,
    data,
    showValidationMessage,
    defaultItem,
    dataSet,
    handleChange,
    textField,
    dataItemKey,
    filterable,
    fieldWrapperStyles,
    requiredField,
    ...others
  } = fieldRenderProps;

  // set Validation Message
  const setValidationMessage =
    (touched && validationMessage) || showValidationMessage;
  // show hint variable
  const showHint = !setValidationMessage && hint;
  // hint Id
  const hintId = showHint ? `${id}_hint` : '';
  // error Id
  const errorId = setValidationMessage ? `${id}_error` : '';

  const [dropdownDataSet, setDropdownDataSet] = React.useState(dataSet);

  const filterData = (filter) => {
    const data = dataSet.slice();
    return filterBy(data, filter);
  };

  const filterChange = (event) => {
    setDropdownDataSet(filterData(event.filter));
  };

  React.useEffect(() => {
    setDropdownDataSet(dataSet);
  }, [dataSet]);

  return (
    <FieldWrapper>
      {label && (
        <span className="k-floating-label-container k-label">
          <Label
            editorId={id}
            editorValid={valid}
            editorDisabled={disabled}
            optional={optional}
          >
            {label} {requiredField && <span className="required-field">*</span>}
          </Label>
        </span>
      )}
      {renderTypes === RenderTypes.ReadOnly ? (
        <span className="display-text">
          {textField && value ? value[textField] : value}
        </span>
      ) : (
        <div className={'k-form-field-wrap'} style={fieldWrapperStyles}>
          <DropDownList
            data={dropdownDataSet}
            filterable={filterable}
            onFilterChange={filterChange}
            onChange={handleChange}
            defaultItem={defaultItem}
            value={value}
            textField={textField}
            dataItemKey={dataItemKey}
            {...others}
          />
          {showHint && <Hint id={hintId}>{hint}</Hint>}
          {setValidationMessage && (
            <Error id={errorId}>{validationMessage}</Error>
          )}
        </div>
      )}
    </FieldWrapper>
  );
};

export default DropDownInput;
