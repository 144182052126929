import * as React from 'react';
import { DropDownList } from '@progress/kendo-react-dropdowns';
import { Button } from '@progress/kendo-react-buttons';
import { filterBy, orderBy } from '@progress/kendo-data-query';
import {
  RenderTypes,
  DROPDOWN_FILTER_THRESHOLD,
} from '../../../../constants/applicationConstants';
import { DEVICE_TYPES } from '../../../../constants/eventDataTypes';
import { GlobalContext } from '../../Context/GlobalContextState';

/**
 * Dropdown Filter Cell Class
 * @param {*} hasValue
 * @returns {React.ReactElement} The DropdownFilterCell
 */
export const DropdownFilterCell = (props) => {
  const { deviceInfo } = React.useContext(GlobalContext);
  const { validateField = false, loading } = props;
  const [inputValue, setInputValue] = React.useState([]);
  const [filterValue, setFilterValue] = React.useState('');
  const [data, setData] = React.useState([]);
  const [isOpened, setIsOpenend] = React.useState(false);
  React.useEffect(() => {
    setData(props.data);
  }, [props.data]);

  React.useEffect(() => {
    if (props.value && props.value !== 'null') {
      const item = props.data?.find((f) => f.value === props.value);

      setInputValue({
        label: item?.label ?? props?.label ?? props.value,
        value: props.value,
      });
    } else setInputValue([]);
  }, [props.value]);

  /**
   * Makes the filter input box readonly when the dropdown is shown on mobile and tablet devices.
   * Clicking on the input box restores its normal editable state.
   * Only for mobile and tablet.
   */
  React.useEffect(() => {
    if (
      (deviceInfo?.type === DEVICE_TYPES.PHONE ||
        deviceInfo?.type === DEVICE_TYPES.TABLET) &&
      isOpened
    ) {
      const kinput = document.querySelector(
        '.k-popup.k-list-container.k-dropdownlist-popup .k-input-inner'
      );

      if (kinput) {
        kinput.addEventListener('click', () => {
          kinput.readOnly = false;
        });
        kinput.readOnly = true;
      }
    }
  }, [isOpened]);
  const hasValue = (value) => Boolean(value && value !== props.defaultItem);
  const rowLayout = `${props.rowSizes} dd-list-item`;
  const isValid = () =>
    props?.required && validateField ? Boolean(props.value) : true;

  /**
   * handle blur
   */
  const handleBlur = () => {
    setData(props.data);
    setFilterValue('');
  };

  /**
   * handle drop down Close
   */
  const handleClose = () => {
    setIsOpenend(false);
    setData(props.data);
    setFilterValue('');
  };

  /**
   * Change Filter
   * @param {*} event
   */
  const onChange = (event) => {
    const isValue = hasValue(event.target.value?.value);
    setInputValue(
      isValue
        ? { label: event.target.value?.label, value: event.target.value?.value }
        : []
    );
    props.onChange({
      text: isValue ? event.target.value?.label : '',
      value: isValue ? event.target.value?.value : '',
      operator: isValue ? 'eq' : '',
      syntheticEvent: event.syntheticEvent,
      field: isValue ? props.field : '',
      formRenderProps: props.formRenderProps ?? '',
    });
  };

  /**
   * Clear Filters
   * @param {*} event
   */
  const onClearButtonClick = (event) => {
    event.preventDefault();
    setInputValue('');
    props.onChange({
      value: '',
      operator: '',
      syntheticEvent: event,
    });
  };

  /**
   * List No Data render
   * @param {*} element
   * @returns
   */
  const listNoDataRender = (element) => {
    const noData = (
      <div className="k-list k-list-md">
        <div className="k-nodata">
          <div>{props?.noDataText ?? 'No DATA FOUND'}</div>
        </div>
      </div>
    );
    return React.cloneElement(
      element,
      {
        ...element.props,
      },
      noData
    );
  };

  /**
   * drop down list items renderer
   * @param {*} li list of elements
   * @returns rendered options
   */
  const itemRender = (li) => {
    const itemChildren = <span className={rowLayout}>{li.props.children}</span>;
    return React.cloneElement(li, li.props, itemChildren);
  };

  /**
   * Updates the filter state variable value when there is a change.
   * @param {*} event
   */
  const filterChange = (event) => {
    setFilterValue(event.filter.value);
    const filterData = filterBy(props.data.slice(), event.filter);
    setData(filterData);
  };

  /**
   * Enables the filter when the data length exceeds the threshold value.
   * @returns {boolean} isFilterable
   */
  const isFilterable = () => {
    if (
      props?.data?.filter((f) => f.label && f.value)?.length >
      DROPDOWN_FILTER_THRESHOLD
    )
      return true;

    return false;
  };

  return (
    <>
      {props?.renderTypes === RenderTypes.ReadOnly ? (
        <span className="display-text">{props?.label ?? props?.value}</span>
      ) : (
        <div className="k-filtercell">
          <DropDownList
            data={orderBy(
              data?.filter(
                (f, index, self) =>
                  index ===
                  self.findIndex(
                    (t) => t.label === f.label && t.value === f.value
                  )
              ) ?? [],
              [{ dir: 'asc', field: 'label' }]
            )}
            filter={filterValue}
            onChange={onChange}
            itemRender={itemRender}
            listNoDataRender={listNoDataRender}
            textField="label"
            dataItemKey="value"
            disabled={props?.disabled ?? false}
            value={inputValue}
            defaultItem={props.defaultItem}
            required={props?.required ?? false}
            allowCustom={true}
            filterable={isFilterable()}
            valid={isValid(inputValue)}
            onFilterChange={filterChange}
            onBlur={handleBlur}
            onClose={handleClose}
            loading={loading}
            opened={isOpened}
            onOpen={() => {
              setIsOpenend(true);
            }}
          />
          {!(props?.hideClear ?? false) && (
            <Button
              title="Clear"
              disabled={!hasValue(inputValue) || inputValue?.length <= 0}
              onClick={onClearButtonClick}
              icon="filter-clear"
            />
          )}
        </div>
      )}
    </>
  );
};

export default DropdownFilterCell;
