import breakpoints from './breakpoints';
import { DEVICE_TYPES } from '../../constants/eventDataTypes';
import {
  TabletDevices,
  MobileDevices,
  DesktopDevices,
} from '../Devices/devices';
/** Check if dimensions match to a specific device type */
export const resizeWorker = () => {
  // add deviceType attribute to the root element we will use it for tablet based media queries
  const rootElement = document.querySelector('#root');
  const deviceInfo = navigator.userAgent.match(/^[^\\(]+\((\w+)/);
  var device = {
    type: '',
    orientation: window.matchMedia('(orientation: portrait)').matches
      ? 'Portrait'
      : 'Landscape',
    userAgent: deviceInfo,
    name: deviceInfo[1],
  };

  const userAgent = navigator.userAgent.toLowerCase();
  // check if user agent matches devices
  const isTabletDevice =
    TabletDevices.filter((f) => userAgent.includes(f.toLowerCase()))?.length >
    0;
  const isMobileDevice =
    MobileDevices.filter((f) => userAgent.includes(f.toLowerCase()))?.length >
    0;
  const isDesktopDevices =
    DesktopDevices.filter((f) => userAgent.includes(f.toLowerCase()))?.length >
    0;
  if (isTabletDevice) {
    device.type = DEVICE_TYPES.TABLET;
  } else if (isMobileDevice) {
    device.type = DEVICE_TYPES.PHONE;
  } else if (isDesktopDevices) {
    device.type = DEVICE_TYPES.DESKTOP;
  } else {
    device.type = getDeviceType(userAgent);
  }
  // set device type in root as per dimensions
  if (rootElement) {
    rootElement.setAttribute('device-type', device.type);
    rootElement.setAttribute('device-orientation', device.orientation);
  }

  return device;
};

/**
 * get Device Types from dimensions
 * @param {*} device
 * @returns {DEVICE_TYPES} returns device type constant
 */
const getDeviceType = (userAgent) => {
  const isTablet =
    /(ipad|tablet|(android(?!.*mobile))|(windows(?!.*phone)(.*touch))|kindle|playbook|silk|(puffin(?!.*(IP|AP|WP))))/.test(
      userAgent
    );
  let deviceType = DEVICE_TYPES.PHONE;
  if (
    (window.innerWidth < breakpoints.phone &&
      window.matchMedia('(orientation: portrait)').matches) ||
    (window.innerWidth <= 915 &&
      window.innerHeight < breakpoints.phone &&
      window.matchMedia('(orientation: landscape)').matches)
  ) {
    deviceType = DEVICE_TYPES.PHONE;
  } else if (
    (window.innerWidth < breakpoints.tablet &&
      window.matchMedia('(orientation: portrait)').matches) ||
    (window.innerWidth <= 1180 &&
      window.innerHeight < breakpoints.tablet &&
      window.matchMedia('(orientation: landscape)').matches) ||
    isTablet
  ) {
    deviceType = DEVICE_TYPES.TABLET;
  } else if (
    (window.innerWidth < breakpoints.laptop &&
      window.matchMedia('(orientation: portrait)').matches) ||
    (window.innerWidth <= 1180 &&
      window.innerHeight < breakpoints.laptop &&
      window.matchMedia('(orientation: landscape)').matches)
  ) {
    deviceType = DEVICE_TYPES.LAPTOP;
  } else {
    deviceType = DEVICE_TYPES.DESKTOP;
  }
  return deviceType;
};
export default resizeWorker;
