import { NOTIFICATION_TYPES } from '../../../../constants/eventDataTypes';
import eventBus from '../../../../Utils/Common/eventBus';
import PushNotification from './PushNotification';

/**
 * Generates and sends a notification to either the service worker or app based on the given option.
 * Generate notification based on the Type i.e SW/Application Notifications.
 * SW notifications will be sent through the Service worker.
 * Application Notification will be sent through the event bus.
 * @param {object} notification - The notification object to generate and send.
 * @param {string} option - The option specifying where to send the notification ('service-worker' or 'app').
 * @param {string} notificationType - The type of notification to send (e.g. 'success', 'warning', etc.).
 * @returns {string} An empty string if the notification was sent successfully; otherwise, an error message.
 */
const GenerateNotification = (notification, option, notificationType) => {
  switch (option) {
    case NOTIFICATION_TYPES.SERVICE_WORKER:
      if ('serviceWorker' in navigator) {
        if (navigator.serviceWorker.controller) {
          // TODO: Use service worker push notification
          if (notification.isWebPush) {
            PushNotification(notification);
          }
          if (notification)
            notification.callBack = notification?.callBack?.toString() ?? '';
          navigator.serviceWorker.controller.postMessage({
            type: notificationType,
            notification: JSON.stringify(notification),
          });
        }
      }
      return '';
    case NOTIFICATION_TYPES.APP:
      if (notification.isWebPush) {
        PushNotification(notification);
      }
      eventBus.dispatch(notificationType, notification);
      return '';
    default:
      return 'No option provided.';
  }
};

export default GenerateNotification;
