import React from 'react';
import { Form, Field } from '@progress/kendo-react-form';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faExpand } from '@fortawesome/free-solid-svg-icons';
import { ErrorBoundary } from 'react-error-boundary';
import { Button } from '@progress/kendo-react-buttons';
import { Card, CardHeader, CardBody } from '@progress/kendo-react-layout';
import ErrorFallback from '../ErrorHandler/ErrorFallback';
import TextAreaInput from '../FormElements/TextArea';
import './DescriptionTextArea.scss';

/**
 * DescriptionTextArea component
 * DescriptionTextArea component that renders a command basic editor & full screen editor
 * @returns {JSX.Element} - the DescriptionTextArea component
 */
const DescriptionTextArea = ({
  label,
  renderTypes,
  validateForm,
  formData,
  setFormData,
  detailsDataModified,
  showEditor,
  isTextArea,
  setIsTextArea,
  smHeight,
  lgHeight,
  editorField,
  setEditorField,
  row,
  requiredField,
  ...others
}) => {
  /**
   * handle Input Change
   * @e object
   */
  const handleInputChange = (e) => {
    const { value, type, checked } = e.target;
    const newValue = type === 'checkbox' ? (checked ? 1 : 0) : value;
    setFormData((prev) => ({ ...prev, [editorField.name]: newValue }));
  };
  /**
   * close the editor screen
   */
  const close = () => {
    setIsTextArea(false);
  };
  return (
    <ErrorBoundary
      FallbackComponent={ErrorFallback}
      onReset={() => {
        // reset the state of your app so the error doesn't happen again
      }}
    >
      {!isTextArea ? (
        <Form
          render={(formRenderProps) => (
            <>
              <Field
                key={editorField?.name}
                id={editorField?.name}
                name={editorField?.name}
                data={formData[editorField?.name]}
                onChange={handleInputChange}
                label={label}
                type={'text'}
                row={row}
                component={TextAreaInput}
                renderTypes={!detailsDataModified ? 'ReadOnly' : ''}
                style={{
                  width: '100%',
                }}
                optional={false}
                requiredField={requiredField}
              />
              {detailsDataModified && (
                <span
                  onClick={() => {
                    setIsTextArea(true);
                    setEditorField(editorField);
                  }}
                  className="open-editor-icon"
                >
                  <FontAwesomeIcon
                    className="icon-full-screen k-mr-1"
                    icon={faExpand}
                  />
                </span>
              )}
            </>
          )}
        />
      ) : (
        <>
          <div className="container mx-auto k-m-3 field-description-main">
            <Card className="detail-card">
              <CardHeader>
                <h6 className="card-title-secondary">{editorField?.label}</h6>
              </CardHeader>
              <CardBody className="detail-card">
                <Form
                  render={(formRenderProps) => (
                    <div className="k-border">
                      <div className="k-d-flex single-field-row">
                        <div className={`field-wrapper field-wrapper-100`}>
                          <Field
                            key={editorField?.name}
                            id={editorField?.name}
                            name={editorField?.name}
                            data={formData[editorField?.name]}
                            onChange={handleInputChange}
                            type={'text'}
                            component={TextAreaInput}
                            renderTypes={!detailsDataModified ? 'ReadOnly' : ''}
                            style={{
                              width: '100%',
                            }}
                            optional={false}
                          />
                        </div>
                      </div>
                      <div className="lcs-action">
                        <Button themeColor={'primary'} onClick={() => close()}>
                          Close
                        </Button>
                      </div>
                    </div>
                  )}
                />
              </CardBody>
            </Card>
          </div>
        </>
      )}
    </ErrorBoundary>
  );
};

export default DescriptionTextArea;
