// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `/* dimensions for devices in portrait mode */
.grid-sort-wrapper {
  display: flex;
  flex-direction: column;
  padding: 10px 0;
}
.grid-sort-wrapper .sort-button-wrapper {
  display: flex;
  align-items: center;
  padding: 10px 0;
  justify-content: space-between;
}
.grid-sort-wrapper .sort-button-wrapper .left-sort-controls-icon {
  padding: 4px;
}`, "",{"version":3,"sources":["webpack://./src/assets/scss/abstracts/_breakpoints.scss","webpack://./src/Modules/Core/Components/DataTable/Sort/Sort.scss"],"names":[],"mappings":"AAAA,4CAAA;ACEA;EACE,aAAA;EACA,sBAAA;EACA,eAAA;AAAF;AACE;EACE,aAAA;EACA,mBAAA;EACA,eAAA;EACA,8BAAA;AACJ;AAAI;EACE,YAAA;AAEN","sourcesContent":["/* dimensions for devices in portrait mode */\n$breakpoints: (\n  'sm-phone': 320px,\n  'l-phone': 420px,\n  'phone': 576px,\n  's-tablet': 700px,\n  'tablet': 820px,\n  'laptop': 992px,\n  'desktop': 1024px,\n  'l-desktop': 1200px,\n  'xl-desktop': 1440px,\n  'xxl-desktop': 1920px,\n) !default;\n$tablet-landscape-mode: '>=560px', '<1180px', 'landscape';\n$tablet-portrait-mode: '>=560px', '<1180px', 'portrait';\n$tablet-mode: '>=560px', '<820px';\n$tablet-only-portrait-mode: '<820px', 'portrait';\n$sm-tablet-only-portrait-mode: '>500px', '<696px', 'portrait';\n$phone-landscape-mode: '<560px', 'landscape';\n$phone-portrait-mode: '<560px', 'portrait';\n$sm-phone-portrait-mode: '>300px', '<=400px', 'portrait';\n$x-sm-phone-portrait-mode: '>200px', '<=300px', 'portrait';\n$lg-phone-portrait-mode: '>325px', '<=475', 'portrait';\n$laptop-landscape-mode: '<1180px', 'landscape';\n\n@import '/node_modules/include-media/dist/_include-media.scss';\n","@import '../../../../../assets/scss/abstracts/breakpoints';\n\n.grid-sort-wrapper {\n  display: flex;\n  flex-direction: column;\n  padding: 10px 0;\n  .sort-button-wrapper {\n    display: flex;\n    align-items: center;\n    padding: 10px 0;\n    justify-content: space-between;\n    .left-sort-controls-icon {\n      padding: 4px;\n    }\n  }\n}\n"],"sourceRoot":""}]);
// Exports
export default ___CSS_LOADER_EXPORT___;
