/**
 * Validating Javascript Object.
 * @param {*} object object to be verified
 * @returns {array} array of javascript object
 */
const validateSpread = (object) => {
  try {
    const array = [...object];
    return array;
  } catch (error) {
    console.log('error', error);
    return [];
  }
};
export default validateSpread;
