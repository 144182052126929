import React from 'react';
import { CardActions } from '@progress/kendo-react-layout';

/**
 * Footer of Action Slider
 */
function SliderFooter({ children }) {
  return (
    <CardActions className="k-card-actions-end card-actions content-slider-actions">
      {children}
    </CardActions>
  );
}

export default SliderFooter;
